<template>
  <div>
    <transition>
      <v-card class="my-9">
        <v-card-title class="px-4 py-2 blue-grey darken-1">
          <h3 class="subtitle-1 font-weight-bold white--text">
            使用人である弁理士
          </h3>
        </v-card-title>

        <v-row justify="center">
          <v-col md="8">
            <v-simple-table class="transparent my-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>登録番号</th>
                    <th>氏名</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(patent, index) in fields.patents"
                    :key="patent.id"
                  >
                    <td class="font-weight-bold" width="100">
                      {{ patent.registration_number }}
                    </td>
                    <td>{{ patent.name_kanji }}</td>
                    <td width="100">
                      <v-dialog
                        v-model="patent.dialogPatent"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            dark
                            depressed
                            small
                            color="red darken-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                          削除
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5"> </v-card-title>
                          <v-card-text>
                            {{ patent.name }}を削除しますか？
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="grey darken-3"
                              text
                              @click="patent.dialogPatent = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              color="red darken-2"
                              dark
                              text
                              class="font-weight-bold"
                              @click="removePatent(index)"
                            >
                              削除する
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="px-9 pb-9" justify="center">
          <v-col md="3" cols="12">
            <v-text-field
              outlined
              hide-details
              dense
              label="登録番号"
              background-color="white"
              color="cyan darken-2"
              class="mr-2"
              v-model="patentId"
              @change="changeRegistrationNumber"
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            {{ patentName }}
          </v-col>
          <v-col md="2" cols="12">
            <v-btn
              color="blue-grey darken-2"
              depressed
              dark
              @click="addPatent()"
            >
              追加する
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      patentId: "",
      patentName: "",
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    removePatent(index) {
      this.fields.patents.splice(index, 1);
      let allEmp = this.fields.members.concat(this.fields.patents);
      this.fields.employees = allEmp;
      this.dialogPatent = false;
    },
    addPatent() {
      const newpatentId = this.patentId.trim();
      const newpatentName = this.patentName.trim();
      if (!newpatentId || !newpatentName) {
        return;
      }

      const newPatent = {
        registration_number: newpatentId,
        name_kanji: newpatentName,
        dialogPatent: false,
      };

      if (!this.isDuplicate(newPatent)) {
        this.fields.patents.push(newPatent);
        let allEmp = this.fields.members.concat(this.fields.patents);
        this.fields.employees = allEmp;
        this.patentId = "";
        this.patentName = "";
      } else {
        this.$store.commit("setMessege", {
          text: "重複した使用人が入力されています",
          color: "warning",
        });
      }
    },
    isDuplicate(newPatent) {
      let tempEmployees = this.fields.employees.concat(newPatent);
      const uniqueEmployees = new Set(
        tempEmployees.map((employee) => employee.registration_number)
      );
      if (uniqueEmployees.size < tempEmployees.length) {
        return true;
      }
    },
    changeRegistrationNumber(patentId) {
      if (patentId != "") {
        this.$axios
          .get("/api/member_by_number/" + patentId)
          .then((response) => {
            if (response.data.exist == true) {
              this.patentName = response.data.name_kanji;
            } else {
              this.patentName = "";
            }
          })
          .catch((error) => {
            this.patentName = "";
          });
      } else {
        this.patentName = "";
      }
    },
  },
};
</script>

<style type="scss" scoped>
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
</style>
