<template>
  <div>
    <transition-group tag="div">
      <v-card
        v-for="(office, index) in fields['offices']"
        :key="index"
        class="mb-5"
      >
        <v-card-title class="px-4 py-2 blue-grey darken-1">
          <h3 class="subtitle-1 font-weight-bold white--text" v-if="index == 0">
            主たる事務所
          </h3>
          <h3 class="subtitle-1 font-weight-bold white--text" v-else>
            従たる事務所
          </h3>
        </v-card-title>
        <v-simple-table class="transparent my-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所状態</span>
                </th>
                <td>
                  <div class="d-md-flex justify-start align-center">
                    <v-radio-group row dense v-model="office.office_state_cd">
                      <v-radio
                        v-for="cd in office_state_cd"
                        :key="cd.id"
                        :label="cd.label"
                        :value="cd.id"
                        color="green darken-3"
                        :disabled="true"
                      ></v-radio>
                    </v-radio-group>
                    <div>
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="text-caption ml-1 text-decoration-underline"
                              >主たる事務所とは</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          主たる事務所とはのtooltipテキスト<br />主たる事務所とはのtooltipテキスト
                        </p>
                      </v-tooltip>
                    </div>
                  </div>
                  <div
                    v-if="
                      errors &&
                      errors &&
                      errors['offices.' + index + '.office_state_cd']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".office_state_cd"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>フリガナ</span>
                </th>
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    background-color="white"
                    dense
                    color="green darken-3"
                    class="mr-2"
                    v-model="office.name_kana"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.name_kana']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".name_kana"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所名</span>
                </th>
                <td class="py-2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    background-color="white"
                    class="mr-2"
                    color="green darken-3"
                    v-model="office.name"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.name']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".name"][0] }}
                  </div>
                  <div class="my-2">
                    <v-tooltip right :open-on-hover="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          @click="on.click"
                          @blur="on.blur"
                          retain-focus-on-click
                          text
                          color="cyan darken-2"
                        >
                          <v-icon dark depressed color="black">
                            mdi-chat-question-outline
                          </v-icon>
                          <span
                            class="text-caption ml-1 text-decoration-underline"
                            >使用できない事務所名</span
                          >
                        </v-btn>
                      </template>
                      <p class="mb-0">
                        使用できない事務所名のtooltipテキスト<br />使用できない事務所名のtooltipテキスト
                      </p>
                    </v-tooltip>
                    <v-tooltip right :open-on-hover="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          @click="on.click"
                          @blur="on.blur"
                          retain-focus-on-click
                          text
                          color="cyan darken-2"
                        >
                          <v-icon dark depressed color="black">
                            mdi-chat-question-outline
                          </v-icon>
                          <span
                            class="text-caption ml-1 text-decoration-underline"
                            >事務所名称に関するガイドラインDL</span
                          >
                        </v-btn>
                      </template>
                      <p class="mb-0">
                        主たる事務所とはのtooltipテキスト<br />主たる事務所とはのtooltipテキスト
                      </p>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所所在地</span>
                </th>
                <td class="pb-2">
                  <div class="d-md-flex justify-start py-2">
                    <div style="width: 200px">
                      <v-text-field
                        label="郵便番号(ハイフン無し)"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        dense
                        v-model="office.adress_postalcode"
                        @input="getPrefecture(index, false)"
                      ></v-text-field>
                    </div>

                    <v-btn class="mx-2" @click="getPrefecture(index, true)"
                      >住所検索</v-btn
                    >
                    <v-btn @click="setKaigai(index)">海外</v-btn>
                  </div>
                  <div
                    v-if="
                      errors &&
                      errors['offices.' + index + '.adress_postalcode']
                    "
                    class="text-danger py-2"
                  >
                    {{ errors["offices." + index + ".adress_postalcode"][0] }}
                  </div>
                  <div class="d-md-flex justify-start align-center pb-2">
                    <div style="width: 200px">
                      <v-select
                        label="都道府県"
                        :items="[
                          { label: '選択してください', id: '' },
                          ...office_prefecture,
                        ]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        v-model="office.adress_prefecture"
                      ></v-select>
                      <div
                        v-if="
                          errors &&
                          errors['offices.' + index + '.adress_prefecture']
                        "
                        class="text-danger"
                      >
                        {{
                          errors["offices." + index + ".adress_prefecture"][0]
                        }}
                      </div>
                    </div>
                    <div class="ml-2">
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="text-caption ml-1 text-decoration-underline"
                              >海外に事務所をお持ちの方</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          海外に事務所をお持ちの方のtooltipテキスト<br />海外に事務所をお持ちの方のtooltipテキスト
                        </p>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-text-field
                    label="住所"
                    outlined
                    hide-details
                    color="teal darken-3"
                    class="mb-2"
                    background-color="white"
                    dense
                    v-model="office.adress_municipality"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="
                      errors &&
                      errors['offices.' + index + '.adress_municipality']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".adress_municipality"][0] }}
                  </div>
                  <v-text-field
                    label="建物名以下"
                    outlined
                    hide-details
                    color="teal darken-3"
                    background-color="white"
                    dense
                    v-model="office.adress_building"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="
                      errors && errors['offices.' + index + '.adress_building']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".adress_building"][0] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table class="transparent my-5">
          <template v-slot:default>
            <tbody>
              <tr>
                <th class="bbnone"></th>
                <td class="bbnone pb-0">
                  <div>
                    <v-tooltip right :open-on-hover="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          @click="on.click"
                          @blur="on.blur"
                          retain-focus-on-click
                          text
                          color="cyan darken-2"
                        >
                          <v-icon dark depressed color="black">
                            mdi-chat-question-outline
                          </v-icon>
                          <span
                            class="text-caption ml-1 text-decoration-underline"
                            >登録されている番号を削除したいとき</span
                          >
                        </v-btn>
                      </template>
                      <p class="mb-0">
                        登録されている番号を削除したいときのtooltipテキスト<br />登録されている番号を削除したいときの方のtooltipテキスト
                      </p>
                    </v-tooltip>
                  </div>
                </td>
                <td class="bbnone"></td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所TEL1</span>
                </th>
                <td>
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office.tel1"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.tel1']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel1"][0] }}
                  </div>
                </td>
                <td>
                  <v-select
                    label="回線区分"
                    :items="[
                      { label: '選択してください', id: '' },
                      ...line_division,
                    ]"
                    item-text="label"
                    item-value="id"
                    outlined
                    hide-details
                    color="green darken-3"
                    background-color="white"
                    flat
                    dense
                    v-model="office.tel1_line_division"
                  ></v-select>
                  <div
                    v-if="
                      errors &&
                      errors['offices.' + index + '.tel1_line_division']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel1_line_division"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>内線</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office.tel1_extension"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="
                      errors && errors['offices.' + index + '.tel1_extension']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel1_extension"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所TEL2</span>
                </th>
                <td>
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office.tel2"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.tel2']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel2"][0] }}
                  </div>
                </td>
                <td>
                  <v-select
                    label="回線区分"
                    :items="[
                      { label: '選択してください', id: '' },
                      ...line_division,
                    ]"
                    item-text="label"
                    item-value="id"
                    outlined
                    hide-details
                    color="green darken-3"
                    background-color="white"
                    flat
                    dense
                    v-model="office.tel2_line_division"
                  ></v-select>
                  <div
                    v-if="
                      errors &&
                      errors['offices.' + index + '.tel2_line_division']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel2_line_division"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="150"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>内線</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office.tel2_extension"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="
                      errors && errors['offices.' + index + '.tel2_extension']
                    "
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".tel2_extension"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="100"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所FAX1</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    background-color="white"
                    color="green darken-3"
                    class="my-2"
                    v-model="office.fax1"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.fax1']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".fax1"][0] }}
                  </div>
                </td>
              </tr>
              <tr>
                <th
                  width="100"
                  class="text-right px-3 bg-yellow lighten-5"
                  style="vertical-align: middle"
                >
                  <span>事務所FAX2</span>
                </th>
                <td colspan="2">
                  <v-text-field
                    single-line
                    outlined
                    hide-details
                    dense
                    class="my-2"
                    background-color="white"
                    color="green darken-3"
                    v-model="office.fax2"
                    clearable
                  ></v-text-field>
                  <div
                    v-if="errors && errors['offices.' + index + '.fax2']"
                    class="text-danger"
                  >
                    {{ errors["offices." + index + ".fax2"][0] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions color="grey">
          <v-spacer></v-spacer>

          <v-btn
            v-if="index != 0"
            class="ma-2"
            dark
            depressed
            small
            color="red darken-2"
            @click="removeOffice(index)"
          >
            <v-icon>mdi-trash-can</v-icon>
            この事務所を削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </transition-group>
    <div class="text-center pb-4">
      <v-btn
        class="ma-2"
        dark
        depressed
        outlined
        color="green darken-2"
        @click="addOffice(1)"
      >
        <v-icon class="mr-2">mdi-domain-plus</v-icon>
        事務所を追加する
      </v-btn>
    </div>
  </div>
</template>

<script>
import YubinBango from "yubinbango-core2";
export default {
  data() {
    return {
      office_state_cd: [],
      branch_cd: [],
      district_cd: [],
      office_prefecture: [],
      line_division: [],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    changes: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get("/api/masters/office_state_cd").then((response) => {
        this.office_state_cd = response.data;
        this.$delete(this.office_state_cd, 3);
        this.$delete(this.office_state_cd, 2);
      });
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
      await this.$axios.get("/api/masters/line_division").then((response) => {
        this.line_division = response.data;
      });
      this.addOffice(1);
    })();
  },
  methods: {
    getPrefecture(index, isClicked) {
      let _this = this.fields;
      let that = this;
      if(_this["offices"][index]["adress_postalcode"].length == 7){
        if (_this["offices"][index]["adress_postalcode"].substr(0, 3) == "000") {
          if(isClicked === true){
            _this["offices"][index]["adress_prefecture"] = "";
            _this["offices"][index]["adress_municipality"] = "";
            _this["offices"][index]["adress_building"] = "";
          }
          that.$axios
            .get(
              "/api/getBranchDistrictCd/" +
                _this["offices"][index]["adress_postalcode"] +
                "/false/false"
            )
            .then((response) => {
              if(response.data.branch_cd != ''){
                _this["offices"][index]["branch_cd"] = response.data.branch_cd;
              }
              if(response.data.branch_cd != ''){
                _this["offices"][index]["district_cd"] = response.data.district_cd;
              }
            });
        } else {
          this.getAddress(
            _this["offices"][index]["adress_postalcode"],
            function (addr) {
              if(isClicked === true){
                //住所検索ボタンを押した時にのみ住所を自動入力
                _this["offices"][index]["adress_prefecture"] = addr.prefecture;
                _this["offices"][index]["adress_municipality"] = addr.municipality;
                _this["offices"][index]["adress_building"] = ""; //住所検索ボタンを押した時は「建物名以下」は空欄にする
              }
              if (addr.region != "") {
                var postalcode = _this["offices"][index]["adress_postalcode"]
                  ? _this["offices"][index]["adress_postalcode"]
                  : 0;
                that.$axios
                  .get(
                    "/api/getBranchDistrictCd/" +
                      postalcode +
                      "/" +
                      addr.region +
                      "/" +
                      addr.locality
                  )
                  .then((response) => {
                    if(response.data.branch_cd != ''){
                      _this["offices"][index]["branch_cd"] =
                        response.data.branch_cd;
                    }
                    if(response.data.district_cd != ''){
                      _this["offices"][index]["district_cd"] =
                        response.data.district_cd;
                    }
                  });
              }
            }
          );
        }
      }
    },
    setKaigai(index) {
      let _this = this.fields;
      let that = this;
      _this["offices"][index]["adress_prefecture"] = '';
      _this["offices"][index]["adress_postalcode"] = "0000000";
      _this["offices"][index]["adress_municipality"] = "";
      that.$axios
        .get(
          "/api/getBranchDistrictCd/" +
            _this["offices"][index]["adress_postalcode"] +
            "/false/false"
        )
        .then((response) => {
          _this["office_entry_requests"][index]["branch_cd"] =
          response.data.branch_cd;
          _this["office_entry_requests"][index]["district_cd"] =
          response.data.district_cd;
        });
    },
    removeOffice(index) {
      this.errors = [];
      this.fields.offices.splice(index, 1);
    },

    addOffice(addCount) {
      for (var i = 0; i < addCount; i++) {
        let arrLength = Object.keys(this.fields["offices"]).length;
        const new_index = arrLength == 0 ? 0 : arrLength;
        this.$set(this.fields["offices"], new_index, {
          name_kana: null,
          name: null,
          adress_postalcode: null,
          adress_prefecture: "",
          adress_municipality: null,
          adress_building: null,
          tel1: null,
          tel1_line_division: "",
          tel1_extension: null,
          tel2: null,
          tel2_line_division: "",
          tel2_extension: null,
          fax1: null,
          fax2: null,
        });
        if (new_index == 0) {
          this.$set(this.fields["offices"], new_index, {
            office_state_cd: 1,
            adress_prefecture: "",
          });
        } else {
          this.$set(this.fields["offices"], new_index, {
            office_state_cd: 2,
            adress_prefecture: "",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
