import axios from "axios";
import store from "../store";

export function checkAuth() {
  return new Promise((resolve) => {
    if(store.state.auth.authrole){
      resolve(true);
    }
    else{
      axios.get("/api/login_user").then((response) => {
        if (response.status == 200 && response.data != 0) {
          resolve(true);
        }
      }).catch(
          function (error) {
              resolve(false);
          }
        );
    }
    
  });

}


export function checkAuthByRole(role) {
  return new Promise((resolve) => {
    if(role.includes(store.state.auth.authrole)){
      resolve(true);
    }
    else{
      resolve(false);
    }
  });
}