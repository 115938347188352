<template>
  <div>
    <transition-group tag="div">
      <template v-for="(office, index) in offices">
        <v-card :key="index" class="mb-5" v-if="office['delete_flag'] == false">
          <v-card-title class="px-4 py-2 blue-grey darken-1">
            <h3 class="subtitle-1 font-weight-bold white--text">事務所</h3>
          </v-card-title>

          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr
                  :class="{
                    active: changes.office_state_change_presence[index],
                  }"
                >
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.office_state_change_presence[index]"
                        hide-details
                        color="light-blue darken-4"
                        class="mt-0 mb-1"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>事務所状態</span>
                    </div>
                  </th>
                  <td>
                    <div class="d-md-flex justify-space-between">
                      <v-radio-group row dense v-model="office.office_state_cd">
                        <v-radio
                          v-for="cd in office_state_cd"
                          :key="cd.id"
                          :label="cd.label"
                          :value="cd.id"
                          color="light-blue darken-4"
                          :disabled="
                            !changes.office_state_change_presence[index]
                          "
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      v-if="
                        errors &&
                        errors &&
                        errors['belong_offices.' + index + '.office_state_cd']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_state_cd"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.name_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.name_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>フリガナ</span>
                    </div>
                  </th>
                  <td>
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      background-color="white"
                      color="light-blue darken-4"
                      class="mr-2"
                      v-model="office.office_kana"
                      :disabled="!changes.name_change_presence[index]"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_kana']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".office_kana"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.name_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-end align-center">
                      <span>事務所名</span>
                    </div>
                  </th>
                  <td>
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      value="東京特許事務所"
                      background-color="white"
                      class="mr-2"
                      color="light-blue darken-4"
                      v-model="office.offfice_kanji"
                      :disabled="!changes.name_change_presence[index]"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.offfice_kanji']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".offfice_kanji"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.adress_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.adress_change_presence[index]"
                        hide-details
                        color="light-blue darken-4"
                        class="mt-0 mb-1"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>住所</span>
                    </div>
                  </th>
                  <td class="pb-2">
                    <div class="d-md-flex justify-start py-2">
                      <div style="width: 200px">
                        <v-text-field
                          label="郵便番号(ハイフン無し)"
                          outlined
                          hide-details
                          color="light-blue darken-4"
                          background-color="white"
                          dense
                          v-model="office.office_postalcode"
                          :disabled="!changes.adress_change_presence[index]"
                          @input="getPrefecture(office, false)"
                        ></v-text-field>
                        <div
                          v-if="
                            errors &&
                            errors[
                              'belong_offices.' + index + '.office_postalcode'
                            ]
                          "
                          class="text-danger"
                        >
                          {{
                            errors[
                              "belong_offices." + index + ".office_postalcode"
                            ][0]
                          }}
                        </div>
                      </div>
                      <v-btn
                        class="mx-2"
                        :disabled="!changes.adress_change_presence[index]"
                        @click="getPrefecture(office, true)"
                        >住所検索</v-btn
                      >
                      <v-btn :disabled="!changes.adress_change_presence[index]"
                        @click="setKaigai(office)"
                        >海外</v-btn
                      >
                    </div>
                    <div class="d-md-flex justify-start align-center pb-2">
                      <div style="width: 200px">
                        <v-select
                          label="都道府県"
                          :items="office_prefecture"
                          item-text="label"
                          item-value="value"
                          outlined
                          hide-details
                          color="light-blue darken-4"
                          background-color="white"
                          flat
                          dense
                          v-model="office.office_prefecture"
                          :disabled="!changes.adress_change_presence[index]"
                          @change="getBranchCd(office)"
                        ></v-select>
                        <div
                          v-if="
                            errors &&
                            errors[
                              'belong_offices.' + index + '.office_prefecture'
                            ]
                          "
                          class="text-danger"
                        >
                          {{
                            errors[
                              "belong_offices." + index + ".office_prefecture"
                            ][0]
                          }}
                        </div>
                      </div>
                    </div>
                    <v-text-field
                      label="住所"
                      outlined
                      hide-details
                      color="teal darken-4"
                      class="mb-2"
                      background-color="white"
                      dense
                      v-model="office.office_municipality"
                      :disabled="!changes.adress_change_presence[index]"
                      @blur="getBranchCd(office)"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.office_municipality'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_municipality"
                        ][0]
                      }}
                    </div>
                    <v-text-field
                      label="建物名以下"
                      outlined
                      hide-details
                      color="teal darken-4"
                      background-color="white"
                      dense
                      v-model="office.office_building"
                      :disabled="!changes.adress_change_presence[index]"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_building']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_building"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr :class="{ active: changes.tel1_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel1_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>事務所TEL1</span>
                    </div>
                  </th>
                  <td>
                    <v-text-field
                      label="000-0000-0000"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel1"
                      :disabled="!changes.tel1_change_presence[index]"
                      class="my-2"
                      color="light-blue darken-4"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.tel1']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".tel1"][0] }}
                    </div>
                  </td>
                  <td>
                    <v-select
                      label="回線区分"
                      :items="[{ label: '選択してください', id: '' }, ...line_division]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="light-blue darken-4"
                      background-color="white"
                      flat
                      dense
                      v-model="office.tel1_line_division"
                      :disabled="!changes.tel1_change_presence[index]"
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.tel1_line_division'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".tel1_line_division"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.tel1_extension_change_presence[index],
                  }"
                >
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel1_extension_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>内線</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel1_extension"
                      :disabled="!changes.tel1_extension_change_presence[index]"
                      class="my-2"
                      color="light-blue darken-4"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.tel1_extension']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".tel1_extension"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.tel2_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel2_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>事務所TEL2</span>
                    </div>
                  </th>
                  <td>
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel2"
                      :disabled="!changes.tel2_change_presence[index]"
                      class="my-2"
                      color="light-blue darken-4"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.tel2']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".tel2"][0] }}
                    </div>
                  </td>
                  <td>
                    <v-select
                      label="回線区分"
                      :items="[{ label: '選択してください', id: '' }, ...line_division]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="light-blue darken-4"
                      background-color="white"
                      flat
                      dense
                      v-model="office.tel2_line_division"
                      :disabled="!changes.tel2_change_presence[index]"
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.tel2_line_division'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".tel2_line_division"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.tel2_extension_change_presence[index],
                  }"
                >
                  <th
                    width="150"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel2_extension_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>内線</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      value="03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel2_extension"
                      :disabled="!changes.tel2_extension_change_presence[index]"
                      class="my-2"
                      color="light-blue darken-4"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.tel2_extension']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".tel2_extension"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.fax1_change_presence[index] }">
                  <th
                    width="100"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.fax1_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>事務所FAX1</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      value="03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.fax1"
                      :disabled="!changes.fax1_change_presence[index]"
                      class="my-2"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.fax1']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".fax1"][0] }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.fax2_change_presence[index] }">
                  <th
                    width="100"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.fax2_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                      ></v-checkbox>

                      <span>事務所FAX2</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      value="03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.fax2"
                      :disabled="!changes.fax2_change_presence[index]"
                      class="my-2"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.fax2']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".fax2"][0] }}
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.office_oparation_change_presence[index],
                  }"
                >
                  <th
                    width="100"
                    class="text-md-right px-3"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="
                          changes.office_oparation_change_presence[index]
                        "
                        hide-details
                        class="mt-0 mb-1"
                        color="light-blue darken-4"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>就業形態</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-select
                      :items="operation_status_cd"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="light-blue darken-4"
                      background-color="white"
                      flat
                      dense
                      v-model="office.operation_status_cd"
                      :disabled="
                        !changes.office_oparation_change_presence[index]
                      "
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.operation_status_cd'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".operation_status_cd"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions color="grey">
            <v-spacer></v-spacer>
            <v-btn
              v-if="index > officesLength"
              class="ma-2"
              dark
              depressed
              small
              color="red darken-2"
              @click.stop="removeOffice(index)"
            >
              <v-icon>mdi-delete</v-icon>
              この事務所を削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </transition-group>
    <div class="text-center pb-8">
      <v-btn
        class="ma-2"
        dark
        depressed
        outlined
        color="green darken-2"
        @click="addOffice"
      >
        <v-icon class="mr-2">mdi-domain-plus</v-icon>
        事務所を追加する
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offices: [],
      line_division: [],
      office_state_cd: [],
      operation_status_cd: [],
      office_prefecture: [],
      officesLength: 0,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    changes: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    fields(val) {
      this.offices = val["belong_offices"];
      this.officesLength = Object.keys(this.offices).length;
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get("/api/masters/office_state_cd").then((response) => {
        this.office_state_cd = response.data;
        this.office_state_cd.splice(2, 1);
      });
      await this.$axios
        .get("/api/masters/operation_status_cd")
        .then((response) => {
          this.operation_status_cd = response.data;
        });
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
      await this.$axios.get("/api/masters/line_division").then((response) => {
        this.line_division = response.data;
      });
    })();
  },
  methods: {
    getPrefecture(office, isClicked) {
      let _this = office;
      let that = this;

      if(_this.office_postalcode.length == 7){
        if(_this.office_postalcode.substr(0, 3) == "000"){
          if(isClicked === true){
            _this.office_prefecture = "";
            _this.office_municipality = "";
            _this.office_building = "";
          }
          that.$axios
          .get(
            "/api/getBranchDistrictCd/" +
            _this.office_postalcode + "/false/false"
          )
          .then((response) => {
            if(response.data.branch_cd != ''){
              _this.branch_cd = response.data.branch_cd;
            }
            if(response.data.district_cd != ''){
              _this.district_cd = response.data.district_cd;
            }
          });
        }else {
          this.getAddress(_this.office_postalcode, function (addr) {
            if(isClicked === true){
              //住所検索ボタンを押した時にのみ住所を自動入力
              _this.office_prefecture = addr.prefecture;
              _this.office_municipality = addr.municipality;
              _this.office_building = ''; //住所検索ボタンを押した時は「建物名以下」は空欄にする
            }
            if (addr.region != "") {
              var postalcode = _this.adress_postalcode
                ? _this.adress_postalcode
                : 0;
              that.$axios
                .get(
                  "/api/getBranchDistrictCd/" +
                    postalcode +
                    "/" +
                    addr.region +
                    "/" +
                    addr.locality
                )
                .then((response) => {
                  if(response.data.branch_cd != ''){
                    _this.branch_cd = response.data.branch_cd;
                  }
                  if(response.data.district_cd != ''){
                    _this.district_cd = response.data.district_cd;
                  }
                });
            }
          });
        }
      }
    },
    setKaigai(office) {
      let _this = office;
      _this.office_prefecture = null;
      _this.office_postalcode = "0000000";
      _this.office_municipality = "";
    },
    getBranchCd(office){
      let _this = office;
      let prefecture = _this.office_prefecture;
      if(prefecture == null || prefecture.length < 1) {
          _this.branch_cd = '';
          _this.district_cd = '';
          return
      }
      var postalcode = (_this.office_postalcode)?_this.office_postalcode:0;
      var municipality = (_this.office_municipality)?_this.office_municipality:0;
      if(postalcode !== 0){
        this.getAddress(postalcode, function (addr) {
          municipality = addr.municipality;
          this.$axios.get("/api/getBranchDistrictCd/"+postalcode +"/"+prefecture+"/"+municipality).then((response) => {
              _this.branch_cd = response.data.branch_cd;
              _this.district_cd = response.data.district_cd;
          }).catch((error) => {
              _this.branch_cd = '';
              _this.district_cd = '';
          });
        });
      }
    },
    removeOffice(index) {
      if (window.confirm("事務所を削除してよろしいですか？")) {
        this.offices[index]["delete_flag"] = true;
        const newOffice = {};
        var i = 1;
        for (const [key, value] of Object.entries(this.offices)) {
          this.$set(newOffice, i, value);
          i++;
        }
        this.offices = newOffice;
      }
    },
    addOffice() {
      const title = Object.keys(this.offices).length + 1;
      this.$set(this.offices, title, {
        id: null,
        corporation_id: this.$route.params.id,
        office_state_cd: null,
        operation_status_cd: null,
        office_kana: "",
        offfice_kanji: "",
        office_postalcode: null,
        office_prefecture: null,
        office_municipality: null,
        office_building: null,
        tel1: null,
        tel1_line_division: null,
        tel1_extension: null,
        tel2: null,
        tel2_line_division: null,
        tel2_extension: null,
        fax1: null,
        fax2: null,
        delete_flag: false,
      });
      this.changes.office_state_change_presence[title] = true;
      this.changes.name_change_presence[title] = true;
      this.changes.adress_change_presence[title] = true;
      this.changes.tel1_change_presence[title] = true;
      this.changes.fax1_change_presence[title] = true;
      this.changes.office_oparation_change_presence[title] = true;
    },
  },
};
</script>

<style type="scss" scoped>
.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
