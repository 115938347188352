<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">各種申請書ダウンロード</h2>
    <v-row justify="center" class="my-9">
      <v-col lg="8" v-for="document in documents" :key="document.row">
        <v-card>
          <v-card-title class="px-4 py-2 cyan darken-1">
            <h3 class="subtitle-1 font-weight-bold white--text">
              {{ document.name }}
            </h3>
          </v-card-title>

          <v-list-item-group>
            <template v-for="item in document.items">
              <v-list-item :key="item.title" inactive>
                <div
                  class="d-lg-flex justify-space-between align-center"
                  style="width: 100%;"
                >
                  <v-list-item-content class="py-lg-9 pt-5">
                    <v-list-item-title class="font-weight-bold">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <div class="d-lg-block d-flex justify-end pb-lg-0 pb-3">
                    <v-btn
                      small
                      dark
                      :class="[
                        item.extension === 'xlsx'
                          ? 'green--text text--darken-1 font-weight-bold mr-3'
                          : 'blue--text text--darken-1 font-weight-bold mr-3'
                      ]"
                      target="blank"
                      outlined
                      :href="'/doc/' + item.title + '.' + item.extension"
                    >
                      <span v-if="item.extension === 'xlsx'">
                        <v-icon class="mr-1">mdi-microsoft-excel</v-icon>Excel
                      </span>
                      <span v-else>
                        <v-icon class="mr-1">mdi-microsoft-word</v-icon>Word
                      </span></v-btn
                    >
                    <v-btn
                      v-if="item.title === '弁理士法人の変更事項届出書'"
                      small
                      dark
                      class="blue--text text--darken-1 font-weight-bold mr-3"
                      target="blank"
                      outlined
                      href="/doc/【記載例】弁理士法人の変更事項届出書.docx"
                      ><v-icon class="mr-1">mdi-microsoft-word</v-icon>記載例
                    </v-btn>
                    <v-btn
                      small
                      dark
                      color="red darken-3"
                      :href="'/doc/' + item.title + '.pdf'"
                      target="blank"
                      class="font-weight-bold"
                      outlined
                    >
                      <v-icon class="mr-1">mdi-file-pdf-box</v-icon>PDF
                    </v-btn>
                    <v-btn
                      v-if="item.title === '弁理士法人の変更事項届出書'"
                      small
                      dark
                      color="red darken-3"
                      href="/doc/【記載例】弁理士法人の変更事項届出書.pdf"
                      target="blank"
                      class="font-weight-bold ml-3"
                      outlined
                      ><v-icon class="mr-1">mdi-file-pdf-box</v-icon>記載例PDF
                    </v-btn>
                  </div>
                </div>
              </v-list-item>
              <v-divider :key="item.index"></v-divider>
            </template>
          </v-list-item-group>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center my-6">
      <v-col lg="8" class="py-6"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      documents: [
        {
          row: "1",
          name: "登録情報の変更届出",
          items: [
            {
              title: "弁理士登録・届出事項変更届",
              extension: "docx"
            },
            {
              title: "勤務証明書",
              extension: "docx"
            },
            {
              title: "特許庁への届出書式",
              extension: "docx"
            }
          ]
        },
        {
          row: "2",
          name: "各種証明書・交付物発行申請",
          items: [
            {
              title: "登録証明申請書",
              extension: "xlsx"
            },
            {
              title: "身分証明書発行申請書",
              extension: "docx"
            },
            {
              title: "事務所職員身分証明書用紙貸与申請書",
              extension: "docx"
            },
            {
              title: "弁理士略章申請書",
              extension: "docx"
            },
            {
              title: "弁理士記章再交付申請書",
              extension: "docx"
            }
          ]
        },
        {
          row: "3",
          name: "弁理士法人関係届出",
          items: [
            {
              title: "弁理士法人の変更事項届出書",
              extension: "xlsx"
            },
            {
              title: "弁理士法人成立届出書",
              extension: "docx"
            },
            {
              title: "弁理士法人の解散届出書／残務届",
              extension: "docx"
            },
            {
              title: "弁理士法人の清算結了届出書",
              extension: "docx"
            },
            {
              title: "遅延理由書（見本）",
              extension: "docx"
            },
            {
              title: "弁理士法人の社員資格申請書（途中加入）",
              extension: "docx"
            },
            {
              title: "弁理士法人の社員資格申請書（設立）",
              extension: "docx"
            }
          ]
        },
        {
          row: "4",
          name: "特定侵害訴訟代理業務の付記申請",
          items: [
            {
              title: "付記申請書",
              extension: "docx"
            }
          ]
        },
        {
          row: "5",
          name: "弁理士登録の抹消",
          items: [
            {
              title: "弁理士登録抹消届出書",
              extension: "docx"
            },
            {
              title: "弁理士登録抹消に伴う残務届",
              extension: "docx"
            }
          ]
        }
      ]
    };
  }
};
</script>
