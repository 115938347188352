<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">弁理士法人の変更事項届出書作成</h2>

    <v-row class="my-5" justify="center" align="center">
      <v-col lg="8">
        <v-card class="my-9">
          <v-card-title class="px-4 py-2 cyan darken-2">
            <h3 class="subtitle-1 font-weight-bold white--text">
              対象法人選択
            </h3>
          </v-card-title>

          <v-row justify="center" class="pa-5">
            <v-col cols="12">
              <v-select
                v-model="employee"
                :items="[{ label: '選択してください', id: '' }, ...items]"
                item-text="label"
                item-value="id"
                outlined
                hide-details
                color="cyan darken-2"
                background-color="white"
                flat
                dense
                @change="getData(employee)"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
        <div v-if="employee != ''">
          <v-card class="my-9" color="cyan lighten-5">
            <v-row justify="center" class="px-md-5">
              <v-col cols="12">
                <v-simple-table class="transparent my-5">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th width="100">申請者</th>
                        <td colspan="2" >
                          <div class="d-flex" align="center">
                            <div style="width: 300px">
                              <v-combobox
                                v-model="fields.application_by"
                                :items="empList"
                                item-text="label"
                                item-value="number"
                                solo
                                outlined
                                dense
                                flat
                                hide-details
                                color="teal darken-3"
                                background-color="white"
                                clearable
                                :return-object="false"
                                ref="combobox"
                                @change="getApplicantName"
                              ></v-combobox>           
                              <div v-if="errors && errors.application_by" class="text-danger">
                                {{ errors.application_by[0] }}
                              </div>
                            </div>
                            <div style="width: 100px;" class="mt-3">
                              {{ applicant_name }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th width="100" style="vertical-align: middle;">変更の日</th>
                        <td>
                          <div class="d-flex justify-between" v-for="(change_date, key) in change_dates" :key="key" style="margin-top: 5px;margin-bottom: 5px;">
                            <div style="width: 200px">
                              <v-menu
                                v-model="change_date.changeDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="change_date.date"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    hide-details
                                    dense
                                    color="teal darken-3"
                                    background-color="white"
                                    class="datepick"
                                    @click:clear="clearDate"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="change_date.date"
                                  no-title
                                  @input="change_date.changeDateMenu = false"
                                  :day-format="(date) => new Date(date).getDate()"
                                  @change="calculateDifference"
                                >
                                </v-date-picker>
                              </v-menu>
                            </div>
                            <div style="width: 250px">
                              <v-text-field
                                label="メモ"
                                outlined
                                hide-details
                                color="teal darken-3"
                                background-color="white"
                                dense
                                class="ml-1"
                                v-model="change_date.memo"
                              ></v-text-field>
                            </div>
                            <v-btn small icon style="margin-top: 5px;">
                              <v-icon @click="removeChangeDate(key)">
                              mdi-trash-can
                              </v-icon>
                            </v-btn>
                          </div>
                          <div v-if="errors && errors.change_date_date" class="text-danger">
                            {{ errors.change_date_date[0] }}
                          </div>
                          <div style="color: orange">
                            変更事項が複数にわたる場合はそれぞれの変更の日を入力し、メモ欄に該当の変更内容を簡単に記載してください
                          </div>
                        </td>
                        <td>
                          <div>
                            <v-btn
                              dark
                              color="teal darken-2"
                              class="mr-2 font-weight-bold"
                              small
                              @click="addChangeDate()"
                            >
                              <v-icon small>mdi-plus</v-icon>追加</v-btn
                            >
                          </div>
                        </td>
                        <!-- <td>
                          <div
                            class="d-md-flex justify-content-start align-center"
                          >
                            <div style="width: 200px">
                              <v-menu
                                v-model="changeDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fields.change_date"
                                    prepend-inner-icon="mdi-calendar"
                                    @click:prepend-inner="on.click"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    hide-details
                                    dense
                                    color="cyan darken-2"
                                    background-color="white"
                                    class="datepick"
                                    clearable
                                    @click:clear="clearDate"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fields.change_date"
                                  no-title
                                  color="cyan darken-2"
                                  @input="changeDateMenu = false"
                                  :day-format="
                                    (date) => new Date(date).getDate()
                                  "
                                  @change="calculateDifference"
                                >
                                </v-date-picker>
                              </v-menu>
                              <div
                                v-if="errors && errors.change_date"
                                class="text-danger"
                              >
                                {{ errors.change_date[0] }}
                              </div>
                            </div>
                            <div class="ml-md-3 mt-2 mt-md-0">
                              <v-tooltip top :open-on-hover="false">
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    small
                                    @click="on.click"
                                    @blur="on.blur"
                                    retain-focus-on-click
                                    text
                                    color="cyan darken-2"
                                  >
                                    <v-icon dark depressed color="black">
                                      mdi-chat-question-outline
                                    </v-icon>
                                    <span
                                      class="
                                        text-caption
                                        ml-1
                                        text-decoration-underline
                                      "
                                      >詳細</span
                                    >
                                  </v-btn>
                                </template>
                                <p class="mb-0">
                                  登記の変更を行った場合は登記の日。<br />それ以外の場合は変更日を記載してください。
                                </p>
                              </v-tooltip>
                            </div>
                          </div>
                        </td> -->
                      </tr>
                       <tr>
                        <th width="100">変更届出日</th>
                        <td class="py-4" colspan="2">
                          <div
                            class="d-md-flex justify-content-start align-center"
                          >
                            <div style="width: 200px">
                              <v-menu
                                v-model="changeRequestedAtMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fields.change_requested_at"
                                    prepend-inner-icon="mdi-calendar"
                                    @click:prepend-inner="on.click"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    hide-details
                                    dense
                                    color="cyan darken-2"
                                    background-color="white"
                                    class="datepick"
                                    clearable
                                    @click:clear="clearDate"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fields.change_requested_at"
                                  no-title
                                  color="cyan darken-2"
                                  :min="minDate"
                                  @input="changeRequestedAtMenu = false"
                                  :day-format="
                                    (date) => new Date(date).getDate()
                                  "
                                  @change="calculateDifference"
                                >
                                </v-date-picker>
                              </v-menu>
                              <div
                                v-if="errors && errors['change_requested_at']"
                                class="text-danger"
                              >
                                {{ errors["change_requested_at"][0] }}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="fields.has_delay_reason">
                        <th width="100">遅延理由</th>
                        <td>
                          <v-textarea
                            outlined
                            hide-details
                            color="cyan darken-2"
                            style="background:#fff;"
                            v-model="fields.delay_reason"
                            rows="4"
                          ></v-textarea>
                          <div
                            v-if="errors && errors.delay_reason"
                            class="text-danger"
                          >
                            {{ errors.delay_reason[0] }}
                          </div>
                        </td>
                        <v-card-text class="mt-5 mb-0">
                          <p>
                            <v-dialog v-model="dialog" width="80%">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  text
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  記入例
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                  記入例
                                </v-card-title>
                                <v-card-text>
                                  （例）○○法務局の事務処理上の都合により、登記簿謄本が作成されるに要する日数が（成立・変更の登記）より2週間を超えてしまったため<br />

                                  （例）年末年始の長期休業により、○○法務局の事務手続に要する日数が成立の日（または、変更の生じた年月日）より2週間を超えてしまったため<br />

                                  （
                                  例）従たる事務所の登記及び登記簿謄本の取り寄せ等、届出までの事務処理に、日数を要してしまったため
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                                  >
                                    閉じる
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </p>
                        </v-card-text>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>

          <CorpTable
            :fields="fields"
            :errors="errors"
            :changes="copChangePresence"
          />
          <p>
            変更が生じる部分のチェックボックスにチェックを入れ、情報を変更してください。
          </p>
          <OfficeDetail
            :fields="fields"
            :errors="errors"
            :changes="officeChangePresence"
          />
          <Registration :fields="fields" :errors="errors" />
          <MemberTable
            :fields="fields"
            :errors="errors"
            :changes="employeeChangePresence"
          />
          <PatentTable
            :fields="fields"
            :errors="errors"
            :changes="employee3ChangePresence"
          />
          <div class="text-center mt-4 mb-6">
            <v-btn
              x-large
              dark
              color="orange accent-4 font-weight-bold"
              @click="submit"
              >変更依頼をする</v-btn
            >
          </div>

          <!-- <div class="text-center py-5">
          <v-btn
            class="ma-2"
            dark
            x-large
            color="cyan darken-1 font-weight-bold"
            to="/corporate_change/download"
          >
            変更依頼をする
          </v-btn>
        </div> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CorpTable from "./CorpTable";
import OfficeDetail from "./OfficeDetail";
import Registration from "./Registration";
import MemberTable from "./MemberTable";
import PatentTable from "./PatentTable";

export default {
  components: {
    CorpTable,
    OfficeDetail,
    Registration,
    MemberTable,
    PatentTable,
  },
  data() {
    return {
      fields: {
        change_date: [],
      },
      change_dates:[{
        date: null,
        memo: null,
        changeDateMenu: false,
      }],
      errors: {},
      copChangePresence: {
        checkboxName: false,
      },
      officeChangePresence: {
        office_state_change_presence: [],
        name_change_presence: [],
        adress_change_presence: [],
        tel1_change_presence: [],
        tel2_change_presence: [],
        tel1_extension_change_presence: [],
        tel2_extension_change_presence: [],
        fax1_change_presence: [],
        fax2_change_presence: [],
        office_oparation_change_presence: [],
      },
      employeeChangePresence: {
        check: [],
      },
      employee3ChangePresence: {
        check: [],
      },

      employee: "",
      items: [],
      empList: [],
      empNameList: [],
      minDate : new Date().toISOString().substr(0, 10),
      //デイトピッカー
      changeDateMenu: false,
      changeRequestedAtMenu: false,
      id: null,
      dialog: false,
      applicant_name: '',
    };
  },
  watch: {
    fields(val) {
      val.change_date = '';
      val.change_date = this.change_dates;
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get(`/api/selectperson`).then((response) => {
        this.items = response.data;
      });
      // this.addChangeDate();
      this.getMinDate()
    })();
  },
  methods: {
    removeChangeDate(key){
      this.$delete(this.change_dates, key);
      this.fields['change_date'] = this.change_dates;
    },
    getMinDate() {
      if(this.change_dates.length >0) {
        var date_arr = [];
        this.change_dates.forEach(function (item) {
          if (item.date!==null) {
            date_arr.push(item.date);
          }
        });
        var orderedDates = date_arr.sort(function(a, b) {
          return Date.parse(a) - Date.parse(b);
        });
        this.minDate = orderedDates[0];
      }
    },
    getData(id) {
      (async () => {
        this.empList = []
        await this.$axios
          .get(`/api/selectapplicationby/${id}`)
          .then((response) => {
            for (const employee of response.data) {
              this.empList.push({
                'number' : employee.registration_number,
                'label' : employee.name_kanji+"("+employee.registration_number+")"
              })
              this.empNameList[employee.registration_number] = employee.name_kanji
            }
          });
        await this.$axios.get(`/api/getCorporationByEmp/${id}`).then((response) => {
          this.fields = response.data;
          this.id = this.fields.id;
          
          (this.fields.change_requested_at = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10)),
            this.getApplicantName()
            this.$store.commit("setOverlay", {
              overlay: false,
            });
          for (const [key, value] of Object.entries(this.fields.employees)) {
            this.employeeChangePresence.check.push(false);
          }
          for (const [key, value] of Object.entries(this.fields.employees3)) {
            this.employee3ChangePresence.check.push(false);
          }
        });
      })();
    },
    async getApplicantName() {
      if(this.fields.application_by == '') {
        this.applicant_name = ''
        return
      }
      await this.$axios
          .get(`/api/member_by_number/${this.fields.application_by}`)
          .then(response => {
            if (response.data.exist == true && response.data.type == "person") {
              this.applicant_name = response.data.name_kanji
            } else {
              this.applicant_name = ''
            }
          })
          .catch(error => {
            this.applicant_name = ''
            if (error.response.status == "404") {
              this.$router.replace({
                path: "/404"
              });
            }
          });
    },
   async submit() {
      await this.$refs.combobox.blur(); // workaround for combobox value not updating until blur(https://github.com/vuetifyjs/vuetify/issues/4679,https://qiita.com/XIGN/items/6b448c292a5f93f02c9)
      this.buttonStatus = true;
      if (this.employee == "") {
        alert("対象企業を選択してください");
        this.buttonStatus = false;
        return false;
      }
      this.fields.change_date = this.change_dates;
      this.fields.copChange = this.copChangePresence;
      this.fields.officeChange = this.officeChangePresence;
      this.fields.status_id = 1;
      this.fields.change = "copChange";
      let arr = [];
      for (const [key, value] of Object.entries(this.employeeChangePresence)) {
        arr.push(value);
      }
      for (const [key, value] of Object.entries(this.employee3ChangePresence)) {
        arr.push(value);
      }
      var merge = [].concat.apply([], arr);
      this.fields.empChange = { check: merge };

      this.fields.employee = [];
      for (const [key, value] of Object.entries(this.fields.employees)) {
        this.fields.employee.push(value);
      }
      for (const [key, value] of Object.entries(this.fields.employees3)) {
        this.fields.employee.push(value);
      }

      let mainOfficeCount = 0 //主たる事務所1件必要バリデーション

      //全角英数字 => 半角処理 start
      for (let [index, belong_office] of Object.entries(this.fields.belong_offices)) {
        for (let [fieldName, fieldValue] of Object.entries(belong_office)) {
            belong_office[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName)
        }
  
        if(belong_office['office_state_cd'] == 1) {
          mainOfficeCount++;
        }
      }
      //全角英数字 => 半角処理 end

      if(mainOfficeCount < 1) {
        this.buttonStatus = false;
        this.$store.commit('setMessege', {
          text: '主たる事務所は1件必要です。',
          color: 'warning',
        })
        return false;
      }

      this.$axios
        .post(`/api/corporation_change/${this.id}`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit("setMessege", {
              text: response.data.message,
              color: "success",
            });
            this.buttonStatus = false;
            let uuid = response.data.uuid;
            this.$router.push({ path: `/corporate_change/download/${uuid}` });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            var msg = "入力内容にエラーがあります。内容を確認してください。";
            if (error.response.data.errorMessage) {
              msg = error.response.data.errorMessage;
            }
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: msg,
              color: "warning",
            });
            this.buttonStatus = false;
          }
        });
    },
    calculateDifference() {
      this.getMinDate()
      if (this.fields.change_requested_at && this.minDate) {
        var change_requested_at = new Date(this.fields.change_requested_at);
        var change_date = new Date(this.minDate);
        if (change_requested_at > change_date) {
          var time_diff =
            change_requested_at.getTime() - change_date.getTime();
        } else {
          time_diff = change_date.getTime() - change_requested_at.getTime();
        }

        if (change_requested_at < change_date) {
          this.fields.change_requested_at = null
        }
        var day_diff = time_diff / (1000 * 3600 * 24);
        if (day_diff > 14) {
          this.fields.has_delay_reason = true;
        } else {
          this.fields.has_delay_reason = false;
        }
      } else {
        this.fields.has_delay_reason = false;
      }
    },
    clearDate() {
      this.fields.has_delay_reason = false;
    },
    addChangeDate(){
      const title = this.change_dates.length;
      this.$set(this.change_dates, title, {
        date: null,
        memo: null,
        changeDateMenu: false,
      });
    },
  },
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #eceff1 !important;
}
td.top {
  vertical-align: top !important;
}
</style>
