<template>
  <div>
   <transition>
      <v-card class="my-9">
        <v-card-title class="px-4 py-2 blue-grey darken-1">
          <h3 class="subtitle-1 font-weight-bold white--text">
            使用人である弁理士
          </h3>
        </v-card-title>

        <v-row justify="center">
          <v-col>
          <v-data-table
            :headers="headers"
            :items="employees"
            hide-default-header
            hide-default-footer
            no-data-text="データがありません"
            disable-pagination
            class="mb-5"
          >
        <template v-slot:item="{ item, index }">
            <tr v-if="item.delete_flag == false" :class="{
                        active: changes.check[index]
                    }">
                <td>
                <v-checkbox
                    v-model="changes.check[index]"
                    hide-details
                    class="mt-0 mb-1"
                    color="light-blue darken-4"
                ></v-checkbox>
                </td>
                <td>
                    <div class="d-md-flex justify-start align-center">
                      <div>
                        <v-text-field
                            v-model="item.registration_number"
                            outlined
                            hide-details
                            color="light-blue darken-4"
                            background-color="white"
                            dense
                            label="登録番号"
                            @change="
                                change_registration_number(
                                item.registration_number,
                                index
                                )
                            "
                            :disabled="!changes.check[index]"
                        ></v-text-field>
                        <div
                            v-if="errors && errors['employee.' + index +'.registration_number']"
                            class="text-danger"
                          >
                            {{ errors['employee.' + index +'.registration_number'][0] }}
                        </div>
                      </div>
                      <div style="width: 30%;">
                        <div class="ma-2 my-md-0 ml-md-5">{{ item.name_kanji }}</div>
                      </div>
                      <v-btn small dark color="red darken-2" @click="removeEmployee(index)">
                        削除
                      </v-btn>
                  </div>
                </td>
            </tr>
            <tr v-else class='delete_text'>
                <td></td>
                <td>{{ item.registration_number }}</td>
                <td>{{ item.name_kanji }}</td>
                <td><span>削除予定</span></td>
            </tr>
        </template>
      </v-data-table>
      </v-col>
  </v-row>
    <div class="text-center pb-4">
      <v-btn
        class="ma-2"
        dark
        depressed
        outlined
        color="blue-grey darken-2"
        @click="addEmployee"
      >
        <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
        弁理士を追加する
      </v-btn>
    </div>
    </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
        person:[],
        employees: [],
        employeesLength: null,
        headers: [
        {
          text: "",
          sortable: false,
          width: "10%",
        },
        {
          text: "登録番号",
          sortable: false,
          width: "15%",
          value: "num",
        },
        {
          text: "氏名",
          sortable: false,
          width: "25%",
        },
        {
          text: "",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    changes: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    fields(val) {
        const employees = [];
        for (const [key, value] of Object.entries(val.employees3)) {
            employees.push(value);
        }
        this.employees = employees;
        this.employeesLength = Object.keys(this.employees).length;
        val.employees3 = '';
        val.employees3 = this.employees;
    },
  },
  mounted() {
    (async () => {
        await this.$axios
          .get("/api/masters/employment_2")
          .then(response => {
            this.employee_status = response.data;
            this.employee_status.splice(2, 1);//remove 使用人
        });   
    })();
  },
  methods: {
    removeEmployee(index) {
        if (confirm("削除してよろしいですか？")) {
            this.employees[index]["delete_flag"] = true;
            this.employees[index]["will_delete"] = 1;

            if(this.employees[index]['id'] == null){
                this.employees.splice(index, 1);
            }
            const newEmployee = [];
            var i = 0;
            for (const value of this.employees) {
                this.$set(newEmployee, i, value);
                i++;
            }
            this.employees = newEmployee;
            this.fields.employees3 = '';
            this.fields.employees3 = this.employees;
        }
    },
    addEmployee() {
        const title = this.employees.length;
        this.$set(this.employees, title, {
            id: null,
            employee_id: null,
            registration_number: null,
            name_kanji :null,
            notification_number: null,
            employment_status_cd: 3,
            will_delete :0,
            charge_cd: null,
            delete_flag: false
        });
        this.changes.check[title] = true;
        this.fields.employee = '';
        this.fields.employee = this.employees;
    },
    change_registration_number(number,index) {
      if (number != "") {
        this.$axios
            .get("/api/member_by_number/" + number)
            .then((response) => {
            if (response.data.exist == true && response.data.type == "person") {
                this.$set(this.employees[index], 'name_kanji', response.data.name_kanji);
            } else {
                this.$set(this.employees[index], 'name_kanji', '');
            }
            })
            .catch((error) => {
            this.$set(this.employees, false);
            });
      }else{
        this.$set(this.employees[index], 'name_kanji', '');
      }
    },
  }
};
</script>
<style type="scss">

.v-data-table__wrapper table thead tr th  {
    background-color:#ffffff !important;
}

.delete_text{
    background-color: #f2f2f2 !important;
}
</style>

<style type="scss" scoped>

.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
