<template>
  <v-card>
    <v-card-title class="px-4 py-2 blue-grey darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">
        法人情報
      </h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr :class="{ active: changes.checkboxName }">
            <th
              width="150"
              class="text-right px-3"
              style="vertical-align: middle;"
            >
              <div class="d-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.checkboxName"
                  hide-details
                  color="light-blue darken-4"
                  class="mt-0 mb-1"
                ></v-checkbox>

                <span>法人名フリガナ</span>
              </div>
            </th>
            <td class="py-4">
              <div class="d-flex justify-space-between">
                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  value="トウキョウベンリシホウジン"
                  background-color="white"
                  color="light-blue darken-4"
                  class="mr-2"
                  v-model="fields.office_kana"
                  :disabled="!changes.checkboxName"
                ></v-text-field>
              </div>
              <div
                  v-if="errors && errors.office_kana"
                  class="text-danger"
                >
                  {{ errors.office_kana[0] }}
              </div>
            </td>
          </tr>
          <tr :class="{ active: changes.checkboxName }">
            <th
              width="150"
              class="text-right px-3 bg-yellow lighten-5"
              style="vertical-align: middle;"
            >
              <div class="d-flex justify-end align-center">
                <span>法人名</span>
              </div>
            </th>
            <td class="py-4">
              <div class="d-flex justify-space-between">
                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  value="東京弁理士法人"
                  background-color="white"
                  class="mr-2"
                  v-model="fields.office_kanji"
                  :disabled="!changes.checkboxName"
                ></v-text-field>
              </div>
              <div
                  v-if="errors && errors.office_kanji"
                  class="text-danger"
                >
                  {{ errors.office_kanji[0] }}
                </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      name: null,
     
      files: [
        {
          title: "1"
        }
      ]
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    },
    changes: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    createFile() {
      const title = this.files.length + 1;
      this.files.push({ title: title });
    },
    deleteFile(index) {
      if (window.confirm("ファイルを削除してよろしいですか？")) {
        this.files.splice(index, 1);
      }
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
