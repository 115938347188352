<template>
  <v-container grid-list-md>
    <h2 class="text-center pa-2">会員登録情報 変更申請完了</h2>
    <div class="text-center my-9">
      <v-btn
        large
        dark
        color="green darken-1"
        class="font-weight-bold"
        @click="exportPdf"
      >
        <v-icon right class="mr-2"> mdi-file-download </v-icon>
        申請書をダウンロードする</v-btn
      >
    </div>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    exportPdf() {
      window.open(`/api/doc/notification_change_form/${this.$route.params.id}`, '_blank');
    },
  },
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #e0f7fa !important;
}
.disabled {
  background-color: #eeeeee !important;
}
td.top {
  vertical-align: top !important;
}
</style>
