<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">メールアドレス</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th
              width="25%"
              class="text-right px-3"
              style="vertical-align: middle"
            >
              <span>Eメールアドレス1</span>
            </th>
            <td>
              <div class="d-md-flex justify-start align-center">
                <v-text-field
                  placeholder="minoru.suzuki@example.com"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                  v-model="fields.email1"
                ></v-text-field>
                <div v-if="errors && errors.email1" class="text-danger">
                  {{ errors.email1[0] }}
                </div>
                <v-tooltip top :open-on-hover="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      @click="on.click"
                      @blur="on.blur"
                      retain-focus-on-click
                      text
                      color="cyan darken-2"
                    >
                      <v-icon dark depressed color="black">
                        mdi-chat-question-outline
                      </v-icon>
                      <span class="text-caption ml-1 text-decoration-underline"
                        >詳細</span
                      >
                    </v-btn>
                  </template>
                  <p class="mb-0">
                    原則として、日本弁理士会からの連絡、各種情報の提供については、<br />メールアドレス１宛に送信します。
                  </p>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right">掲載可否</th>
            <td>
              <v-radio-group row dense v-model="fields.email_publication">
                <v-radio
                  v-for="pub in publication"
                  :key="pub.id"
                  :label="pub.label"
                  :value="pub.id"
                  color="cyan darken-2"
                ></v-radio>
              </v-radio-group>
              <div class="grey--text text--darken-1 text-caption mt-3">
                名簿にメールアドレスの掲載を希望する場合は「掲載可」を、希望しない場合は「掲載否」を選択してください
              </div>
              <div
                v-if="errors && errors.email_publication"
                class="text-danger"
              >
                {{ errors.email_publication[0] }}
              </div>
            </td>
          </tr>
          <tr>
            <th
              width="150"
              class="text-right px-3"
              style="vertical-align: middle"
            >
              <span>Eメールアドレス2</span>
            </th>
            <td>
              <div class="d-md-flex justify-start align-center">
                <v-text-field
                  placeholder="okada06@example.org"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                  v-model="fields.email2"
                ></v-text-field>
                <div v-if="errors && errors.email2" class="text-danger">
                  {{ errors.email2[0] }}
                </div>
                <v-tooltip top :open-on-hover="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      @click="on.click"
                      @blur="on.blur"
                      retain-focus-on-click
                      text
                      color="cyan darken-2"
                    >
                      <v-icon dark depressed color="black">
                        mdi-chat-question-outline
                      </v-icon>
                      <span class="text-caption ml-1 text-decoration-underline"
                        >詳細</span
                      >
                    </v-btn>
                  </template>
                  <p class="mb-0">
                    日本弁理士会からの緊急性を要する連絡（災害時、防災関係等）、緊急情報について送信します。<br />
                    原則として緊急連絡等のみに使用し、一切公開されません。
                  </p>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <tr>
            <th
              width="150"
              class="text-right px-3"
              style="vertical-align: middle"
            >
              <span>Eメールアドレス3</span>
            </th>
            <td>
              <div class="d-md-flex justify-start align-center">
                <v-text-field
                  placeholder="tanahashi81@example.net"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2"
                  background-color="white"
                  color="cyan darken-2"
                  v-model="fields.email3"
                ></v-text-field>
                <div v-if="errors && errors.email3" class="text-danger">
                  {{ errors.email3[0] }}
                </div>
                <v-tooltip top :open-on-hover="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      @click="on.click"
                      @blur="on.blur"
                      retain-focus-on-click
                      text
                      color="cyan darken-2"
                    >
                      <v-icon dark depressed color="black">
                        mdi-chat-question-outline
                      </v-icon>
                      <span class="text-caption ml-1 text-decoration-underline"
                        >詳細</span
                      >
                    </v-btn>
                  </template>
                  <p class="mb-0">
                    日本弁理士会からの緊急性を要する連絡（災害時、防災関係等）、緊急情報について送信します。<br />
                    原則として緊急連絡等のみに使用し、一切公開されません。
                  </p>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      publication: [],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$store.commit("setOverlay", {
      overlay: true,
    });
    (async () => {
      await this.$axios.get("/api/masters/publication").then((response) => {
        this.publication = response.data;
      });
      this.$store.commit("setOverlay", {
        overlay: false,
      });
    })();
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
