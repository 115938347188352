<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center mb-9">納付状況</h2>

    <v-row justify="center" align="center">
      <v-col lg="10">
        <v-card color="cyan lighten-5">
          <v-card-title class="py-2 px-4 cyan darken-2">
            <h3 class="subtitle-1 font-weight-bold white--text">検索</h3>
          </v-card-title>
          <v-divider> </v-divider>
          <form id="search_form">
            <v-row class="pa-6 pb-0">
              <v-col cols="12">
                <div class="d-md-flex justify-center align-center">
                  <div class="text-subtitle-1 mr-4">対象期間</div>
                  <div style="width: 200px">
                    <v-menu
                      v-model="requestDateSearch"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="searchForm.target_start"
                          prepend-inner-icon="mdi-calendar"
                          @click:prepend-inner="on.click"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                          dense
                          color="cyan darken-2"
                          background-color="white"
                          class="datepick"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchForm.target_start"
                        no-title
                        color="cyan darken-2"
                        @input="requestDateSearch = false"
                        type="month"
                        :day-format="(date) => new Date(date).getDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="px-4">〜</div>
                  <div style="width: 200px">
                    <v-menu
                      v-model="requestDateSearch2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="searchForm.target_end"
                          prepend-inner-icon="mdi-calendar"
                          @click:prepend-inner="on.click"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                          dense
                          color="cyan darken-2"
                          background-color="white"
                          class="datepick"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="searchForm.target_end"
                        no-title
                        color="cyan darken-2"
                        @input="requestDateSearch2 = false"
                        type="month"
                        :day-format="(date) => new Date(date).getDate()"
                      >
                      </v-date-picker>
                    </v-menu>
                  </div>
                  <div class="px-4">&nbsp;</div>
                  <div style="width: 200px">
                    <v-select
                      label="納付状況"
                      v-model="searchForm.fee_status_cd"
                      :items="[
                        { label: '全て', id: '' },
                        ...search_fee_statuses,
                      ]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      flat
                      color="cyan darken-3"
                      background-color="white"
                      dense
                    ></v-select>
                  </div>
                </div>
              </v-col>
            </v-row>
          </form>
          <div class="text-center py-4">
            <v-btn
              class="ma-2"
              dark
              color="blue-grey darken-2"
              @click="Search()"
            >
              <v-icon>mdi-magnify</v-icon>
              検索
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="blue-grey darken-2"
              v-bind:href="'/fee_history?clear'"
            >
              クリア
            </v-btn>
          </div>
          <v-row justify="center" align="center">
            <v-col lg="8">
              <v-card class="mb-4" color="white lighten-5">
                <v-card-text class="pt-4">
                  <ul class="mb-2">
                    <li>２０２３年１月分以降の会費について発行できます</li>
                    <li>
                      <span class="red--text">
                        下記日数を目途に発行可能となります<br />
                        【会費】<br />
                        <ul style="list-style-type: none">
                          <li>振込：振込日の５営業日後頃</li>
                          <li>口座振替：翌月中旬頃</li>
                        </ul>
                        【新規登録料・登録月会費】
                        <ul style="list-style-type: none">
                          <li>弁理士登録後、５～１０営業日後頃</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      一度に発行できるのは<strong>最大</strong>１２か月分です。
                    </li>
                    <li>
                      事務所名は操作時のご登録情報のみ表示されます。領収書宛名が異なる場合など、ご不明な場合は会計課にご連絡ください。
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" class="mt-6 mb-5">
      <v-col lg="10">
        <div class="text-left">
          <v-dialog v-model="dialog" max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2 ml-0 font-weight-bold white--text"
                color="cyan darken-3"
                :disabled="checkedList.length < 1 || loading"
                @click="dialog = true"
                :loading="loading"
                v-bind="attrs"
                v-on="on"
              >
                チェックした領収書の発行
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >領収書宛名</v-card-title
              >
              <v-card-text>
                <v-select
                  :items="[
                    { label: '会員氏名', id: 1 },
                    { label: '主たる事務所', id: 2 },
                  ]"
                  item-text="label"
                  item-value="id"
                  solo
                  outlined
                  hide-details
                  dense
                  background-color="white"
                  flat
                  class="py-2"
                  v-model="to"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="cyan darken-2"
                  class="white--text"
                  @click="issue()"
                  :loading="loading"
                  :disabled="checkedList.length < 1 || loading"
                >
                  発行する
                </v-btn>
                <v-btn
                  color="grey darken-3"
                  text
                  @click="dialog = false"
                  :loading="loading"
                  :disabled="loading"
                >
                  キャンセル
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            no-data-text="データがありません"
            hide-default-footer
            disable-pagination
            :custom-sort="tableSort"
            :loading="isLoading"
            loading-text="ロード中"
          >
            <template v-slot:item="{ item, index }">
              <!-- 複数の入金がある場合(チェック/発行出来ない) -->
              <tr v-if="item.multiple_deposit == true">
                <td></td>
                <td>{{ item.target_year_month | targetFormatStart }}</td>
                <td>{{ feeCodesLabel[item.fee_code] }}</td>
                <td>{{ item.deposited_at | dateFormat }}</td>
                <td>
                  <span v-if="item.repayment">{{
                    item.repayment.expectes_repay_date | dateFormat
                  }}</span>
                </td>
                <td>会計課にご連絡ください</td>
                <td>{{ item.tax_receipt_issued_at | dateFormat }}</td>
              </tr>
              <!-- 発行済の場合(チェック/発行出来ない) -->
              <tr v-else-if="item.tax_receipt_issued_at != null">
                <td></td>
                <td>{{ item.target_year_month | targetFormatStart }}</td>
                <td>{{ feeCodesLabel[item.fee_code] }}</td>
                <td>{{ item.deposited_at | dateFormat }}</td>
                <td>
                  <span v-if="item.repayment">{{
                    item.repayment.expectes_repay_date | dateFormat
                  }}</span>
                </td>
                <td>
                  <span
                    v-if="item.tax_receipt_issued_at != null"
                    class="d-flex justify-content-start align-center"
                  >
                    <div class="mr-1">発行済</div>
                    <div>
                      <v-tooltip top :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          再発行をご希望の方は会計課までお問い合わせください
                        </p>
                      </v-tooltip>
                    </div>
                  </span>
                </td>
                <td>{{ item.tax_receipt_issued_at | dateFormat }}</td>
              </tr>
              <!-- 発行可能 -->
              <tr v-else>
                <td>
                  <v-checkbox
                    v-if="item.issuable"
                    v-model="checkedList"
                    :key="item.id"
                    :value="item.target_year_month + '_' + item.id"
                    color="blue darken-4"
                    input-value="true"
                  ></v-checkbox>
                </td>
                <td>{{ item.target_year_month | targetFormatStart }}</td>
                <td>{{ feeCodesLabel[item.fee_code] }}</td>
                <td>{{ item.deposited_at | dateFormat }}</td>
                <td>
                  <span v-if="item.repayment">{{
                    item.repayment.expectes_repay_date | dateFormat
                  }}</span>
                </td>
                <td>
                  <span v-if="item.issuable">
                    <v-dialog v-model="item.dialog" max-width="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="cyan darken-2"
                          class="ma-2 ml-0 font-weight-bold white--text"
                          small
                          v-bind="attrs"
                          v-on="on"
                          :loading="issuingItem == item.id ? true : false"
                          :disabled="isIssuing"
                        >
                          領収書発行
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2"
                          >領収書宛名</v-card-title
                        >
                        <v-card-text>
                          <v-select
                            :items="[
                              { label: '会員氏名', id: 1 },
                              { label: '主たる事務所', id: 2 },
                            ]"
                            item-text="label"
                            item-value="id"
                            solo
                            outlined
                            hide-details
                            dense
                            background-color="white"
                            flat
                            class="py-2"
                            v-model="to"
                          ></v-select>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="cyan darken-2"
                            class="white--text"
                            @click="issue(index)"
                            :loading="issuingItem == item.id ? true : false"
                            :disabled="isIssuing || to == null"
                          >
                            発行する
                          </v-btn>
                          <v-btn
                            color="grey darken-3"
                            text
                            @click="item.dialog = false"
                          >
                            キャンセル
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </span>
                </td>
                <td>{{ item.tax_receipt_issued_at | dateFormat }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <div class="text-center py-5">
          <v-pagination
            v-model="page"
            :length="pageCount"
            @input="emitChangePage()"
            color="indigo"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Search from "../../mixins/Search.js";
import moment from "moment";
export default {
  mixins: [Search],
  data() {
    return {
      requestDateSearch: false,
      requestDateSearch2: false,
      checkedList: [],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        direction: "",
        sort: "",
        target_start: "",
        target_end: "",
        fee_status_cd: "",
      },
      //search用
      searchScheme: [
        "direction",
        "sort",
        "target_start",
        "target_end",
        "fee_status_cd",
      ],
      searchURL: "/api/fee_ledgers",
      headers: [
        {
          text: "",
          sortable: false,
          value: "check",
          width: "10%",
        },
        {
          text: "対象月",
          value: "target_year_month_start",
          sortable: false,
          width: "20%",
        },
        {
          text: "納付種別",
          value: "payment_type",
          sortable: false,
          width: "10%",
        },
        {
          text: "入金日",
          value: "deposited_at",
          sortable: false,
          width: "15%",
        },
        {
          text: "返金予定日",
          value: "repayment.expectes_repay_date",
          sortable: false,
          width: "15%",
        },
        {
          text: "",
          sortable: false,
          value: "button",
          width: "15%",
        },
        {
          text: "領収書発行日",
          value: "tax_receipt_issued_at",
          sortable: false,
          width: "15%",
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search_fee_statuses: [],
      statusesFlat: {},
      member_id: null,
      loading: false,
      isIssuing: false,
      issuingItem: null,
      feeCodesLabel: [],
      to: null,
      dialog: false,
      select: ["fee_status_cd"],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      (async () => {
        //先にマスターデータを取得しておく。
        await this.$axios
          .get("/api/masters/search_fee_statuses")
          .then((response) => {
            this.search_fee_statuses = response.data;
          });

        await this.$axios
          .get("/api/masters/display_fee_statuses")
          .then((response) => {
            for (const item of response.data) {
              this.statusesFlat[item.id] = item.label;
            }
          });

        await this.$axios
          .get("/api/mastersLabel/fee_codes")
          .then((response) => {
            this.feeCodesLabel = response.data;
          });

        this.list(false);
      })();
      this.moment = moment;
    },
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.direction = "desc";
      query.sort = "target_year_month";
      this.search(query);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value);
    },
    Search() {
      this.checkedList = [];
      let value = {};
      for (const key of this.searchScheme) {
        value[key] = this.searchForm[key];
      }
      this.searchForm = value;
      let query = this.getSearchBase();
      this.search(query);
    },
    issue(index = null) {
      this.isIssuing = true;
      this.errors = {};
      this.loading = true;

      let target_ids = new Array();

      // 「チェックした領収書の発行」ボタンクリックの時
      if (index == null) {
        /* if (!this.isMonthSerial()) {
          this.loading = false;
          this.isIssuing = false;
          this.issuingItem = null;

          this.$store.commit("setMessege", {
            text: "連続した月を選択してください",
            color: "warning",
          });
          return;
        } */

        if (this.checkedList.length > 12) {
          this.loading = false;
          this.isIssuing = false;
          this.issuingItem = null;

          this.$store.commit("setMessege", {
            text: "領収書の対象は12ヶ月以内にしてください",
            color: "warning",
          });
          return;
        }

        for (let index = 0; index < this.checkedList.length; index++) {
          var item = this.checkedList[index];
          target_ids.push(item.split("_")[1]);
        }
      } else {
        let FeeLedgerID = this.items[index].id;
        this.issuingItem = FeeLedgerID;
        target_ids.push(FeeLedgerID);
      }

      var fields = {
        target_ids: target_ids,
        to: this.to, // デフォルト
        delivery_to: 1, // デフォルト
      };

      this.$axios
        .post(`/api/receipts`, fields)
        .then((response) => {
          if (response.data.result == true) {
            let receipts_id = response.data.receipts_ids;
            this.$axios
              .get(`/api/doc/receipt/${receipts_id}`, { responseType: "blob" })
              .then((response) => {
                let blob = new Blob([response.data], { type: "application/*" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "領収書.pdf";
                link.click();
                this.$store.commit("setMessege", {
                  text: "領収書を発行しました。",
                  color: "success",
                });
                this.loading = false;
                this.isIssuing = false;
                this.issuingItem = null;
                this.Search();
              });
          }
        })
        .catch((error) => {
          this.checkedList = [];
          // バリデーションエラー
          if (error.response.status === 422 || error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            let errorMessage =
              "入力内容にエラーがあります。内容を確認してください。";
            // 同じ年月に2件以上の入金があった場合は重複入金なので、領収書を発行できないようにする
            if ("multiple_deposits" in this.errors) {
              errorMessage = this.errors.multiple_deposits;
            }
            this.$store.commit("setMessege", {
              text: errorMessage,
              color: "warning",
            });
            this.loading = false;
            this.isIssuing = false;
            this.issuingItem = null;
          }
        })
        .finally(() => {
          if (index == null) {
            this.dialog = false;
          } else {
            this.$set(this.items[index], "dialog", false);
          }
        });
    },
    isMonthSerial() {
      let isSerial = true;
      var prevMonth = "";
      var expectedNextMonth = "";

      this.checkedList.sort();
      for (let index = 0; index < this.checkedList.length; index++) {
        var item = this.checkedList[index];
        var checkedMonth = item.split("_")[0];

        // 初回と重複データはチェックしない
        if (
          index != 0 &&
          checkedMonth != prevMonth &&
          checkedMonth != expectedNextMonth
        ) {
          isSerial = false;
          break;
        }

        var tempDate = new Date(
          checkedMonth.toString().substr(0, 4),
          checkedMonth.toString().substr(4, 2) - 1,
          1
        );
        expectedNextMonth = moment(tempDate).add(1, "M").format("YYYYMM");
        prevMonth = checkedMonth;
      }

      return isSerial;
    },
  },

  filters: {
    /**
     * @param {Date} value    - Date オブジェクト
     * @param {string} format - 変換したいフォーマット
     */
    targetFormatStart(value) {
      if (value == null) return "";
      var firstDay = new Date(
        value.toString().substr(0, 4),
        value.toString().substr(4, 2) - 1,
        1
      );
      return moment(firstDay).format("YYYY年MM月");
    },

    targetFormatLast(value) {
      if (value == null) return "";
      var lastDay = new Date(
        value.toString().substr(0, 4),
        value.toString().substr(4, 2),
        0
      );
      return moment(lastDay).format("YYYY/MM/DD");
    },
    dateFormat(value) {
      if (value == null) return "";
      return moment(value).format("YYYY年MM月DD日");
    },
  },
};
</script>

<style scoped>
.v-data-table__wrapper table thead tr {
  background-color: #000 !important;
}
.v-data-table__wrapper table thead tr th:first-child {
  border-radius: 5px 0 0 0;
}
.v-data-table__wrapper table thead tr th:last-child {
  border-radius: 0 5px 0 0;
}
</style>
