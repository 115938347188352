var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition-group',{attrs:{"tag":"div"}},[_vm._l((_vm.offices),function(office,index){return [(office['delete_flag'] == false)?_c('v-card',{key:index,staticClass:"mb-5"},[_c('v-card-title',{staticClass:"px-4 py-2 blue-grey darken-1"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold white--text"},[_vm._v("事務所")])]),_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{class:{
                  active: _vm.changes.office_state_change_presence[index],
                }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4","disabled":office.office_id == null},model:{value:(_vm.changes.office_state_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.office_state_change_presence, index, $$v)},expression:"changes.office_state_change_presence[index]"}}),_c('span',[_vm._v("事務所状態")])],1)]),_c('td',[_c('div',{staticClass:"d-md-flex justify-space-between"},[_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(office.office_state_cd),callback:function ($$v) {_vm.$set(office, "office_state_cd", $$v)},expression:"office.office_state_cd"}},_vm._l((_vm.office_state_cd),function(cd){return _c('v-radio',{key:cd.id,attrs:{"label":cd.label,"value":cd.id,"color":"light-blue darken-4","disabled":!_vm.changes.office_state_change_presence[index]}})}),1)],1),(
                      _vm.errors &&
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.office_state_cd']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_state_cd" ][0])+" ")]):_vm._e()])]),_c('tr',{class:{ active: _vm.changes.name_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4","disabled":office.office_id == null},model:{value:(_vm.changes.name_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.name_change_presence, index, $$v)},expression:"changes.name_change_presence[index]"}}),_c('span',[_vm._v("フリガナ")])],1)]),_c('td',[_c('v-text-field',{staticClass:"mr-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","background-color":"white","color":"light-blue darken-4","disabled":!_vm.changes.name_change_presence[index]},model:{value:(office.office_kana),callback:function ($$v) {_vm.$set(office, "office_kana", $$v)},expression:"office.office_kana"}}),(
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.office_kana']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".office_kana"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.name_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-end align-center"},[_c('span',[_vm._v("事務所名")])])]),_c('td',[_c('v-text-field',{staticClass:"mr-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","value":"東京特許事務所","background-color":"white","color":"light-blue darken-4","disabled":!_vm.changes.name_change_presence[index]},model:{value:(office.offfice_kanji),callback:function ($$v) {_vm.$set(office, "offfice_kanji", $$v)},expression:"office.offfice_kanji"}}),(
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.offfice_kanji']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".offfice_kanji"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.adress_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4","disabled":office.office_id == null},model:{value:(_vm.changes.adress_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.adress_change_presence, index, $$v)},expression:"changes.adress_change_presence[index]"}}),_c('span',[_vm._v("住所")])],1)]),_c('td',{staticClass:"pb-2"},[_c('div',{staticClass:"d-md-flex justify-start py-2"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{attrs:{"label":"郵便番号(ハイフン無し)","outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"input":function($event){return _vm.getPrefecture(office, false)}},model:{value:(office.office_postalcode),callback:function ($$v) {_vm.$set(office, "office_postalcode", $$v)},expression:"office.office_postalcode"}}),(
                          _vm.errors &&
                          _vm.errors[
                            'belong_offices.' + index + '.office_postalcode'
                          ]
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_postalcode" ][0])+" ")]):_vm._e()],1),_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":!_vm.changes.adress_change_presence[index]},on:{"click":function($event){return _vm.getPrefecture(office, true)}}},[_vm._v("住所検索")]),_c('v-btn',{attrs:{"disabled":!_vm.changes.adress_change_presence[index]},on:{"click":function($event){return _vm.setKaigai(office)}}},[_vm._v("海外")])],1),_c('div',{staticClass:"d-md-flex justify-start align-center pb-2"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"label":"都道府県","items":_vm.office_prefecture,"item-text":"label","item-value":"value","outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"change":function($event){return _vm.getBranchCd(office)}},model:{value:(office.office_prefecture),callback:function ($$v) {_vm.$set(office, "office_prefecture", $$v)},expression:"office.office_prefecture"}}),(
                          _vm.errors &&
                          _vm.errors[
                            'belong_offices.' + index + '.office_prefecture'
                          ]
                        )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_prefecture" ][0])+" ")]):_vm._e()],1)]),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"住所","outlined":"","hide-details":"","color":"teal darken-4","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},on:{"blur":function($event){return _vm.getBranchCd(office)}},model:{value:(office.office_municipality),callback:function ($$v) {_vm.$set(office, "office_municipality", $$v)},expression:"office.office_municipality"}}),(
                      _vm.errors &&
                      _vm.errors[
                        'belong_offices.' + index + '.office_municipality'
                      ]
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_municipality" ][0])+" ")]):_vm._e(),_c('v-text-field',{attrs:{"label":"建物名以下","outlined":"","hide-details":"","color":"teal darken-4","background-color":"white","dense":"","disabled":!_vm.changes.adress_change_presence[index]},model:{value:(office.office_building),callback:function ($$v) {_vm.$set(office, "office_building", $$v)},expression:"office.office_building"}}),(
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.office_building']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".office_building" ][0])+" ")]):_vm._e()],1)])])]},proxy:true}],null,true)}),_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{class:{ active: _vm.changes.tel1_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.tel1_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel1_change_presence, index, $$v)},expression:"changes.tel1_change_presence[index]"}}),_c('span',[_vm._v("事務所TEL1")])],1)]),_c('td',[_c('v-text-field',{staticClass:"my-2",attrs:{"label":"000-0000-0000","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel1_change_presence[index],"color":"light-blue darken-4"},model:{value:(office.tel1),callback:function ($$v) {_vm.$set(office, "tel1", $$v)},expression:"office.tel1"}}),(
                      _vm.errors && _vm.errors['belong_offices.' + index + '.tel1']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel1"][0])+" ")]):_vm._e()],1),_c('td',[_c('v-select',{attrs:{"label":"回線区分","items":[{ label: '選択してください', id: '' } ].concat( _vm.line_division),"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.tel1_change_presence[index]},model:{value:(office.tel1_line_division),callback:function ($$v) {_vm.$set(office, "tel1_line_division", $$v)},expression:"office.tel1_line_division"}}),(
                      _vm.errors &&
                      _vm.errors[
                        'belong_offices.' + index + '.tel1_line_division'
                      ]
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".tel1_line_division" ][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{
                  active: _vm.changes.tel1_extension_change_presence[index],
                }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.tel1_extension_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel1_extension_change_presence, index, $$v)},expression:"changes.tel1_extension_change_presence[index]"}}),_c('span',[_vm._v("内線")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel1_extension_change_presence[index],"color":"light-blue darken-4"},model:{value:(office.tel1_extension),callback:function ($$v) {_vm.$set(office, "tel1_extension", $$v)},expression:"office.tel1_extension"}}),(
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.tel1_extension']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel1_extension"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.tel2_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.tel2_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel2_change_presence, index, $$v)},expression:"changes.tel2_change_presence[index]"}}),_c('span',[_vm._v("事務所TEL2")])],1)]),_c('td',[_c('v-text-field',{staticClass:"my-2",attrs:{"single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel2_change_presence[index],"color":"light-blue darken-4"},model:{value:(office.tel2),callback:function ($$v) {_vm.$set(office, "tel2", $$v)},expression:"office.tel2"}}),(
                      _vm.errors && _vm.errors['belong_offices.' + index + '.tel2']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel2"][0])+" ")]):_vm._e()],1),_c('td',[_c('v-select',{attrs:{"label":"回線区分","items":[{ label: '選択してください', id: '' } ].concat( _vm.line_division),"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.tel2_change_presence[index]},model:{value:(office.tel2_line_division),callback:function ($$v) {_vm.$set(office, "tel2_line_division", $$v)},expression:"office.tel2_line_division"}}),(
                      _vm.errors &&
                      _vm.errors[
                        'belong_offices.' + index + '.tel2_line_division'
                      ]
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".tel2_line_division" ][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{
                  active: _vm.changes.tel2_extension_change_presence[index],
                }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"150"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.tel2_extension_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.tel2_extension_change_presence, index, $$v)},expression:"changes.tel2_extension_change_presence[index]"}}),_c('span',[_vm._v("内線")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":"03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.tel2_extension_change_presence[index],"color":"light-blue darken-4"},model:{value:(office.tel2_extension),callback:function ($$v) {_vm.$set(office, "tel2_extension", $$v)},expression:"office.tel2_extension"}}),(
                      _vm.errors &&
                      _vm.errors['belong_offices.' + index + '.tel2_extension']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".tel2_extension"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.fax1_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"100"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.fax1_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.fax1_change_presence, index, $$v)},expression:"changes.fax1_change_presence[index]"}}),_c('span',[_vm._v("事務所FAX1")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":"03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.fax1_change_presence[index]},model:{value:(office.fax1),callback:function ($$v) {_vm.$set(office, "fax1", $$v)},expression:"office.fax1"}}),(
                      _vm.errors && _vm.errors['belong_offices.' + index + '.fax1']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".fax1"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{ active: _vm.changes.fax2_change_presence[index] }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"100"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.fax2_change_presence[index]),callback:function ($$v) {_vm.$set(_vm.changes.fax2_change_presence, index, $$v)},expression:"changes.fax2_change_presence[index]"}}),_c('span',[_vm._v("事務所FAX2")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-text-field',{staticClass:"my-2",attrs:{"value":"03-3456-7890","single-line":"","outlined":"","hide-details":"","dense":"","disabled":!_vm.changes.fax2_change_presence[index]},model:{value:(office.fax2),callback:function ($$v) {_vm.$set(office, "fax2", $$v)},expression:"office.fax2"}}),(
                      _vm.errors && _vm.errors['belong_offices.' + index + '.fax2']
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["belong_offices." + index + ".fax2"][0])+" ")]):_vm._e()],1)]),_c('tr',{class:{
                  active: _vm.changes.office_oparation_change_presence[index],
                }},[_c('th',{staticClass:"text-md-right px-3",staticStyle:{"vertical-align":"middle"},attrs:{"width":"100"}},[_c('div',{staticClass:"d-md-flex justify-space-between align-center"},[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4","disabled":office.office_id == null},model:{value:(
                        _vm.changes.office_oparation_change_presence[index]
                      ),callback:function ($$v) {_vm.$set(_vm.changes.office_oparation_change_presence, index, $$v)},expression:"\n                        changes.office_oparation_change_presence[index]\n                      "}}),_c('span',[_vm._v("就業形態")])],1)]),_c('td',{attrs:{"colspan":"2"}},[_c('v-select',{attrs:{"items":_vm.operation_status_cd,"item-text":"label","item-value":"id","outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","flat":"","dense":"","disabled":!_vm.changes.office_oparation_change_presence[index]},model:{value:(office.operation_status_cd),callback:function ($$v) {_vm.$set(office, "operation_status_cd", $$v)},expression:"office.operation_status_cd"}}),(
                      _vm.errors &&
                      _vm.errors[
                        'belong_offices.' + index + '.operation_status_cd'
                      ]
                    )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors[ "belong_offices." + index + ".operation_status_cd" ][0])+" ")]):_vm._e()],1)])])]},proxy:true}],null,true)}),_c('v-card-actions',{attrs:{"color":"grey"}},[_c('v-spacer'),(index > _vm.officesLength)?_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","depressed":"","small":"","color":"red darken-2"},on:{"click":function($event){$event.stopPropagation();return _vm.removeOffice(index)}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" この事務所を削除 ")],1):_vm._e()],1)],1):_vm._e()]})],2),_c('div',{staticClass:"text-center pb-8"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","depressed":"","outlined":"","color":"green darken-2"},on:{"click":_vm.addOffice}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-domain-plus")]),_vm._v(" 事務所を追加する ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }