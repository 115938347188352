<template>
  <v-container grid-list-md>
    <div class="my-4">
      <v-btn
        depressed
        color="grey darken-1"
        to="/corporate_change"
        exact
        outlined
        >戻る</v-btn
      >
    </div>
    <h2 class="text-center pa-2">申請書ダウンロード及び今後の流れ</h2>
    <div class="text-center my-9">
      <v-btn
        large
        color="green darken-1"
        class="font-weight-bold white--text"
        :disabled="buttonStatus"
        :loading="buttonStatus"
        @click="exportPdf()"
      >
        <v-icon right class="mr-2"> mdi-file-download </v-icon>
        申請書ダウンロード</v-btn
      >
    </div>
    <v-row justify="center my-6">
      <v-col md="8" class="py-6">
        <v-card>
          <v-card-title class="px-4 py-2 cyan darken-1">
            <h3 class="subtitle-1 font-weight-bold white--text">以下の宛先へ出力した申請書を送付してください。</h3>
          </v-card-title>
          <v-card-text class="pt-4">
            <p>
              〒100-0013<br>
              東京都千代田区霞が関３－２－６<br>
              東京倶楽部ビル１４階<br>
              日本弁理士会 会員課 宛
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      buttonStatus: false,
      errors: {},
    };
  },
  mounted() {
  },
  methods: {
    exportPdf() {
      this.buttonStatus = true;
      this.errors = {}
      this.$axios
        .get(`/api/corporation_change_request/doc/${this.$route.params.uuid}`, { responseType: 'blob' })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/*" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "弁理士法人の変更事項届出書.pdf";
          link.click();
          this.buttonStatus = false;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            const blob = new Blob([error.response.data]);
            blob.text().then(value => {
              const response = JSON.parse(value);
              this.errors = response.errors
              this.buttonStatus = false;
            })
          } else {
            this.buttonStatus = false;
          }
        })
    },
  }
};
</script>

