var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-9"},[_c('v-card-title',{staticClass:"px-4 py-2 blue-grey darken-1"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold white--text"},[_vm._v("基本情報")])]),_c('v-simple-table',{staticClass:"transparent my-5",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',{attrs:{"width":"100"}},[_vm._v("届出人")]),_c('td',{staticClass:"py-4"},[_c('div',{staticClass:"d-flex justify-content-start align-center"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","hide-details":"","dense":"","label":"登録番号","background-color":"white","color":"cyan darken-2"},on:{"change":_vm.changeRegistrationNumber},model:{value:(_vm.memberRegistrationNumber),callback:function ($$v) {_vm.memberRegistrationNumber=$$v},expression:"memberRegistrationNumber"}})],1),_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.memberName)+" "),(_vm.registrationNum!='')?_c('span',[_vm._v("( "+_vm._s(_vm.registrationNum)+" )")]):_vm._e()])]),(_vm.errors && _vm.errors['application_by'])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["application_by"][0])+" ")]):_vm._e()])]),_c('tr',[_c('th',{attrs:{"width":"100"}},[_vm._v("届出日")]),_c('td',{staticClass:"py-4"},[_c('div',{staticClass:"d-md-flex justify-content-start align-center"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"datepick",attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":"","color":"cyan darken-2","background-color":"white","clearable":""},on:{"click:prepend-inner":on.click,"click:clear":_vm.clearDate},model:{value:(_vm.fields.application_at),callback:function ($$v) {_vm.$set(_vm.fields, "application_at", $$v)},expression:"fields.application_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.requestDateSearch),callback:function ($$v) {_vm.requestDateSearch=$$v},expression:"requestDateSearch"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"cyan darken-2","day-format":function (date) { return new Date(date).getDate(); }},on:{"input":function($event){_vm.requestDateSearch = false},"change":_vm.calculateDifference},model:{value:(_vm.fields.application_at),callback:function ($$v) {_vm.$set(_vm.fields, "application_at", $$v)},expression:"fields.application_at"}})],1),(_vm.errors && _vm.errors['application_at'])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["application_at"][0])+" ")]):_vm._e()],1),_c('div',{staticClass:"ml-md-3"},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"my-2 my-md-0",attrs:{"small":"","retain-focus-on-click":"","text":"","color":"cyan darken-2"},on:{"click":on.click,"blur":on.blur}},[_c('v-icon',{attrs:{"dark":"","depressed":"","color":"black"}},[_vm._v(" mdi-chat-question-outline ")]),_c('span',{staticClass:"text-caption text-decoration-underline"},[_vm._v("詳細")])],1)]}}])},[_c('p',{staticClass:"mb-0"},[_vm._v(" 変更届年月日のtooltipテキスト"),_c('br'),_vm._v("変更届年月日のtooltipテキスト ")])])],1),_c('div',{staticClass:"grey--text text--darken-1 ml-md-5"},[_vm._v(" 例：2021/01/01 ")])])])]),_c('tr',[_c('th',{attrs:{"width":"100"}},[_vm._v("設立年月日")]),_c('td',{staticClass:"py-4"},[_c('div',{staticClass:"d-md-flex justify-content-start align-center"},[_c('div',{staticStyle:{"width":"200px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"datepick",attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":"","color":"cyan darken-2","background-color":"white","clearable":""},on:{"click:prepend-inner":on.click,"click:clear":_vm.clearDate},model:{value:(_vm.fields.established_at),callback:function ($$v) {_vm.$set(_vm.fields, "established_at", $$v)},expression:"fields.established_at"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.establishedDateSearch),callback:function ($$v) {_vm.establishedDateSearch=$$v},expression:"establishedDateSearch"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"cyan darken-2","day-format":function (date) { return new Date(date).getDate(); }},on:{"input":function($event){_vm.establishedDateSearch = false},"change":_vm.calculateDifference},model:{value:(_vm.fields.established_at),callback:function ($$v) {_vm.$set(_vm.fields, "established_at", $$v)},expression:"fields.established_at"}})],1),(_vm.errors && _vm.errors['established_at'])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors["established_at"][0])+" ")]):_vm._e()],1),_c('div',{staticClass:"grey--text text--darken-1 ml-md-5 mt-2 mt-md-0"},[_vm._v(" 例：2021/01/01 ")])])])]),(_vm.fields.has_delay_reason)?_c('tr',[_c('th',{attrs:{"width":"100"}},[_vm._v("遅延理由")]),_c('td',[_c('v-textarea',{attrs:{"outlined":"","hide-details":"","color":"cyan darken-2","rows":"4"},model:{value:(_vm.fields.delay_reason),callback:function ($$v) {_vm.$set(_vm.fields, "delay_reason", $$v)},expression:"fields.delay_reason"}}),(_vm.errors && _vm.errors.delay_reason)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.delay_reason[0])+" ")]):_vm._e()],1),_c('v-card-text',{staticClass:"mt-5 mb-0"},[_c('p',[_c('v-dialog',{attrs:{"width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" 記入例 ")])]}}],null,false,799688533),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" 記入例 ")]),_c('v-card-text',[_vm._v(" （例）○○法務局の事務処理上の都合により、登記簿謄本が作成されるに要する日数が（成立・変更の登記）より2週間を超えてしまったため"),_c('br'),_vm._v(" （例）年末年始の長期休業により、○○法務局の事務手続に要する日数が成立の日（または、変更の生じた年月日）より2週間を超えてしまったため"),_c('br'),_vm._v(" （ 例）従たる事務所の登記及び登記簿謄本の取り寄せ等、届出までの事務処理に、日数を要してしまったため ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 閉じる ")])],1)],1)],1)],1)])],1):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }