<template>
  <div>
    <v-card class="mb-5">
      <v-card-title class="px-4 py-2 blue-grey darken-2">
        <h3 class="subtitle-1 font-weight-bold white--text">
          定款記載事項 / 登記事項の変更
        </h3>
      </v-card-title>

      <v-card-text class="mt-5 mb-0">
        <p>
          定款の変更があった場合、条番号と変更前後の文言を記載してください。
          <v-dialog v-model="dialog" width="100%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="primary" v-bind="attrs" v-on="on">
                記入例
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                定款記載事項 / 登記事項の変更 記入例
              </v-card-title>

              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">変更前</th>
                        <th class="text-left">変更後</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          （事務所の所在地）<br />第４条
                          当法人は、主たる事務所を東京都千代田区に置く。
                        </td>
                        <td>
                          （事務所の所在地）<br />第４条
                          当法人は、主たる事務所を東京都千代田区に、従たる事務所を大阪府大阪市に置く。
                        </td>
                      </tr>

                      <tr>
                        <td>
                          （社員の氏名、住所及び出資）<br />
                          第５条
                          当法人の社員の氏名、住所及び出資の目的・金額及び評価の標準は、次のとおりである。<br />
                          １．東京都 区 町 番<br />
                          弁理士 太郎<br />
                          金額出資 10,000,000円<br />
                          東京都 区 町 番<br />
                          弁理士 次郎<br />
                          金額出資 10,000,000円<br />
                          ３．東京都 区 町 番<br />
                          弁理士 花子<br />
                          金額出資 10,000,000円
                        </td>
                        <td>
                          （社員の氏名、住所及び出資）<br />
                          第５条
                          当法人の社員の氏名、住所及び出資の目的・金額及び評価の標準は、次のとおりである。<br />
                          １．東京都 区 町 番<br />
                          弁理士 太郎<br />
                          金額出資 10,000,000円<br />
                          ２．東京都 区 町 番<br />
                          弁理士 次郎<br />
                          金額出資 10,000,000円<br />
                          ３．東京都 区 町 番<br />
                          弁理士 三郎<br />
                          金額出資 10,000,000円
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </p>
      </v-card-text>

      <transition-group tag="div">
        <v-card
          class="px-9 py-5"
          v-for="(registration, index) in registrations"
          :key="registration.id"
          elevation="0"
        >
          <v-row justify="center" class="mb-0 pb-0">
            <v-col md="6" cols="12">
              <v-textarea
                v-model="registration.before"
                outlined
                hide-details
                label="変更前"
                color="cyan darken-2"
              ></v-textarea>
            </v-col>
            <v-col md="6" cols="12">
              <v-textarea
                v-model="registration.new"
                outlined
                hide-details
                label="変更後"
                color="cyan darken-2"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-card-actions color="grey">
            <v-spacer></v-spacer>

            <v-btn
              dark
              depressed
              small
              color="red darken-2"
              @click="removeRegistration(index)"
            >
              <v-icon>mdi-trash-can</v-icon>
              この項目を削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </transition-group>
      <div class="text-center pb-4">
        <v-btn
          class="ma-2"
          dark
          depressed
          color="blue-grey darken-2"
          @click="addRegistration"
        >
          <v-icon class="mr-2">mdi-card-text-outline</v-icon>
          変更を追加する
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registrations: [
        {
          id: "",
          before: "",
          new: "",
        },
      ],
      dialog: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    changes: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    fields(val) {
      val.registrations = "";
      val.registrations = this.registrations;
    },
  },
  methods: {
    removeRegistration(index) {
      this.registrations.splice(index, 1);
      this.fields.registrations = "";
      this.fields.registrations = this.registrations;
    },
    addRegistration() {
      const title = this.registrations.length;
      this.$set(this.registrations, title, {
        id: title,
        before: null,
        new: null,
      });
      this.fields.registrations = "";
      this.fields.registrations = this.registrations;
    },
  },
};
</script>

<style type="scss" scoped>
.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
