<template>
  <v-app>
    <v-snackbar
      v-model="message.view"
      class="headerSnackbar"
      :color="message.color"
      :timeout="message.timeout"
      centered
      top
    >
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="message.view = false"
          id="closetab"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-app-bar clipped-left light app color="blue-grey lighten-5">
      <v-toolbar-title>
        <img src="../../assets/logo.svg" width="200" />
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container grid-list-md>
        <h2 class="pt-4 pb-2 text-center">弁理士登録申請書作成</h2>
        <v-row class="my-5" justify="center" align="center">
          <v-col lg="8">
            <v-card class="mb-8" color="white lighten-5">
              <v-card-title class="px-4 py-2 red darken-1">
                <h3 class="subtitle-1 font-weight-bold white--text">
                  申請前にお読みください
                </h3>
              </v-card-title>
              <v-card-text class="pt-4">
                <ul class="mb-2">
                  <li>
                    作成フォームは途中保存ができませんので、お時間の余裕があるときにご利用ください。
                  </li>
                  <li>数字・記号はすべて半角でご入力ください。</li>
                  <li>
                    作成フォームで「弁理士登録申請書」を作成しただけでは申請したことになりません。<br />
                    入力後、表示されるPDFファイル「弁理士登録申請書」を印刷して、必要書類とともに日本弁理士会会員課宛に提出してください。
                  </li>
                  <li>
                    申請書の申請人氏名は必ず申請人本人が自署してください。
                  </li>
                </ul>

                <p>
                  <strong>
                    【提出先】<br />
                    〒100-0013 東京都千代田区霞が関 3-2-6 東京倶楽部ビル14階<br />
                    日本弁理士会 会員課
                  </strong>
                </p>

                <h3>入力についてのお願い</h3>
                <v-alert
                  class="ma-2" 
                  dense
                  outlined
                  
                  type="success"
                >
                  会内における登録情報の管理の都合上、事務所名称及びフリガナは下記のように入力をお願いします。
                </v-alert>
                
               

                <ol>
                  <li>
                    フリガナ欄は、スペースを入れず、<span class="red--text"
                      >全角カタカナで</span
                    >続けて入力してください。<br />
                    [例]（フリガナ）コウオツトッキョジムショトラノモンシショ<br />
                    （事務所名）甲乙特許事務所 虎ノ門支所
                  </li>

                  <li>
                    「株式会社」、「弁理士法人」は、（株）、（業）と省略をしないで、入力してください。<br />
                    [例]<span class="red--text"
                      >（事務所名）ABC株式会社 知的財産部 特許課 → ○
                      よい例</span
                    ><br />
                    （事務所名）ABC（株）知的財産部 特許課 → X 悪い例
                  </li>

                  <li>
                    「株式会社」、「弁理士法人」の部分には、フリガナはふらないでください。それ以外の名称部分のみ<span
                      class="red--text"
                      >全角カタカナで</span
                    >フリガナをふってください。<br />
                    [例]（フリガナ） エービーシーチテキザイサンブトッキョカ
                    ※「カブシキガイシャ」は不要<br />
                    （事務所名） ABC株式会社 知的財産部 特許課<br />
                    [例]（フリガナ） イロハ ※「ベンリシホウジン」は不要<br />
                    （事務所名）弁理士法人いろは
                  </li>
                </ol>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="my-5" justify="center" align="center">
          <v-col lg="8">
            <DetailTable :fields="fields" :errors="errors" />
            <AddressTable :fields="fields" :errors="errors" />
            <SchoolTable :fields="fields" :errors="errors" />

            <v-card class="mb-8" color="cyan lighten-5">
              <v-simple-table class="transparent mt-5">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="pb-2">
                        <div class="d-md-flex justify-start align-center">
                          <div style="width: 200px">
                            <v-text-field
                              label="前登録番号"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              dense
                              v-model="fields.before_number"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.before_number"
                              class="text-danger"
                            >
                              {{ errors.before_number[0] }}
                            </div>
                          </div>
                          <div
                            class="
                              grey--text
                              text--darken-1
                              ml-md-5
                              mt-2 mt-md-0
                              text-caption
                            "
                          >
                            過去に弁理士登録の経験がある場合に、前登録番号を入力してください
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="pb-2">
                        <div class="d-md-flex justify-start align-center">
                          <div style="width: 200px">
                            <v-text-field
                              label="前々登録番号"
                              outlined
                              hide-details
                              color="cyan darken-2"
                              background-color="white"
                              dense
                              v-model="fields.beforehand_number"
                            ></v-text-field>
                            <div
                              v-if="errors && errors.beforehand_number"
                              class="text-danger"
                            >
                              {{ errors.beforehand_number[0] }}
                            </div>
                          </div>
                          <div
                            class="
                              grey--text
                              text--darken-1
                              ml-md-5
                              mt-2 mt-md-0
                              text-caption
                            "
                          >
                            過去に弁理士登録の経験が２度ある場合に、前登録番号を入力してください
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

            <MailTable :fields="fields" :errors="errors" />
            <OfficeTable
              :fields="fields"
              :errors="errors"
              @ShowAddressRange="ShowAddressRange"
            />
            <AddressRange
              :fields="fields"
              :errors="errors"
              v-if="address_range"
            />
            <PublicServant :fields="fields" :errors="errors" />
            <div class="text-center py-5">
              <v-btn
                class="ma-2"
                dark
                x-large
                color="cyan darken-1 font-weight-bold"
                :loading="buttonStatus"
                @click="submit"
              >
                申請書をダウンロードする
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import DetailTable from "./DetailTable";
import AddressTable from "./AddressTable";
import SchoolTable from "./SchoolTable";
import MailTable from "./MailTable";
import OfficeTable from "./OfficeTable";
import PublicServant from "./PublicServantTable";
import AddressRange from "./AddressRangeTable";

export default {
  components: {
    DetailTable,
    AddressTable,
    SchoolTable,
    MailTable,
    OfficeTable,
    PublicServant,
    AddressRange,
  },
  data() {
    return {
      fields: {
        office_entry_requests: {},
        adress_prefecture: "",
        adress_postalcode: null,
      },
      errors: {},
      buttonStatus: false,
      address_range: false,
    };
  },
  methods: {
    submit() {
      this.buttonStatus = true;
      this.errors = {};

      //全角英数字 => 半角処理 start
      for (let [fieldName, fieldValue] of Object.entries(this.fields)) {
        this.fields[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName)
        
      }

      for (let [index, office_entry_request] of Object.entries(this.fields.office_entry_requests)) {
        for (let [fieldName, fieldValue] of Object.entries(office_entry_request)) {
          office_entry_request[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName)
        }
      }
      //全角英数字 => 半角処理 end

      this.$axios
        .post("/api/application", this.fields)
        .then((response) => {
          if (response.data.result == true) {
            let personEntryRequest = response.data.personEntryRequests;
            let uuid = personEntryRequest["uuid"];
            let message = response.data.message;
            this.exportPdf(uuid, message);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            window.scrollTo(0, 0);
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
            this.buttonStatus = false;
          } else {
            this.buttonStatus = false;
          }
        });
    },
    exportPdf(uuid, message) {
      this.errors = {}
      this.$axios
        .get(`/api/doc/notification_book/${uuid}`, { responseType: 'blob' })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/*" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "弁理士登録申請書.pdf";
          link.click();
          this.buttonStatus = false;
          this.$store.commit('setMessege', {
            text: message,
            color: 'success',
          })
        })
        .catch((error) => {
          this.buttonStatus = false;
          if (error.response.status === 400) {
            const blob = new Blob([error.response.data]);
            blob.text().then(value => {
              const response = JSON.parse(value);
              this.errors = response.errors
              this.buttonStatus = false;
            })
          } else {
            this.buttonStatus = false;
          }
        })
    },
    ShowAddressRange(val) {
      this.address_range = val;
    }
  },
  computed: {
    message() {
      return this.$store.state.message;
    },
  },
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #e0f7fa !important;
}
.disabled {
  background-color: #eeeeee !important;
}
td.top {
  vertical-align: top !important;
}
</style>
