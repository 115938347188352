<template>
    <v-app>
        <v-container fluid class="grey lighten-4 login">
            <v-row justify="center" align="center">
                <v-col xs="10" sm="6" md="6" lg="4">
                    <v-card>
                        <v-toolbar color="cyan darken-1" flat>
                            <h4 class="white--text mx-auto">
                                パスワード変更
                            </h4>
                        </v-toolbar>
                        <v-divider> </v-divider>
                        <div class="pa-6">
                            <p>〇〇様でログイン中です。</p>
                            <v-text-field
                                v-model="currentPass"
                                :append-icon="
                                    passwordShow3 ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="passwordShow3 ? 'text' : 'password'"
                                outlined
                                label="現在のパスワード"
                                :error-messages="this.errors.current_password"
                                :error-count="
                                    this.errors.current_password
                                        ? this.errors.current_password.length
                                        : 0
                                "
                                @click:append="passwordShow3 = !passwordShow3"
                            ></v-text-field>

                            <v-divider class="mb-3"></v-divider>
                        </div>
                        <div class="pa-6 pt-0">
                            <v-text-field
                                v-model="newPass"
                                :append-icon="
                                    passwordShow ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="passwordShow ? 'text' : 'password'"
                                outlined
                                label="新しいパスワード"
                                :error-messages="this.errors.new_password"
                                :error-count="
                                    this.errors.new_password
                                        ? this.errors.new_password.length
                                        : 0
                                "
                                @click:append="passwordShow = !passwordShow"
                            ></v-text-field>

                            <v-text-field
                                v-model="newPassConfirm"
                                :append-icon="
                                    passwordShow2 ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="passwordShow2 ? 'text' : 'password'"
                                outlined
                                label="新しいパスワードの確認"
                                :error-messages="
                                    this.errors.new_password_confirmation
                                "
                                :error-count="
                                    this.errors.new_password_confirmation
                                        ? this.errors.new_password_confirmation
                                              .length
                                        : 0
                                "
                                @click:append="passwordShow2 = !passwordShow2"
                                class="mb-4"
                            ></v-text-field>
                            <div class="login-btn text-center">
                                <v-btn
                                    class="font-weight-bold"
                                    color="indigo darken-1"
                                    large
                                    dark
                                    @click="change"
                                    to="/change"
                                >
                                    変更する
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            currentPass: null,
            newPass: null,
            passwordShow: false,
            newPassConfirm: null,
            passwordShow2: false,
            passwordShow3: false,
            errors: {},
        };
    },
};
</script>

<style lang="scss">
.login_title {
    width: 100%;
}
.login {
    height: 100%;
    .row {
        height: 100%;
    }
}
</style>
