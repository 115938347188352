<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 blue-grey darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">基本情報</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th width="100">届出人</th>
            <td class="py-4">
              <div class="d-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    label="登録番号"
                    background-color="white"
                    color="cyan darken-2"
                    class="mr-2"
                    v-model="memberRegistrationNumber"
                    @change="changeRegistrationNumber"
                  ></v-text-field>
                </div>
                <div class="ml-3">
                  {{ memberName }} <span v-if="registrationNum!=''">( {{ registrationNum}} )</span> 
                </div>
              </div>
              <div
                v-if="errors && errors['application_by']"
                class="text-danger"
              >
                {{ errors["application_by"][0] }}
              </div>
            </td>
          </tr>
          <tr>
            <th width="100">届出日</th>
            <td class="py-4">
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-menu
                    v-model="requestDateSearch"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.application_at"
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="on.click"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        color="cyan darken-2"
                        background-color="white"
                        class="datepick"
                        clearable
                        @click:clear="clearDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.application_at"
                      no-title
                      color="cyan darken-2"
                      @input="requestDateSearch = false"
                      :day-format="(date) => new Date(date).getDate()"
                      @change="calculateDifference"
                    >
                    </v-date-picker>
                  </v-menu>
                  <div
                    v-if="errors && errors['application_at']"
                    class="text-danger"
                  >
                    {{ errors["application_at"][0] }}
                  </div>
                </div>
                <div class="ml-md-3">
                  <v-tooltip top :open-on-hover="false">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                        text
                        color="cyan darken-2"
                        class="my-2 my-md-0"
                      >
                        <v-icon dark depressed color="black">
                          mdi-chat-question-outline
                        </v-icon>
                        <span class="text-caption text-decoration-underline"
                          >詳細</span
                        >
                      </v-btn>
                    </template>
                    <p class="mb-0">
                      変更届年月日のtooltipテキスト<br />変更届年月日のtooltipテキスト
                    </p>
                  </v-tooltip>
                </div>
                <div class="grey--text text--darken-1 ml-md-5">
                  例：2021/01/01
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th width="100">設立年月日</th>
            <td class="py-4">
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-menu
                    v-model="establishedDateSearch"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.established_at"
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="on.click"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        color="cyan darken-2"
                        background-color="white"
                        class="datepick"
                        clearable
                        @click:clear="clearDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.established_at"
                      no-title
                      color="cyan darken-2"
                      @input="establishedDateSearch = false"
                      :day-format="(date) => new Date(date).getDate()"
                      @change="calculateDifference"
                    >
                    </v-date-picker>
                  </v-menu>
                  <div
                    v-if="errors && errors['established_at']"
                    class="text-danger"
                  >
                    {{ errors["established_at"][0] }}
                  </div>
                </div>
                <div class="grey--text text--darken-1 ml-md-5 mt-2 mt-md-0">
                  例：2021/01/01
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="fields.has_delay_reason">
            <th width="100">遅延理由</th>
            <td>
              <v-textarea
                outlined
                hide-details
                color="cyan darken-2"
                v-model="fields.delay_reason"
                rows="4"
              ></v-textarea>
              <div v-if="errors && errors.delay_reason" class="text-danger">
                {{ errors.delay_reason[0] }}
              </div>
            </td>
            <v-card-text class="mt-5 mb-0">
              <p>
                <v-dialog v-model="dialog" width="80%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text color="primary" v-bind="attrs" v-on="on">
                      記入例
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      記入例
                    </v-card-title>
                    <v-card-text>
                      （例）○○法務局の事務処理上の都合により、登記簿謄本が作成されるに要する日数が（成立・変更の登記）より2週間を超えてしまったため<br />

                      （例）年末年始の長期休業により、○○法務局の事務手続に要する日数が成立の日（または、変更の生じた年月日）より2週間を超えてしまったため<br />

                      （
                      例）従たる事務所の登記及び登記簿謄本の取り寄せ等、届出までの事務処理に、日数を要してしまったため
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        閉じる
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </p>
            </v-card-text>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      //デイトピッカー
      menu: false,
      requestDateSearch: false,
      establishedDateSearch: false,
      show: false,
      dialog: false,
      memberName: "",
      memberRegistrationNumber: "",
      registrationNum:"",
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    calculateDifference() {
      if (this.fields.application_at && this.fields.established_at) {
        var application_at = new Date(this.fields.application_at);
        var established_at = new Date(this.fields.established_at);
        if (application_at > established_at) {
          var time_diff = application_at.getTime() - established_at.getTime();
        } else {
          time_diff = established_at.getTime() - application_at.getTime();
        }
        var day_diff = time_diff / (1000 * 3600 * 24);
        if (day_diff >= 14) {
          this.fields.has_delay_reason = true;
        } else {
          this.fields.has_delay_reason = false;
        }
      } else {
        this.fields.has_delay_reason = false;
      }
    },
    clearDate() {
      this.fields.has_delay_reason = false;
    },
    changeRegistrationNumber(memberRegistrationNumber) {
      if (memberRegistrationNumber != "") {
        this.$axios
          .get("/api/member_by_number/" + memberRegistrationNumber)
          .then((response) => {
            if (response.data.exist == true) {
              this.memberName = response.data.name_kanji;
              this.registrationNum = response.data.registration_number;
              this.fields.application_by = response.data.id;
            } else {
              this.memberName = "";
              this.registrationNum = "";
              this.fields.application_by = "";
            }
          })
          .catch((error) => {
            this.memberName = "";
            this.fields.application_by = "";
          });
      } else {
        this.memberName = "";
        this.fields.application_by = "";
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
