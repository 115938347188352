var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',[_c('v-card',{staticClass:"my-9"},[_c('v-card-title',{staticClass:"px-4 py-2 blue-grey darken-1"},[_c('h3',{staticClass:"subtitle-1 font-weight-bold white--text"},[_vm._v(" 使用人である弁理士 ")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"mb-5",attrs:{"headers":_vm.headers,"items":_vm.employees,"hide-default-header":"","hide-default-footer":"","no-data-text":"データがありません","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.delete_flag == false)?_c('tr',{class:{
                      active: _vm.changes.check[index]
                  }},[_c('td',[_c('v-checkbox',{staticClass:"mt-0 mb-1",attrs:{"hide-details":"","color":"light-blue darken-4"},model:{value:(_vm.changes.check[index]),callback:function ($$v) {_vm.$set(_vm.changes.check, index, $$v)},expression:"changes.check[index]"}})],1),_c('td',[_c('div',{staticClass:"d-md-flex justify-start align-center"},[_c('div',[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","color":"light-blue darken-4","background-color":"white","dense":"","label":"登録番号","disabled":!_vm.changes.check[index]},on:{"change":function($event){return _vm.change_registration_number(
                              item.registration_number,
                              index
                              )}},model:{value:(item.registration_number),callback:function ($$v) {_vm.$set(item, "registration_number", $$v)},expression:"item.registration_number"}}),(_vm.errors && _vm.errors['employee.' + index +'.registration_number'])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors['employee.' + index +'.registration_number'][0])+" ")]):_vm._e()],1),_c('div',{staticStyle:{"width":"30%"}},[_c('div',{staticClass:"ma-2 my-md-0 ml-md-5"},[_vm._v(_vm._s(item.name_kanji))])]),_c('v-btn',{attrs:{"small":"","dark":"","color":"red darken-2"},on:{"click":function($event){return _vm.removeEmployee(index)}}},[_vm._v(" 削除 ")])],1)])]):_c('tr',{staticClass:"delete_text"},[_c('td'),_c('td',[_vm._v(_vm._s(item.registration_number))]),_c('td',[_vm._v(_vm._s(item.name_kanji))]),_c('td',[_c('span',[_vm._v("削除予定")])])])]}}])})],1)],1),_c('div',{staticClass:"text-center pb-4"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","depressed":"","outlined":"","color":"blue-grey darken-2"},on:{"click":_vm.addEmployee}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-multiple-plus")]),_vm._v(" 弁理士を追加する ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }