<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">弁理士法人の成立届出書作成</h2>

    <v-row class="my-5" justify="center" align="center">
      <v-col lg="8">
        <v-card color="cyan lighten-5">
          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th width="100">登録者</th>
                  <td>
                    <span class="text-h6 mr-5">{{
                      loginUser.registration_number
                    }}</span>
                    <span class="text-subtitle-1 font-weight-bold">{{
                      loginUser.name_kanji
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <DetailTable :fields="fields" :errors="errors" />
        <OfficeDetail :fields="fields" :errors="errors" />
        <MemberTable :fields="fields" :errors="errors" />
        <PatentTable :fields="fields" :errors="errors" />
        <div class="text-center py-5">
          <v-btn
            class="ma-2"
            dark
            x-large
            color="cyan darken-2 font-weight-bold"
            @click="submit"
          >
            登録する
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailTable from "./DetailTable";
import OfficeDetail from "./OfficeDetail";
import MemberTable from "./MemberTable";
import PatentTable from "./PatentTable";

export default {
  components: { DetailTable, OfficeDetail, MemberTable, PatentTable },
  data() {
    return {
      loginUser: {},
      memo: "",
      fields: {
        offices: [],
        patents: [],
        members: [],
        employees: [],
      },
      errors: {},
    };
  },
  mounted() {
    this.loginUser = this.$store.state.auth.authuser;
  },
  methods: {
    submit() {
      //全角英数字 => 半角処理 start
      for (let [fieldName, fieldValue] of Object.entries(this.fields)) {
          this.fields[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName)
      }

      for (let [index, office] of Object.entries(this.fields.offices)) {
        for (let [fieldName, fieldValue] of Object.entries(office)) {
            office[fieldName] = this.zenKakuToHanKaKu(fieldValue,fieldName)
        }
      }
      //全角英数字 => 半角処理 end

      this.errors = {}
      this.$axios
        .post(`/api/corporate/register`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.$store.commit('setMessege', {
              text: response.data.message,
              color: 'success',
            })
            let uuid = response.data.uuid;
            this.$router.push({ path: `/corporate/download/${uuid}` });
          }
        })
        .catch((error) => {
          // バリデーションエラー
          if (error.response.status === 422 || error.response.status === 400) {
            this.errors = error.response.data.errors || {}
            this.$store.commit('setMessege', {
              text: '入力内容にエラーがあります。内容を確認してください。',
              color: 'warning',
            })
          }
        })
    }
  }
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #e0f7fa !important;
}
td.top {
  vertical-align: top !important;
}
</style>
