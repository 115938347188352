<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">申請状況</h2>
    <v-row class="mt-5" justify="center" align="center">
      <v-col lg="8">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            no-data-text="データがありません"
            hide-default-footer
            disable-pagination
            :custom-sort="tableSort"
            :loading="isLoading"
            loading-text="ロード中"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-caption text-md-body-2">
                  {{ moment(item.application_at).format("YYYY-MM-DD") }}
                </td>
                <td>{{ categories[item.category] }}</td>
                <td>{{ statuses[item.status] }}</td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center py-5">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="emitChangePage()"
        color="cyan darken-2"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import Search from "../../mixins/Search.js";
import moment from "moment";
export default {
  mixins: [Search],
  data() {
    return {
      statuses: [],
      categories: [],
      //検索条件
      searchForm: {
        //検索のデフォルト値
        direction: "",
        sort: "",
      },
      //search用
      searchScheme: ["direction", "sort"],
      searchURL: "/api/applications",
      headers: [
        {
          text: "申請日",
          sortable: false,
          value: "application_at",
          width: "15%",
          class: "blue-grey darken-1 white--text",
        },
        {
          text: "申請種別",
          value: "category",
          sortable: false,
          width: "40%",
          class: "blue-grey darken-1 white--text",
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
          width: "20%",
          class: "blue-grey darken-1 white--text",
        },
        {
          text: "申請書確認",
          value: "confirm",
          sortable: false,
          width: "20%",
          class: "blue-grey darken-1 white--text",
        },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      (async () => {
        await this.$axios
          .get("/api/mastersLabel/application_statuses")
          .then((response) => {
            this.statuses = response.data;
          });
        await this.$axios
          .get("/api/mastersLabel/application_categories")
          .then((response) => {
            this.categories = response.data;
          });
        this.list(false);
      })();
      this.moment = moment;
    },
    list(scrollFlg) {
      let query = this.$route.query;
      query.scrollFlg = scrollFlg;
      query.direction = "asc";
      query.sort = "name";
      this.search(query);
    },
    emitChangePage() {
      let value = {
        page: this.page,
        pageCount: this.pageCount,
        itemsPerPage: this.itemsPerPage,
      };
      this.changePage(value);
    },
  },
};
</script>

<style type="scss">
.v-data-table-header {
  background-color: #393e46;
}
</style>
