<template>
  <div>
    <transition-group tag="div">
      <template v-for="(office, index) in offices">
        <v-card
          :key="index"
          class="mb-5"
          v-if="
            office['delete_flag'] == false && office['old_office_state_cd'] != 4
          "
        >
          <v-card-title class="px-4 py-2 green darken-2">
            <h3 class="subtitle-1 font-weight-bold white--text">所属事務所</h3>
          </v-card-title>

          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr
                  :class="{
                    active: changes.office_state_change_presence[index],
                  }"
                >
                  <th
                    width="150"
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.office_state_change_presence[index]"
                        hide-details
                        color="green darken-3"
                        class="mt-0 mb-1"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>事務所状態</span>
                    </div>
                  </th>
                  <td>
                    <div class="d-md-flex justify-start align-center" v-if="office.office_state_cd != 3">
                      <v-radio-group
                        row
                        dense
                        v-model="office.office_state_cd"
                        @change="changeOperation(office, index)"
                      >
                        <v-radio
                          v-for="cd in office_state_cd"
                          :key="cd.id"
                          :label="cd.label"
                          :value="cd.id"
                          color="green darken-3"
                          :disabled="
                            !changes.office_state_change_presence[index]
                          "
                        ></v-radio>
                      </v-radio-group>
                      </div>
                      <div class="d-md-flex justify-start align-center" v-else>
                        <v-radio-group
                          row
                          dense
                          v-model="office.office_state_cd"
                          @change="changeOperation(office, index)"
                        >
                          <v-radio
                            v-for="cd in office_state_cd_with_2"
                            :key="cd.id"
                            :label="cd.label"
                            :value="cd.id"
                            color="green darken-3"
                            :disabled="
                              !changes.office_state_change_presence[index]
                            "
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div>
                        <v-tooltip right :open-on-hover="false">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              @click="on.click"
                              @blur="on.blur"
                              retain-focus-on-click
                              text
                              color="cyan darken-2"
                            >
                              <v-icon dark depressed color="black">
                                mdi-chat-question-outline
                              </v-icon>
                              <span
                                class="
                                  text-caption
                                  ml-1
                                  text-decoration-underline
                                "
                                >主たる事務所とは</span
                              >
                            </v-btn>
                          </template>
                          <p class="mb-0">
                            登録する事務所のうち、１ヶ所のみ主たる事務所としてください。<br />
                            それ以外は従たる事務所となります。
                          </p>
                        </v-tooltip>
                      </div>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_state_cd']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_state_cd"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr v-if="office.office_state_cd == 4"  :class="{
                    active: changes.retirement_date_change_presence[index],
                  }">
                  <th
                    width="150"
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.retirement_date_change_presence[index]"
                        hide-details
                        color="green darken-3"
                        class="mt-0 mb-1"
                      ></v-checkbox>

                      <span>前勤務先退職日</span>
                    </div>
                  </th>
                  <td>        
                    <div style="width: 200px">           
                      <v-menu
                        v-model="office.retirementDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="office.retirement_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            solo
                            dense
                            flat
                            outlined
                            hide-details
                            color="teal darken-3"
                            background-color="white"
                            clearable
                            class="datepick"
                            :disabled="
                              !changes.retirement_date_change_presence[index]
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="office.retirement_date"
                          no-title
                          @input="office.retirementDateMenu=false"
                          :day-format="(date) => new Date(date).getDate()"
                          :max="fields.change_requested_at"
                        >
                        </v-date-picker>
                      </v-menu>
                    </div>
                      <div style="color: #0097A7" class="text-caption m1">
                        事務所・企業・団体等に勤務しており、退職・出向・資格変更（勤務から経営になった場合等）による変更が生じた場合は、その日付を入力してください。
＊経営していた事務所の廃止や、同じ組織内での変更（部署異動等）の場合には入力不要です。
                      </div>
                      <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.retirement_date']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".retirement_date"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.name_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3 bg-yellow lighten-5 bbnone"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.name_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>フリガナ</span>
                    </div>
                  </th>
                  <td class="bbnone">
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      value="トウキョウトッキョジムショ"
                      background-color="white"
                      color="green darken-3"
                      class="mr-2"
                      v-model="office.office_kana"
                      :disabled="!changes.name_change_presence[index]"
                    ></v-text-field>
                    <div
                      class="text-danger"
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_kana']
                      "
                    >
                      {{
                        errors["belong_offices." + index + ".office_kana"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.name_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-end align-center">
                      <span>事務所名</span>
                    </div>
                  </th>
                  <td class="py-2">
                    <v-text-field
                      single-line
                      outlined
                      hide-details
                      dense
                      value="東京特許事務所"
                      background-color="white"
                      class="mr-2"
                      color="green darken-3"
                      v-model="office.offfice_kanji"
                      :disabled="!changes.name_change_presence[index]"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.offfice_kanji']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".offfice_kanji"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.adress_change_presence[index] }">
                  <th
                    width="150"
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.adress_change_presence[index]"
                        hide-details
                        color="green darken-3"
                        class="mt-0 mb-1"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>事務所所在地</span>
                    </div>
                  </th>
                  <td class="pb-2">
                    <div class="d-md-flex justify-start py-2">
                      <div style="width: 200px">
                        <v-text-field
                          label="郵便番号(ハイフン無し)"
                          outlined
                          hide-details
                          color="green darken-3"
                          background-color="white"
                          class="mb-2 mb-md-0"
                          dense
                          v-model="office.office_postalcode"
                          :disabled="!changes.adress_change_presence[index]"
                          @input="getPrefecture(office, false)"
                        ></v-text-field>
                      </div>

                      <v-btn
                        class="mx-2"
                        :disabled="!changes.adress_change_presence[index]"
                        @click="getPrefecture(office, true)"
                        >住所検索
                      </v-btn>

                      <v-btn
                        :disabled="!changes.adress_change_presence[index]"
                        @click="setKaigai(office)"
                        >海外</v-btn
                      >
                    </div>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_postalcode']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_postalcode"
                        ][0]
                      }}
                    </div>
                    <div
                      class="
                        d-md-flex
                        justify-start
                        align-center
                        pb-2
                        mt-4 mt-md-0
                      "
                    >
                      <div style="width: 200px">
                        <v-select
                          label="都道府県"
                          :items="office_prefecture"
                          item-text="label"
                          item-value="value"
                          outlined
                          hide-details
                          color="green darken-3"
                          background-color="white"
                          flat
                          dense
                          v-model="office.office_prefecture"
                          :disabled="!changes.adress_change_presence[index]"
                          @change="getBranchCd(office)"
                        ></v-select>
                        <div
                          v-if="
                            errors &&
                            errors[
                              'belong_offices.' + index + '.office_prefecture'
                            ]
                          "
                          class="text-danger"
                        >
                          {{
                            errors[
                              "belong_offices." + index + ".office_prefecture"
                            ][0]
                          }}
                        </div>
                      </div>

                      <div class="ml-md-2 my-2 my-md-0">
                        <v-tooltip right :open-on-hover="false">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              @click="on.click"
                              @blur="on.blur"
                              retain-focus-on-click
                              text
                              color="cyan darken-2"
                            >
                              <v-icon dark depressed color="black">
                                mdi-chat-question-outline
                              </v-icon>
                              <span
                                class="
                                  text-caption
                                  ml-1
                                  text-decoration-underline
                                "
                                >海外に事務所をお持ちの方</span
                              >
                            </v-btn>
                          </template>
                          <p class="mb-0">
                            海外ボタンを押下し「住所」欄に住所及び郵便番号すべてを入力して下さい。<br />
                            例：3-4-2, Kasumigaseki, Chiyoda-ku, Tokyo 100-0013
                            Japan<br />
                            なお、郵便番号欄は一律、「000-0000」と表示されます。
                          </p>
                        </v-tooltip>
                      </div>
                    </div>
                    
                    <div class="grey--text text--darken-1 text-caption">
                    英数字、ハイフン等の記号は半角で入力して下さい
                    </div>

                    <v-text-field
                      label="住所"
                      outlined
                      hide-details
                      color="teal darken-3"
                      class="mb-2"
                      background-color="white"
                      dense
                      v-model="office.office_municipality"
                      :disabled="!changes.adress_change_presence[index]"
                      @blur="getBranchCd(office)"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.office_municipality'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_municipality"
                        ][0]
                      }}
                    </div>

                    <v-text-field
                      label="建物名以下"
                      outlined
                      hide-details
                      color="teal darken-3"
                      background-color="white"
                      dense
                      v-model="office.office_building"
                      :disabled="!changes.adress_change_presence[index]"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.office_building']
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".office_building"
                        ][0]
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr :class="{ active: changes.tel1_change_presence[index] }">
                  <th
                    width="110"
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel1_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>事務所TEL1</span>
                    </div>
                  </th>
                  <td>
                    <div class="d-md-flex justify-start align-center">
                      <v-text-field
                        placeholder="例：03-3456-7890"
                        single-line
                        outlined
                        hide-details
                        dense
                        v-model="office.tel1"
                        :disabled="!changes.tel1_change_presence[index]"
                        class="my-2 mr-md-4"
                        background-color="white"
                        color="green darken-3"
                      ></v-text-field>
                      <div
                        v-if="
                          errors && errors['belong_offices.' + index + '.tel1']
                        "
                        class="text-danger"
                      >
                        {{ errors["belong_offices." + index + ".tel1"][0] }}
                      </div>
                      <v-select
                        label="回線区分"
                        :items="[{ label: '選択してください', id: '' }, ...line_division]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        v-model="office.tel1_line_division"
                        :disabled="!changes.tel1_change_presence[index]"
                      ></v-select>
                      <div
                        v-if="
                          errors &&
                          errors[
                            'belong_offices.' + index + '.tel1_line_division'
                          ]
                        "
                        class="text-danger"
                      >
                        {{
                          errors[
                            "belong_offices." + index + ".tel1_line_division"
                          ][0]
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.tel1_extension_change_presence[index],
                  }"
                >
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel1_extension_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>内線</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      value="03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel1_extension"
                      :disabled="!changes.tel1_extension_change_presence[index]"
                      class="my-2"
                      background-color="white"
                      color="green darken-3"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.tel1_extension']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".tel1_extension"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.tel2_change_presence[index] }">
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel2_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>事務所TEL2</span>
                    </div>
                  </th>
                  <td>
                    <div class="d-md-flex justify-start align-center">
                      <v-text-field
                        placeholder="例：03-3456-7890"
                        single-line
                        outlined
                        hide-details
                        dense
                        v-model="office.tel2"
                        :disabled="!changes.tel2_change_presence[index]"
                        class="my-2 mr-md-4"
                        background-color="white"
                        color="green darken-3"
                      ></v-text-field>
                      <div
                        v-if="
                          errors && errors['belong_offices.' + index + '.tel2']
                        "
                        class="text-danger"
                      >
                        {{ errors["belong_offices." + index + ".tel2"][0] }}
                      </div>
                      <v-select
                        label="回線区分"
                        :items="[{ label: '選択してください', id: '' }, ...line_division]"
                        item-text="label"
                        item-value="id"
                        outlined
                        hide-details
                        color="green darken-3"
                        background-color="white"
                        flat
                        dense
                        v-model="office.tel2_line_division"
                        :disabled="!changes.tel2_change_presence[index]"
                      ></v-select>
                      <div
                        v-if="
                          errors &&
                          errors[
                            'belong_offices.' + index + '.tel2_line_division'
                          ]
                        "
                        class="text-danger"
                      >
                        {{
                          errors[
                            "belong_offices." + index + ".tel2_line_division"
                          ][0]
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.tel2_extension_change_presence[index],
                  }"
                >
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.tel2_extension_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>内線</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      value="03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.tel2_extension"
                      :disabled="!changes.tel2_extension_change_presence[index]"
                      class="my-2"
                      background-color="white"
                      color="green darken-3"
                    ></v-text-field>
                    <div
                      v-if="
                        errors &&
                        errors['belong_offices.' + index + '.tel2_extension']
                      "
                      class="text-danger"
                    >
                      {{
                        errors["belong_offices." + index + ".tel2_extension"][0]
                      }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.fax1_change_presence[index] }">
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.fax1_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>事務所FAX1</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      placeholder="例：03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.fax1"
                      :disabled="!changes.fax1_change_presence[index]"
                      background-color="white"
                      color="green darken-3"
                      class="my-2"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.fax1']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".fax1"][0] }}
                    </div>
                  </td>
                </tr>
                <tr :class="{ active: changes.fax2_change_presence[index] }">
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="changes.fax2_change_presence[index]"
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                      ></v-checkbox>

                      <span>事務所FAX2</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-text-field
                      placeholder="例：03-3456-7890"
                      single-line
                      outlined
                      hide-details
                      dense
                      v-model="office.fax2"
                      :disabled="!changes.fax2_change_presence[index]"
                      class="my-2"
                      background-color="white"
                      color="green darken-3"
                    ></v-text-field>
                    <div
                      v-if="
                        errors && errors['belong_offices.' + index + '.fax2']
                      "
                      class="text-danger"
                    >
                      {{ errors["belong_offices." + index + ".fax2"][0] }}
                    </div>
                  </td>
                </tr>
                <tr
                  :class="{
                    active: changes.office_oparation_change_presence[index],
                  }"
                >
                  <th
                    class="text-md-right px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="
                          changes.office_oparation_change_presence[index]
                        "
                        hide-details
                        class="mt-0 mb-1"
                        color="green darken-3"
                        :disabled="office.office_id == null"
                      ></v-checkbox>

                      <span>就業形態</span>
                    </div>
                  </th>
                  <td colspan="2">
                    <v-select
                      :items="operation_status_cd"
                      v-if="office['office_state_cd'] == 1"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="green darken-3"
                      background-color="white"
                      flat
                      dense
                      v-model="office.operation_status_cd"
                      :disabled="
                        !changes.office_oparation_change_presence[index]
                      "
                      @change="changeAddressRange(office, index)"
                    ></v-select>
                    <v-select
                      v-else
                      :items="[
                        { label: '選択してください', id: '' },
                        ...reduce_operation_cd,
                      ]"
                      item-text="label"
                      item-value="id"
                      outlined
                      hide-details
                      color="green darken-3"
                      background-color="white"
                      flat
                      dense
                      class="caption"
                      :disabled="
                        !changes.office_oparation_change_presence[index]
                      "
                      v-model="office['operation_status_cd']"
                      @change="changeAddressRange(office, index)"
                    ></v-select>
                    <div
                      v-if="
                        errors &&
                        errors[
                          'belong_offices.' + index + '.operation_status_cd'
                        ]
                      "
                      class="text-danger"
                    >
                      {{
                        errors[
                          "belong_offices." + index + ".operation_status_cd"
                        ][0]
                      }}
                    </div>
                    <div class="mt-2">
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="
                                text-caption
                                ml-1
                                text-decoration-underline
                              "
                              >勤務証明書が必要な就業形態</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          以下の就業形態を選択された場合には、勤務証明書の添付が必要です<br>
                        ・特許事務所勤務<br>
                        ・企業<br>
                        ・弁理士法人勤務<br>
                        ・弁護士法人・法律事務所勤務<br>
                        ・官公庁、非営利団体勤務<br>
                        ・海外法律事務所等勤務
                        </p>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table
            class="transparent my-5"
            v-if="
              office['office_state_cd'] == 1 &&
              office['operation_status_cd'] == 12
            "
          >
            <template v-slot:default>
              <tbody>
                <tr
                  :class="{
                    active: personChanges.address_range_change_presence,
                  }"
                >
                  <th
                    class="text-md-right text-left px-3 bg-yellow lighten-5"
                    style="vertical-align: middle"
                  >
                    <div class="d-md-flex justify-space-between align-center">
                      <v-checkbox
                        v-model="personChanges.address_range_change_presence"
                        hide-details
                        color="green darken-3"
                        class="mt-0 mb-1"
                      ></v-checkbox>
                      <span>住所公開範囲</span>
                    </div>
                  </th>
                  <td>
                    <v-checkbox
                      label="主たる事務所の住所公開範囲を市区町村までに留める"
                      color="teal darken-1"
                      v-model="fields.address_range"
                      :disabled="!personChanges.address_range_change_presence"
                    ></v-checkbox>
                    <div
                      v-if="errors && errors.address_range"
                      class="text-danger"
                    >
                      {{ errors.address_range[0] }}
                    </div>
                    <div class="mt-2">
                      <v-tooltip right :open-on-hover="false">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            @click="on.click"
                            @blur="on.blur"
                            retain-focus-on-click
                            text
                            color="cyan darken-2"
                          >
                            <v-icon dark depressed color="black">
                              mdi-chat-question-outline
                            </v-icon>
                            <span
                              class="
                                text-caption
                                ml-1
                                text-decoration-underline
                              "
                              >住所の公開範囲</span
                            >
                          </v-btn>
                        </template>
                        <p class="mb-0">
                          事業用ではなく専ら本会からの連絡を受ける用途の事務所を自宅に設置した場合にのみ選択できます。<br />選択すると、主たる事務所の就業形態が自動的に連絡先用事務所になります。
                        </p>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions color="grey">
            <v-spacer></v-spacer>

            <v-btn
              v-if="index > officesLength"
              class="ma-2"
              dark
              depressed
              small
              color="red darken-2"
              @click.stop="removeOffice(index)"
            >
              <v-icon>mdi-trash-can</v-icon>
              この事務所を削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </transition-group>
    <div class="text-center pb-4">
      <v-btn
        class="ma-2"
        dark
        depressed
        outlined
        color="green darken-2"
        @click="addOffice"
      >
        <v-icon class="mr-2">mdi-domain-plus</v-icon>
        事務所を追加する
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offices: [],
      officesLength: null,
      dateSearch:false,
      office_state_cd_with_2 : {},
      retirementDate:[],
      old_address_range: null,
      state1_office_id: null,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => { },
      required: true,
    },
    errors: {
      type: Object,
      default: () => { },
    },
    changes: {
      type: Object,
      default: () => { },
    },
    personChanges: {
      type: Object,
      default: () => { },
    },
  },
  mounted() {
    this.$store.commit("setOverlay", {
      overlay: true,
    });
    (async () => {
      await this.$axios.get("/api/masters/office_state_cd").then((response) => {
        this.office_state_cd = response.data;
        this.office_state_cd.splice(2, 1);
      });
      await this.$axios.get("/api/masters/office_state_cd").then((response) => {
        this.office_state_cd_with_2 = response.data;
      });
      await this.$axios
        .get("/api/masters/operation_status_cd")
        .then((response) => {
          this.operation_status_cd = response.data;

          let reduceOperationStatusCds = [];
          for (const elem of response.data) {
            if (elem.id != 12) {
              reduceOperationStatusCds.push(elem);
            }
          }
          this.reduce_operation_cd = reduceOperationStatusCds;
        });
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
      await this.$axios.get("/api/masters/line_division").then((response) => {
        this.line_division = response.data;
      });

      this.old_address_range = this.fields.address_range;
      if(this.fields.belong_offices){  
        this.offices = this.fields["belong_offices"];
        for (const [key, val] of Object.entries(this.offices)) {
          if(val.office_state_cd == 1){
            this.state1_office_id = val.id;
          }
          val.old_office_state_cd = val.office_state_cd;
        }
        this.officesLength = Object.keys(this.offices).length;
      }
      this.$store.commit("setOverlay", {
        overlay: false,
      });
    })();
  },
  methods: {
    getPrefecture(office, isClicked) {
      let _this = office;
      let that = this;
      _this.branch_cd = '';
      _this.district_cd = '';
      if(_this.office_postalcode.length == 7){
        if(_this.office_postalcode.substr(0, 3) == "000"){
          if(isClicked === true){
            _this.office_prefecture = "";
            _this.office_municipality = "";
            _this.office_building = "";
          }
          that.$axios
          .get(
            "/api/getBranchDistrictCd/" +
            _this.office_postalcode + "/false/false"
          )
          .then((response) => {
            if(response.data.branch_cd != ''){
              _this.branch_cd = response.data.branch_cd;
            }
            if(response.data.district_cd != ''){
              _this.district_cd = response.data.district_cd;
            }
          });
        }else {
          this.getAddress(_this.office_postalcode, function (addr) {
            if(isClicked === true){
              //住所検索ボタンを押した時にのみ住所を自動入力
              _this.office_prefecture = addr.prefecture;
              _this.office_municipality = addr.municipality;
              _this.office_building = ''; //住所検索ボタンを押した時は「建物名以下」は空欄にする
            }
            if (addr.region != "") {
              var postalcode = _this.adress_postalcode
                ? _this.adress_postalcode
                : 0;
              that.$axios
                .get(
                  "/api/getBranchDistrictCd/" +
                    postalcode +
                    "/" +
                    addr.region +
                    "/" +
                    addr.locality
                )
                .then((response) => {
                  if(response.data.branch_cd != ''){
                    _this.branch_cd = response.data.branch_cd;
                  }
                  if(response.data.district_cd != ''){
                    _this.district_cd = response.data.district_cd;
                  }
                });
            }
          });
        }
      }
    },
    getBranchCd(office){
      //郵便番号でのみ地域会などを特定する
      /*
      let _this = office;
      let that = this;
      let prefecture = _this.office_prefecture;
      if (prefecture == '海外' || prefecture == ''){
        _this.office_postalcode = '0000000';
      }else{
        var postalcode = (_this.office_postalcode)?_this.office_postalcode:0;
        var municipality = (_this.office_municipality)?_this.office_municipality:0;
        if(postalcode !== 0){
          this.getAddress(postalcode, function (addr) {
            municipality = addr.municipality;
            if(municipality != ''){
              municipality = '/' + municipality;
            }
            else{
              municipality = '/' + 'false';
            }
            that.$axios.get("/api/getBranchDistrictCd/"+postalcode +"/"+prefecture+municipality).then((response) => {
              if(response.data.branch_cd != ''){
                _this.branch_cd = response.data.branch_cd;
              }
              if(response.data.district_cd != ''){
                _this.district_cd = response.data.district_cd;
              }
            }).catch((error) => {
                _this.branch_cd = '';
                _this.district_cd = '';
            });
          });
        }
      }
      */
    },
    setKaigai(office) {
      let _this = office;
      _this.office_prefecture = null;
      _this.office_postalcode = "0000000";
      _this.branch_cd = '';
      _this.district_cd = '';
    },
    removeOffice(index) {
      if (window.confirm("事務所を削除してよろしいですか？")) {
        this.offices[index]["delete_flag"] = true;
        const newOffice = {};
        var i = 1;
        for (const [key, value] of Object.entries(this.offices)) {
          this.$set(newOffice, i, value);
          i++;
        }
        this.offices = newOffice;
      }
    },
    addOffice() {
      const title = Object.keys(this.offices).length + 1;
      this.$set(this.offices, title, {
        id: null,
        person_id: this.$route.params.id,
        office_state_cd: null,
        operation_status_cd: null,
        reduce_operation_cd: [],
        office_kana: "",
        offfice_kanji: "",
        office_postalcode: null,
        office_prefecture: null,
        office_municipality: null,
        office_building: null,
        tel1: null,
        tel1_line_division: null,
        tel1_extension: null,
        tel2: null,
        tel2_line_division: null,
        tel2_extension: null,
        fax1: null,
        fax2: null,
        delete_flag: false,
        retirementDateMenu:false,
        retirement_date:null,
      });
      this.changes.office_state_change_presence[title] = true;
      this.changes.name_change_presence[title] = true;
      this.changes.adress_change_presence[title] = true;
      this.changes.office_oparation_change_presence[title] = true;
    },
    retirementDateChange(office, index){
      let operationCds = [2, 4, 7, 9, 10, 11];
      if ((operationCds.includes(office.operation_status_cd)) && office.office_state_cd == 4) {
        this.$set(this.changes.retirement_date_change_presence, index, true);
      }
    },
    changeOperation(office, index) {
      if(office.office_state_cd == 4 && this.state1_office_id == office.id){
        if(this.old_address_range == 1){
          this.personChanges.address_range_change_presence = true;
        }else{
          this.personChanges.address_range_change_presence = false;
        }
        this.fields.address_range = false;
      }else{
      
        //チェックがtrueになったときにoffice_operationを12:連絡用に変更する
        if (this.fields.address_range == true && office.office_state_cd == 1) {
          this.changes.office_oparation_change_presence[index] = true;
          office.operation_status_cd = 12;
        }
        if(office.office_state_cd != 4){
          office.retirement_date = null;
        }
      }
      this.retirementDateChange(office, index);
    },
    changeAddressRange(office, index) {
     
      if(office.office_state_cd == 1) {
        if(this.old_address_range == 1){
          this.personChanges.address_range_change_presence = true;
        } else{
          this.personChanges.address_range_change_presence = false;
        }
        if(office.operation_status_cd != 12) {
          this.fields.address_range = false;
        }else{
          alert("連絡先用途事務所であっても、事務所名称の登録は必須です。\nなお事務所名称に関する規則及び事務所名称ガイドラインに抵触する名称では登録が出来ませんのでご注意ください。");
        }
      }

      this.retirementDateChange(office, index);
    },
  },
};
</script>

<style type="scss" scoped>
.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
