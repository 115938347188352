<template>
  <div>
    <transition>
      <v-card class="my-9">
        <v-card-title class="px-4 py-2 blue-grey darken-1">
          <h3 class="subtitle-1 font-weight-bold white--text">
            弁理士法人を構成する社員
          </h3>
        </v-card-title>

        <v-row justify="center">
          <v-col md="12">
            <v-simple-table class="transparent my-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>登録番号</th>
                    <th>氏名</th>
                    <th>雇用形態</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(member, index) in fields.members"
                    :key="member.registration_number"
                  >
                    <td class="font-weight-bold" width="100">
                      {{ member.registration_number }}
                    </td>
                    <td>{{ member.name }}</td>
                    <td>{{ employmentLabel[member.employment_status_cd] }}</td>
                    <td width="100">
                      <v-dialog
                        v-model="member.dialog"
                        persistent
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            dark
                            depressed
                            small
                            color="red darken-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            削除
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="text-h5"> </v-card-title>
                          <v-card-text>
                            {{ member.name }}を削除しますか？
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="grey darken-3"
                              text
                              @click="member.dialog = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              color="red darken-2"
                              dark
                              text
                              class="font-weight-bold"
                              @click="removeMember(index)"
                            >
                              削除する
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="px-9 pb-9">
          <v-col md="3" cols="12">
            <v-text-field
              outlined
              hide-details
              dense
              label="登録番号"
              background-color="white"
              color="cyan darken-2"
              class="mr-2"
              v-model="memberRegistrationNumber"
              @change="changeRegistrationNumber"
            ></v-text-field>
          </v-col>
          <v-col md="4" cols="12">
            {{ memberName }}
          </v-col>
          <v-col md="3">
            <v-select
              placeholder="種別"
              solo
              :items="[{ label: '選択してください', id: '' }, ...employment]"
              item-text="label"
              item-value="id"
              outlined
              hide-details
              color="cyan darken-2"
              background-color="white"
              flat
              dense
              v-model="memberEmpStatusCd"
            ></v-select>
          </v-col>
          <v-col md="2" cols="12">
            <v-btn
              color="blue-grey darken-2"
              depressed
              dark
              @click="addMember()"
            >
              追加する
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberRegistrationNumber: "",
      memberName: "",
      memberEmpStatusCd: "",
      employment: [],
      employmentLabel: {},
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get(`/api/masters/employment_2`).then((response) => {
        for (const item of response.data) {
          this.employmentLabel[item.id] = item.label;
        }
        let tempEmployment = response.data;
        tempEmployment.pop(); //使用人を削除
        this.employment = tempEmployment;
      });
    })();
  },
  methods: {
    removeMember(index) {
      this.fields.members.splice(index, 1);
      let allEmp = this.fields.members.concat(this.fields.patents);
      this.fields.employees = allEmp;
      this.dialog = false;
    },
    addMember: function () {
      const newMemberId = this.memberRegistrationNumber.trim();
      const newMemberName = this.memberName.trim();
      const newMemberEmpStatusCd = this.memberEmpStatusCd;
      if (!newMemberId || !newMemberName || !newMemberEmpStatusCd) {
        return;
      }
      const newMember = {
        registration_number: newMemberId,
        employment_status_cd: newMemberEmpStatusCd,
        name: newMemberName,
        dialog: false,
      };

      if (!this.isDuplicate(newMember)) {
        this.fields.members.push(newMember);
        let allEmp = this.fields.members.concat(this.fields.patents);
        this.fields.employees = allEmp;
        this.memberRegistrationNumber = "";
        this.memberName = "";
        this.memberEmpStatusCd = "";
      } else {
        this.$store.commit("setMessege", {
          text: "重複した社員が入力されています",
          color: "warning",
        });
      }
    },
    isDuplicate(newMember) {
      let tempEmployees = this.fields.employees.concat(newMember);
      const uniqueEmployees = new Set(
        tempEmployees.map((employee) => employee.registration_number)
      );
      if (uniqueEmployees.size < tempEmployees.length) {
        return true;
      }
    },
    changeRegistrationNumber(memberRegistrationNumber) {
      if (memberRegistrationNumber != "") {
        this.$axios
          .get("/api/member_by_number/" + memberRegistrationNumber)
          .then((response) => {
            if (response.data.exist == true) {
              this.memberName = response.data.name_kanji;
            } else {
              this.memberName = "";
            }
          })
          .catch((error) => {
            this.memberName = "";
          });
      } else {
        this.memberName = "";
      }
    },
  },
};
</script>

<style type="scss" scoped>
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-leave-active {
  /*    position: absolute; */
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
</style>
