<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">
        最終学歴 <span class="caption">※任意記載事項</span>
      </h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th width="30%" class="text-right">卒業修了年（西暦）</th>
            <td>
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-text-field
                    outlined
                    hide-details
                    dense
                    color="cyan darken-2"
                    background-color="white"
                    v-model="fields.graduation_year"
                  ></v-text-field>
                  <div
                    v-if="errors && errors.graduation_year"
                    class="text-danger"
                  >
                    {{ errors.graduation_year[0] }}
                  </div>
                </div>
                <div class="grey--text text--darken-1 ml-md-5 mt-2 mt-md-0">
                  例：2021
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right">学校名</th>
            <td>
              <v-text-field
                outlined
                hide-details
                dense
                placeholder="〇〇大学〇〇学部 卒業"
                color="cyan darken-2"
                background-color="white"
                v-model="fields.educational_background"
              ></v-text-field>
              <div
                v-if="errors && errors.educational_background"
                class="text-danger"
              >
                {{ errors.educational_background[0] }}
              </div>
              <div class="grey--text text--darken-1 mt-3 text-caption">
                <p>「卒業」「修了」まで記載してください</p>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
