<template>
  <v-app>
    <v-app-bar clipped-left light app color="blue-grey lighten-5">
      <v-toolbar-title>
        <img src="../../../assets/logo.svg" width="200" />
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container grid-list-md>
        <h2 class="pt-4 pb-2 text-center">弁理士登録申請書完了</h2>

        <v-row class="my-5" justify="center" align="center">
          <v-col lg="8">
            <div class="text-center py-5">
              <v-btn
                class="ma-2"
                dark
                x-large
                color="cyan darken-1 font-weight-bold"
                @click="exportPdf"
              >
                申請書をダウンロードする
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    exportPdf() {
      window.open(`/api/doc/notification_book/${this.$route.params.uuid}`, '_blank');
    },
  },
};
</script>

<style type="scss">
.datepick input[type="text"] {
  width: 100px;
}
.active {
  background-color: #e0f7fa !important;
}
.disabled {
  background-color: #eeeeee !important;
}
td.top {
  vertical-align: top !important;
}
</style>
