<template>
  <v-container grid-list-md class="mb-9 mb-md-0">
    <h1 class="pt-4 pb-2 text-center">MENU</h1>

    <v-row class="mt-5" justify="center">
      <v-col md="4">
        <v-card class="mx-auto pt-0" tile>
          <v-toolbar class="mt-0" color="#00ADB5" dark dense flat>
            <v-toolbar-title
              ><span class="subtitle-1 font-weight-bold"
                >各種申請</span
              ></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list color="blue-grey lighten-5" dense>
            <v-list-item-group color="primary">
              <template v-for="app_list in app_lists">
                <v-list-item :to="app_list.link" :key="app_list.name" exact>
                  <v-list-item-icon>
                    <v-icon>{{ app_list.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ app_list.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col md="4">
        <v-card class="mx-auto mb-4" tile>
          <v-toolbar class="mt-0" color="#00ADB5" dark dense flat>
            <v-toolbar-title
              ><span class="subtitle-1 font-weight-bold"
                >会費支払について</span
              ></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list color="blue-grey lighten-5" dense>
            <v-list-item-group color="primary">
              <template v-for="pay_list in pay_lists">
                <v-list-item :to="pay_list.link" :key="pay_list.name" exact>
                  <v-list-item-icon>
                    <v-icon>{{ pay_list.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ pay_list.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card class="mx-auto" tile v-if="false">
          <v-toolbar class="mt-0" color="#00ADB5" dark dense flat>
            <v-toolbar-title
              ><span class="subtitle-1 font-weight-bold"
                >弁理士法人</span
              ></v-toolbar-title
            >

            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list color="blue-grey lighten-5" dense>
            <v-list-item-group color="primary">
              <template v-for="comp_list in comp_lists">
                <v-list-item
                  v-if="!comp_list.lists"
                  :to="comp_list.link"
                  :key="comp_list.name"
                  exact
                >
                  <v-list-item-icon>
                    <v-icon>{{ comp_list.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ comp_list.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card class="mx-auto mt-5" tile>
          <v-list color="blue-grey lighten-5" dense>
            <v-list-item-group color="primary">
              <v-list-item to="/faq" exact>
                <v-list-item-icon>
                  <v-icon>mdi-comment-question-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    よくあるお問い合わせ
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>

        <v-card class="mx-auto mt-5" tile>
          <v-list color="blue-grey lighten-5" dense>
            <v-list-item-group color="primary">
              <v-list-item
                href="/doc/登録情報の確認・変更に関するマニュアル.pdf"
                target="_blank"
                exact
              >
                <v-list-item-icon>
                  <v-icon>mdi-comment-question-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    登録情報の確認・変更に関するマニュアル
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5" justify="center">
      <v-col cols="12" md="4">
        <v-card class="mx-auto mt-5" tile>
          <v-card-text>
            <strong>【送付／問合せ先】</strong><br />
            〒100-0013<br />
            東京都千代田区霞が関３－２－６<br />東京倶楽部ビル１４階<br />
            日本弁理士会 会員課 宛<br />
            Mail：touroku@jpaa.or.jp<br />
            Tel：03-3519-2716<br />
            ※ご送付前に、記載漏れ、捺印漏れがないかもう一度ご確認ください。
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="mx-auto mt-5" tile>
          <v-card-text>
            <strong>【領収書問い合わせ先】</strong><br />
            日本弁理士会 会計課<br />
            Mail：kaihi@jpaa.or.jp<br />
            Tel：03-3519-2702<br />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      app_lists: [
        //{
        //  name: "申請状況",
        //  icon: "mdi-clipboard-check-outline",
        //  link: "/applications",
        //},
        {
          name: "登録情報変更申請書作成",
          icon: "mdi-note-plus-outline",
          link: "/change",
        },
        {
          name: "メールアドレス変更",
          icon: "mdi-email-alert-outline",
          link: "/email",
        },
        {
          name: "各種申請書ダウンロード",
          icon: "mdi-file-download",
          link: "/download",
        },
      ],
      pay_lists: [
        {
          name: "領収書発行",
          icon: "mdi-receipt",
          link: "/fee_history",
        },
      ],
      comp_lists: [
        {
          name: "弁理士法人の成立届出書作成",
          icon: "mdi-office-building",
          link: "/corporate",
        },
        {
          name: "弁理士法人の変更事項届出書作成",
          icon: "mdi-office-building-cog-outline",
          link: "/corporate_change",
        },
      ],
    };
  },
};
</script>
