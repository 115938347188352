import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      state: {
        authuser: {},
        authrole: "0",
        loggedout : true
      },
      mutations: {
        setAuthUser(state, { authuser }) {
          //state.authuser = authuser;
          state.loggedout = false;
        },
        resetAuthUser(state) {
          state.authuser = {};
          state.loggedout = true;
        },
        updateLoginState(state,{loggedout}){
          state.authuser = {};
          state.loggedout = loggedout;
        }
      },
      namespaced: true,
    },
  },
  state: {
    //ヘッダ フラッシュメッセージ
    message: {
      text: "",
      color: "success",
      view: false,
      timeout: 5000,
    },
    overlay:false,
  },
  
  plugins: [
    createPersistedState({
      paths: ["auth"],
    }),
    createMutationsSharer({ predicate: ["auth/setAuthUser", "auth/resetAuthUser","auth/updateLoginState"] })
  ],
  mutations: {
    setMessege(state, { text, color }) {
      state.message.text = text;
      state.message.color = color;
      if (color == "error") {
        state.message.timeout = -1;
      }
      state.message.view = true;
    },
    resetMessage(state) {
      state.message.text = "";
      state.message.view = false;
    },
    setOverlay(state,{overlay}) {
      state.overlay = overlay;
    },
  },
});

