<template>
  <v-container grid-list-md>
    <div class="my-4">
      <v-btn depressed color="grey darken-1" to="/fee_history" exact outlined
        >戻る</v-btn
      >
    </div>
    <h2 class="pb-2 text-center mb-9">領収書発行</h2>

    <v-row justify="center" align="center">
      <v-col lg="10">
        <v-card color="cyan lighten-5">
          <v-card-title class="py-2 px-4 cyan darken-2">
            <div class="text-h6 font-weight-bold white--text">
              会費 2021年11月分
            </div>
          </v-card-title>
          <v-divider> </v-divider>

          <v-card-text>
            <v-row class="pa-6 pb-0" justify="center">
              <v-col cols="12" md="8">
                <v-card>
                  <v-card-text>
                    <ul class="mb-5">
                      <li>領収書をPDFでダウンロード可能です。</li>
                      <li>
                        一度ダウンロードするとダウンロード不可となります。<br />再発行の際は事務局へお問い合わせください。
                      </li>
                      <li>その他その他その他</li>
                      <li>その他その他その他</li>
                    </ul>
                    <p>その他テキストテキストテキストテキスト</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div class="text-center my-9">
              <v-btn large dark color="green darken-1" class="font-weight-bold">
                <v-icon right class="mr-2"> mdi-file-download </v-icon>
                ダウンロード</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
