<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">基本情報</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th width="120" class="text-right px-3">申請年月日</th>
            <td>
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-menu
                    ref="requestDateSearch"
                    v-model="requestDateSearch"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.entry_requested_at"
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="on.click"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        color="cyan darken-2"
                        background-color="white"
                        class="datepick"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.entry_requested_at"
                      :active-picker.sync="activePicker"
                      @input="requestDateSearch = false"
                      color="cyan darken-2"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>
                <div
                  v-if="errors && errors.entry_requested_at"
                  class="text-danger"
                >
                  {{ errors.entry_requested_at[0] }}
                </div>
                <div class="grey--text text--darken-1 ml-md-5 pt-2">
                  例：2021/01/01
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle">
              <span>フリガナ</span>
            </th>
            <td>
              <div class="d-md-flex justify-space-between">
                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  placeholder="トウキョウ"
                  background-color="white"
                  color="cyan darken-2"
                  class="mr-md-2 mb-2 mb-md-0"
                  v-model="fields.familyname_kana"
                ></v-text-field>
                <div
                  v-if="errors && errors.familyname_kana"
                  class="text-danger"
                >
                  {{ errors.familyname_kana[0] }}
                </div>

                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  placeholder="タロウ"
                  background-color="white"
                  color="cyan darken-2"
                  class="ml-md-2"
                  v-model="fields.firstname_kana"
                ></v-text-field>
                <div v-if="errors && errors.firstname_kana" class="text-danger">
                  {{ errors.firstname_kana[0] }}
                </div>
              </div>
              <div class="grey--text text--darken-1 mt-2 text-caption">
                姓と名に分けて全角で入力してください 例:ベンリシ タロウ
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle">
              <span>氏名</span>
            </th>
            <td>
              <div class="d-md-flex justify-space-between">
                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  placeholder="東京"
                  background-color="white"
                  color="cyan darken-2"
                  class="mr-md-2 mb-2 mb-md-0"
                  v-model="fields.familyname_kanji"
                ></v-text-field>
                <div
                  v-if="errors && errors.familyname_kanji"
                  class="text-danger"
                >
                  {{ errors.familyname_kanji[0] }}
                </div>

                <v-text-field
                  single-line
                  outlined
                  hide-details
                  dense
                  placeholder="太郎"
                  background-color="white"
                  color="cyan darken-2"
                  class="ml-md-2"
                  v-model="fields.firstname_kanji"
                ></v-text-field>
                <div
                  v-if="errors && errors.firstname_kanji"
                  class="text-danger"
                >
                  {{ errors.firstname_kanji[0] }}
                </div>
              </div>
              <div class="grey--text text--darken-1 text-caption mt-2">
                姓と名に分けて全角で入力してください 例:弁理士 太郎
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle"></th>
            <td>
              <v-tooltip top :open-on-hover="false">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    @click="on.click"
                    @blur="on.blur"
                    retain-focus-on-click
                    text
                    color="cyan darken-2"
                  >
                    <v-icon dark depressed color="black">
                      mdi-chat-question-outline
                    </v-icon>
                    <span class="text-caption ml-1 text-decoration-underline"
                      >氏名に旧字・異字が含まれる方</span
                    >
                  </v-btn>
                </template>
                <p class="mb-0">
                  氏名に旧字や異字が含まれる方は、「氏名」欄に新字で入力して下さい。<br />
                  また、「旧字・異字使用」にチェックを入れて下さい。
                </p>
              </v-tooltip>
              <v-checkbox
                v-model="fields.is_oldfont"
                label="旧字・異字使用"
                color="cyan darken-2"
              ></v-checkbox>
              <div v-if="errors && errors.is_oldfont" class="text-danger">
                {{ errors.is_oldfont[0] }}
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle">
              <span>性別</span>
            </th>
            <td>
              <div class="d-md-flex justify-start align-center">
                <v-radio-group row dense v-model="fields.sex_cd">
                  <v-radio
                    v-for="s in sex"
                    :key="s.id"
                    :label="s.label"
                    :value="s.id"
                    color="cyan darken-2"
                  ></v-radio>
                </v-radio-group>
                <div v-if="errors && errors.sex_cd" class="text-danger">
                  {{ errors.sex_cd[0] }}
                </div>

                <div class="grey--text text--darken-1 text-caption ml-md-5">
                  選択してください
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3">生年月日</th>
            <td>
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.birth_date"
                        prepend-icon="mdi-calendar"
                        @click:prepend="on.click"
                        readonly
                        background-color="white"
                        color="cyan darken-2"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        class="mb-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.birth_date"
                      :picker-date="birthDayPickerDate"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1920-01-01"
                      @change="save"
                      color="cyan darken-2"
                    ></v-date-picker>
                  </v-menu>
                  <div v-if="errors && errors.birth_date" class="text-danger">
                    {{ errors.birth_date[0] }}
                  </div>
                </div>
                <div class="grey--text text--darken-1 ml-5">例：1970/01/01</div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle">
              <span>弁理士となる資格</span>
            </th>
            <td>
              <v-row>
                <v-col md="4">
                  <v-radio-group dense v-model="fields.qualification_cd" class="qualificationCdRadioGroup">
                    <v-radio
                      v-for="quali in qualification"
                      :key="quali.id"
                      :label="quali.label"
                      :value="quali.id"
                      color="cyan darken-2"
                    >
                    </v-radio>
                  </v-radio-group>
                  <div
                    v-if="errors && errors.qualification_cd"
                    class="text-danger"
                  >
                    {{ errors.qualification_cd[0] }}
                  </div>
                </v-col>
                <v-col md="8" class="pt-6">
                  <v-text-field
                    v-model="fields.patent_attorney_exam_passed_at"
                    label="資格合格年"
                    outlined
                    hide-details
                    color="cyan darken-2"
                    background-color="white"
                    dense
                    :disabled="!(fields.qualification_cd === 1)"
                    style="width: 200px"
                    class="mb-4"
                  ></v-text-field>
                  <div
                    v-if="errors && errors.patent_attorney_exam_passed_at"
                    class="text-danger"
                  >
                    {{ errors.patent_attorney_exam_passed_at[0] }}
                  </div>
                  <div class="grey--text text--darken-1 text-caption">
                    弁理士となる資格が「弁理士試験合格」の方のみ<br
                      class="d-none d-md-block"
                    />弁理士試験合格年（弁理士試験合格証に記載の年）を<br
                      class="d-none d-md-block"
                    />西暦4桁の数字で入力して下さい 例：2021
                  </div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <th class="text-right px-3" style="vertical-align: middle">
              <span>実務修習修了の必要の有無</span>
            </th>
            <td>
              <v-row>
                <v-col md="4">
                  <v-radio-group
                    row
                    dense
                    v-model="fields.practical_training_completed_presence"
                  >
                    <v-radio
                      v-for="pre in presence"
                      :key="pre.id"
                      :label="pre.label"
                      :value="pre.id"
                      color="cyan darken-2"
                    ></v-radio>
                  </v-radio-group>
                  <div
                    v-if="
                      errors && errors.practical_training_completed_presence
                    "
                    class="text-danger"
                  >
                    {{ errors.practical_training_completed_presence[0] }}
                  </div>
                </v-col>
                <v-col md="8" class="pt-6"> </v-col>
              </v-row>
              <v-tooltip right :open-on-hover="false">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    @click="on.click"
                    @blur="on.blur"
                    retain-focus-on-click
                    text
                    color="cyan darken-2"
                  >
                    <v-icon dark depressed color="black">
                      mdi-chat-question-outline
                    </v-icon>
                    <span class="text-caption ml-1 text-decoration-underline"
                      >実務修習修了の有無について</span
                    >
                  </v-btn>
                </template>
                <p class="mb-0">
                  平成20年9月30日までに「弁理士となる資格を有する者」に該当した方は<br />
                  「なし」を選択してください。それ以外は「あり」を選択してください。
                </p>
              </v-tooltip>
            </td>
          </tr>
          <tr
            :class="{
              disabled: !(fields.practical_training_completed_presence === 1),
            }"
          >
            <th class="text-right px-3" style="vertical-align: middle">
              <span>実務修習修了年月日</span>
            </th>
            <td>
              <div style="width: 200px">
                <v-menu
                  ref="practiceDateSearch"
                  v-model="practiceDateSearch"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="
                        fields.practical_training_completed_presence_date
                      "
                      prepend-inner-icon="mdi-calendar"
                      @click:prepend-inner="on.click"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      hide-details
                      dense
                      color="cyan darken-2"
                      background-color="white"
                      class="datepick"
                      :disabled="
                        !(fields.practical_training_completed_presence === 1)
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fields.practical_training_completed_presence_date"
                    :active-picker.sync="activePicker"
                    :picker-date="trainingPickerDate"
                    color="cyan darken-2"
                    @input="practiceDateSearch = false"
                    :day-format="(date) => new Date(date).getDate()"
                    :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    min="1920-01-01"
                  >
                  </v-date-picker>

                </v-menu>
              </div>
              <div
                v-if="
                  errors && errors.practical_training_completed_presence_date
                "
                class="text-danger"
              >
                {{ errors.practical_training_completed_presence_date[0] }}
              </div>
              <div class="grey--text text--darken-1 mt-3 text-caption">
                実務修習修了の必要の有無が「あり」の方のみ<br />実務修習修了年月日（実務修習修了証書に記載の年月日）を入力してください
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      requestDateSearch: false,
      practiceDateSearch: false,
      activePicker: null,
      menu: false,
      qualification: [],
      presence: [],
      sex: [],
      birthDayPickerDate: "1990-01-01",
      trainingPickerDate: (new Date().getFullYear() - 1) + "-01-01"
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get("/api/masters/sex").then((response) => {
        this.sex = response.data;
      });
      await this.$axios.get("/api/masters/qualification").then((response) => {
        this.qualification = response.data;
      });
      await this.$axios.get("/api/masters/presence").then((response) => {
        this.presence = response.data;
      });
    })();
  },
  watch: {
    requestDateSearch(val) {
      val && setTimeout(() => (this.activePicker = "DATE"));
    },
    menu(val) {
      val &&
        setTimeout(
          () => ((this.activePicker = "YEAR"), (this.birthDayPickerDate = null))
        );
    },
    practiceDateSearch(val) {
       val &&
        setTimeout(
          () => ((this.activePicker = "YEAR"), (this.trainingPickerDate = null))
        );
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}

.qualificationCdRadioGroup .v-radio,
.qualificationCdRadioGroup .v-radio > .v-label{
  width:fit-content !important;
}

</style>
