<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">本人情報</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <th width="100">登録番号</th>
            <td>
              <div class="text-h6">{{ fields.registration_number }}</div>
            </td>
          </tr>
          <tr>
            <th width="100">変更届出日</th>
            <td>
              <div class="d-md-flex justify-content-start align-center">
                <div style="width: 200px">
                  <v-menu
                    v-model="requestDateSearch"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fields.change_requested_at"
                        prepend-inner-icon="mdi-calendar"
                        @click:prepend-inner="on.click"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        dense
                        color="cyan darken-2"
                        background-color="white"
                        class="datepick"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fields.change_requested_at"
                      no-title
                      color="cyan darken-2"
                      @input="requestDateSearch = false"
                      @change="updateRetirementDate"
                      :day-format="(date) => new Date(date).getDate()"
                    >
                    </v-date-picker>
                  </v-menu>
                </div>
                
              </div>
            </td>
          </tr>
          <!-- <tr>
            <th width="100" class="bbnone">
              添付書類
            </th>
            <td class="bbnone">
              <transition-group tag="div">
                <template v-for="(file, index) in files">
                  <v-sheet
                    background-color="white"
                    color="transparent"
                    class="mt-1"
                    rounded
                    outlined
                    :key="index"
                  >
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-text-field
                          label="ファイル名"
                          outlined
                          hide-details
                          color="cyan darken-2"
                          background-color="white"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-file-input
                          label="File"
                          outlined
                          color="cyan darken-2"
                          dense
                          hide-details
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="2" align-self="center">
                        <v-btn icon depressed small color="red darken-2" dark>
                          <v-icon @click.stop="deleteFile(index)">
                            mdi-trash-can
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </template>
              </transition-group>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="text-center">
                <v-btn
                  color="blue-grey darken-2"
                  class="mx-3 mb-2"
                  fab
                  depressed
                  x-small
                  dark
                  @click="createFile"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </td>
          </tr> -->
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="transparent mt-5">
      <template v-slot:default>
        <tbody>
          <tr :class="{ active: changes.name_change_presence }">
            <th
              width="120"
              class="text-md-right text-left px-3 bg-yellow lighten-5 bbnone"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.name_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>
                <div
                  v-if="errors && errors.name_change_presence"
                  class="text-danger"
                >
                  {{ errors.name_change_presence[0] }}
                </div>

                <span>フリガナ</span>
              </div>
            </th>
            <td class="bbnone">
              <div class="d-md-flex justify-space-between">
                <v-text-field
                  v-model="fields.familyname_kana"
                  single-line
                  outlined
                  hide-details
                  dense
                  value="トウキョウ"
                  background-color="white"
                  color="cyan darken-2"
                  class="mr-md-2 mb-2 mb-md-0"
                  :disabled="!changes.name_change_presence"
                ></v-text-field>
                <div
                  v-if="errors && errors.familyname_kana"
                  class="text-danger"
                >
                  {{ errors.familyname_kana[0] }}
                </div>

                <v-text-field
                  v-model="fields.firstname_kana"
                  single-line
                  outlined
                  hide-details
                  dense
                  value="タロウ"
                  background-color="white"
                  color="cyan darken-2"
                  class="ml-md-2"
                  :disabled="!changes.name_change_presence"
                ></v-text-field>
                <div v-if="errors && errors.firstname_kana" class="text-danger">
                  {{ errors.firstname_kana[0] }}
                </div>
              </div>
            </td>
          </tr>
          <tr :class="{ active: changes.name_change_presence }">
            <th
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-end align-center">
                <span>氏名</span>
              </div>
            </th>
            <td>
              <div class="d-md-flex justify-space-between">
                <v-text-field
                  v-model="fields.familyname_kanji"
                  single-line
                  outlined
                  hide-details
                  dense
                  value="東京"
                  background-color="white"
                  color="cyan darken-2"
                  class="mr-md-2 mb-2 mb-md-0"
                  :disabled="!changes.name_change_presence"
                ></v-text-field>
                <div
                  v-if="errors && errors.familyname_kanji"
                  class="text-danger"
                >
                  {{ errors.familyname_kanji[0] }}
                </div>

                <v-text-field
                  v-model="fields.firstname_kanji"
                  single-line
                  outlined
                  hide-details
                  dense
                  value="太郎"
                  background-color="white"
                  color="cyan darken-2"
                  class="ml-md-2"
                  :disabled="!changes.name_change_presence"
                ></v-text-field>
                <div
                  v-if="errors && errors.firstname_kanji"
                  class="text-danger"
                >
                  {{ errors.firstname_kanji[0] }}
                </div>
              </div>
            </td>
          </tr>
          <tr :class="{ active: changes.oldname_change_presence }">
            <th
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.oldname_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>

                <span>旧姓使用</span>
              </div>
            </th>
            <td>
              <div class="d-flex justify-space-between">
                <v-radio-group row dense v-model="fields.is_oldname">
                  <v-radio
                    label="不使用"
                    :value="0"
                    color="cyan darken-2"
                    :disabled="!changes.oldname_change_presence"
                  ></v-radio>
                  <v-radio
                    label="使用"
                    :value="1"
                    color="cyan darken-2"
                    :disabled="!changes.oldname_change_presence"
                  ></v-radio>
                </v-radio-group>
                <div v-if="errors && errors.is_oldname" class="text-danger">
                  {{ errors.is_oldname[0] }}
                </div>
              </div>
            </td>
          </tr>
          <tr
            :class="{ active: changes.register_name_writing_change_presence }"
            v-if="fields.is_oldname == 1"
          >
            <th
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.register_name_writing_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>

                <span>戸籍姓併記</span>
              </div>
            </th>
            <td>
              <div class="d-md-flex justify-start align-center">
                <v-radio-group row dense v-model="fields.register_name_writing">
                  <v-radio
                    label="併記しない"
                    :value="0"
                    color="cyan darken-2"
                    :disabled="!changes.register_name_writing_change_presence"
                  ></v-radio>
                  <v-radio
                    label="併記する"
                    :value="1"
                    color="cyan darken-2"
                    :disabled="!changes.register_name_writing_change_presence"
                  ></v-radio>
                </v-radio-group>
                <div class="grey--text text--darken-1 ml-5 text-caption">
                  「旧姓を使用する」を選択した方は、<br />名簿への戸籍姓の併記を選択することが出来ます<br />ご希望の方を選択して下さい
                </div>
              </div>
              <div
                  v-if="errors && errors.register_name_writing"
                  class="text-danger"
                >
                  {{ errors.register_name_writing[0] }}
                </div>
            </td>
          </tr>
          <tr :class="{ active: changes.adress_change_presence }">
            <th
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.adress_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>

                <span>住所</span>
              </div>
            </th>
            <td class="pb-2">
              <div class="d-md-flex justify-start align-center pb-2">
                <div style="width: 200px">
                  <v-text-field
                    label="郵便番号(ハイフン無し)"
                    outlined
                    hide-details
                    color="cyan darken-2"
                    class="mb-2 mb-md-0"
                    background-color="white"
                    dense
                    v-model="fields.adress_postalcode"
                    :disabled="!changes.adress_change_presence"
                  ></v-text-field>
                  <div
                    v-if="errors && errors.adress_postalcode"
                    class="text-danger"
                  >
                    {{ errors.adress_postalcode[0] }}
                  </div>
                </div>

                <v-btn
                  class="mx-2"
                  :disabled="!changes.adress_change_presence"
                  @click="getPrefecture"
                  >住所検索
                </v-btn>
                <v-btn
                  :disabled="!changes.adress_change_presence"
                  @click="setKaigai()"
                  >海外</v-btn
                >
                <div class="ml-md-3 my-2 my-md-0">
                  <v-tooltip top :open-on-hover="false">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                        text
                        color="cyan darken-2"
                      >
                        <v-icon dark depressed color="black">
                          mdi-chat-question-outline
                        </v-icon>
                        <span
                          class="text-caption ml-1 text-decoration-underline"
                          >海外にお住まいの方</span
                        >
                      </v-btn>
                    </template>
                    <p class="mb-0">
                      海外ボタンを押下し「住所」欄に住所及び郵便番号すべてを入力して下さい。<br />
                      例：3-4-2, Kasumigaseki, Chiyoda-ku, Tokyo 100-0013
                      Japan<br />
                      なお、郵便番号欄は一律、「000-0000」と表示されます。
                    </p>
                  </v-tooltip>
                </div>
              </div>
              <div class="d-flex justify-start align-center pb-2">
                <div style="width: 200px">
                  <v-select
                    label="都道府県"
                    :items="[
                      { label: '選択してください', id: '' },
                      ...office_prefecture,
                    ]"
                    item-text="label"
                    item-value="id"
                    outlined
                    hide-details
                    color="cyan darken-2"
                    background-color="white"
                    flat
                    dense
                    v-model="fields.adress_prefecture"
                    :disabled="!changes.adress_change_presence"
                  ></v-select>
                  <div
                    v-if="errors && errors.adress_prefecture"
                    class="text-danger"
                  >
                    {{ errors.adress_prefecture[0] }}
                  </div>
                </div>
                <div class="red--text text--darken-1 ml-5 text-caption">
                  住民票の通り入力して下さい。<br>また、英数字、ハイフン等の記号は半角で入力して下さい
                </div>
              </div>
              <v-text-field
                label="住所"
                outlined
                hide-details
                color="cyan darken-2"
                class="mb-2"
                background-color="white"
                dense
                v-model="fields.adress_municipality"
                :disabled="!changes.adress_change_presence"
              ></v-text-field>
              <div
                v-if="errors && errors.adress_municipality"
                class="text-danger"
              >
                {{ errors.adress_municipality[0] }}
              </div>

              <v-text-field
                label="建物名以下"
                outlined
                hide-details
                color="cyan darken-2"
                background-color="white"
                dense
                v-model="fields.adress_building"
                :disabled="!changes.adress_change_presence"
              ></v-text-field>
              <div v-if="errors && errors.adress_building" class="text-danger">
                {{ errors.adress_building[0] }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr :class="{ active: changes.checkboxTel }">
            <th
              width="100"
              class="text-md-right text-left px-3 bg-yellow lighten-5 bbnone"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.checkboxTel"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>

                <span>自宅TEL</span>
              </div>
            </th>
            <td class="bbnone">
              <div class="d-md-flex justify-start align-center">
                <v-text-field
                  v-model="fields.tel1"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2 mr-md-4"
                  background-color="white"
                  color="cyan darken-2"
                  :disabled="!changes.checkboxTel"
                ></v-text-field>
                <div v-if="errors && errors.tel1" class="text-danger">
                  {{ errors.tel1[0] }}
                </div>
                <v-text-field
                  v-model="fields.tel2"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2 mr-md-4"
                  background-color="white"
                  color="cyan darken-2"
                  :disabled="!changes.checkboxTel"
                ></v-text-field>
                <div v-if="errors && errors.tel2" class="text-danger">
                  {{ errors.tel2[0] }}
                </div>
              </div>
            </td>
          </tr>

          <tr :class="{ active: changes.checkboxFax }">
            <th
              width="100"
              class="text-md-right text-left px-3 bg-yellow lighten-5 bbnone"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.checkboxFax"
                  hide-details
                  class="mt-0 mb-1"
                  color="cyan darken-2"
                ></v-checkbox>

                <span>自宅FAX</span>
              </div>
            </th>
            <td class="bbnone">
              <div class="d-md-flex justify-start align-center">
                <v-text-field
                  v-model="fields.fax1"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2 mr-md-4"
                  background-color="white"
                  color="cyan darken-2"
                  :disabled="!changes.checkboxFax"
                ></v-text-field>
                <div v-if="errors && errors.fax1" class="text-danger">
                  {{ errors.fax1[0] }}
                </div>
                <v-text-field
                  v-model="fields.fax2"
                  placeholder="例：03-3456-7890"
                  single-line
                  outlined
                  hide-details
                  dense
                  class="my-2 mr-md-4"
                  background-color="white"
                  color="cyan darken-2"
                  :disabled="!changes.checkboxFax"
                ></v-text-field>
                <div v-if="errors && errors.fax2" class="text-danger">
                  {{ errors.fax1[0] }}
                </div>
              </div>
            </td>
          </tr>

          <tr :class="{ active: changes.public_servant_change_presence }">
            <th
              width="100"
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle; border-top: 1px solid #eeeeee"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.public_servant_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                ></v-checkbox>

                <span>公務就任先</span>
              </div>
            </th>
            <td style="border-top: 1px solid #eeeeee">
              <div class="d-md-flex justify-start align-center pb-2">
                <div style="width: 400px">
                  <v-text-field
                    v-model="fields.public_servant"
                    single-line
                    outlined
                    hide-details
                    dense
                    :disabled="!changes.public_servant_change_presence"
                    class="my-1"
                  ></v-text-field>
                </div>
                <div>
                  <v-tooltip right :open-on-hover="false">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                        text
                        color="cyan darken-2"
                      >
                        <v-icon dark depressed color="black">
                          mdi-chat-question-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <p class="mb-0">
                      下記事務所に係らず、現在、国又は地方公共団体等の公職に就いている場合<br/>(規定により兼業が禁止されている場合)は記入してください
                    </p>
                  </v-tooltip>
                </div>
              </div>
              <div v-if="errors && errors.public_servant" class="text-danger">
                {{ errors.public_servant[0] }}
              </div>
            </td>
          </tr>
          <tr :class="{ active: changes.send_to_change_presence }">
            <th
              class="text-md-right text-left px-3 bg-yellow lighten-5"
              style="vertical-align: middle"
            >
              <div class="d-md-flex justify-space-between align-center">
                <v-checkbox
                  v-model="changes.send_to_change_presence"
                  hide-details
                  class="mt-0 mb-1"
                ></v-checkbox>
                <span>書類送付先</span>
              </div>
            </th>
            <td>
              <div class="d-md-flex justify-start">
                <v-select
                  :items="sendList"
                  item-text="name"
                  item-value="id"
                  outlined
                  hide-details
                  color="green darken-3"
                  class="mb-2 mb-md-0"
                  background-color="white"
                  flat
                  dense
                  :disabled="!changes.send_to_change_presence"
                  v-model="fields.send_to"
                ></v-select>
                <div v-if="errors && errors.send_to" class="text-danger">
                  {{ errors.send_to[0] }}
                </div>
                <span
                  v-if="!changes.send_to_change_presence"
                  class="grey--text"
                >
                  {{ addressList[fields.send_to] }}
                </span>
                <span v-else class="black--text">
                  {{ addressList[fields.send_to] }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      office_prefecture: [],
      requestDateSearch: false,
      files: [
        {
          title: "1",
        },
      ],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => { },
    },
    errors: {
      type: Object,
      default: () => { },
    },
    hasSuccessor: {
      type: Object,
      default: () => { },
    },
    changes: {
      type: Object,
      default: () => { },
    },
    officeChanges: {
      type: Object,
      default: () => { },
    },
  },
  mounted() {
    (async () => {
      await this.$axios
        .get("/api/masters/office_prefecture")
        .then((response) => {
          this.office_prefecture = response.data;
        });
    })();
  },
  methods: {
    getPrefecture() {
      let _this = this.fields;
      _this.adress_building = '';
      this.getAddress(_this.adress_postalcode, function (addr) {
        _this.adress_prefecture = addr.prefecture;
        _this.adress_municipality = addr.municipality;
      });
    },
    setKaigai() {
      let _this = this.fields;
      _this.adress_prefecture = '';
      _this.adress_postalcode = "0000000";
    },
    createFile() {
      const title = this.files.length + 1;
      this.files.push({ title: title });
    },
    deleteFile(index) {
      if (window.confirm("ファイルを削除してよろしいですか？")) {
        this.files.splice(index, 1);
      }
    },
    changeOperation() {
      if (this.fields.address_range == true) {
        for (const [key, val] of Object.entries(this.fields.belong_offices)) {
          if (val.office_state_cd == 1) {
            val.operation_status_cd = 12;
            this.officeChanges.office_oparation_change_presence[key] = true;
          }
        }
      }
    },
    updateRetirementDate() {
      // 「変更届出日」に「退職日」より前の日付を選択したら、退職日をクリアする
      var change_requested_at = new Date(this.fields.change_requested_at);
      for (const [key, office] of Object.entries(this.fields.belong_offices)) {
        if (office.office_state_cd == 4 && office.retirement_date != null) {
          var retirement_date = new Date(office.retirement_date);
          if (retirement_date > change_requested_at) {
            this.$set(this.fields["belong_offices"][key], 'retirement_date', null);
          }
        }
      }
    }
  },
  computed: {
    sendList: function () {
      let send = [];
      if (this.fields.belong_offices != undefined) {
        for (const [key, val] of Object.entries(this.fields.belong_offices)) {
          let id = "";
          id = String(val.id);
          if (id == "null") {
            id = "new_" + key;
          }
          let name = "";
          let state = "";
          if (val.office_state_cd == 1) {
            state = "（主）";
          }
          name = state + val.offfice_kanji;
          if (!val.delete_flag && val.office_state_cd != 4) {
            send.push({ id: id, name: name });
          }
        }
      }
      send.push({ id: "0", name: "自宅" });
      return send;
    },
    addressList: function () {
      let list = [];
      let adressPrefecture = this.fields.adress_prefecture;
      if(adressPrefecture === '海外') {
        adressPrefecture = '';
      }
      let address =
        adressPrefecture +
        " " +
        this.fields.adress_municipality +
        " " +
        this.fields.adress_building;
      list = { "0": address.replace(/null|undefined/g, "") };
      if (this.fields.belong_offices != undefined) {
        for (const [key, val] of Object.entries(this.fields.belong_offices)) {
          let officePrefecture = val.office_prefecture;
          if(officePrefecture === '海外') {
            officePrefecture = '';
          }
          address =
            officePrefecture +
            " " +
            val.office_municipality +
            " " +
            val.office_building;
          let id = "";
          if (val.office_id == null) {
            id = "new_" + key;
          } else {
            id = String(val.id);
          }
          if (!val.delete_flag && val.office_state_cd != 4) {
            list[id] = address.replace(/null|undefined/g, "");
          }
        }
      }
      return list;
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
