<template>
    <div>
        <router-view />
        <v-snackbar
            v-model="isChanged"
            color="#F9A825"
            timeout="-1"
            centered
            top
        >
            システムが更新されました。リロードしてください。
        </v-snackbar>
    </div>
</template>

<style type="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
.v-application {
    font-family: "Noto Sans JP", sans-serif !important;
}

  .text-danger {
    color: #dc3545 !important;
  }
  
    a.text-danger:hover, a.text-danger:focus {
    color: #a71d2a !important;
   }
</style>
<script>
export default {
    data() {
        return {
            isChanged: false,
        };
    },
    created() {
        // 更新を3分毎にチェック
        setInterval(this.fetchServer, 1000* 60 * 3); 
    },
    methods: {
        //更新通知。自身のhtmlを取得してハッシュ化、元のハッシュと比べて変更があればアラートを表示する。
        fetchServer() {
            fetch('/')
                .then((res) => {
                    return res.text();
                })
                .then((html) => {
                    const hash = this.createHash(html);
                    this.judgeHash(hash);
                })
                .catch((err) => console.log(err));
        },
        createHash(str) {
            const len = str.length;
            let hash = 0;
            if (len === 0) return hash;
            let i;
            for (i = 0; i < len; i++) {
                hash = (hash << 5) - hash + str.charCodeAt(i);
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        },
        judgeHash(hash) {
            if (!this.previousHash) {
                this.previousHash = hash;
                return;
            }
            if (this.previousHash !== hash) {
                this.isChanged = true;
            }
        },
    },
};
</script>
