import axios from "axios";
import Vue from "vue";
import YubinBango from "yubinbango-core2";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

axios.defaults.withCredentials = true;
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // 認証エラー時の処理
    if (error.response.status === 401 || error.response.status === 419) {
      store.commit("auth/updateLoginState", {
        loggedout: true
      });
    } else if (error.response.status === 500) {
      store.commit("setMessege", {
        text:
          "システムエラーが発生しました。リロード後にもう一度試していただき、改善しなければ管理者へご連絡ください。",
        color: "error"
      });
    } else if (error.response.status === 429) {
      store.commit("setMessege", {
        text: "通信回数制限に達しました。1分後にもう一度試してください。",
        color: "warning"
      });
    }

    return Promise.reject(error);
  }
);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.mixin({
  methods: {
    getAddress(postalCode, callback) {
      new YubinBango.Core(postalCode, function(addr) {
        let response = {};
        response.region = addr.region;
        response.prefecture = addr.region;
        response.municipality = addr.locality + addr.street;
        response.locality = addr.locality;
        callback(response);
      });
    },
    zenKakuToHanKaKu(str,field) {
      if(field != 'tel1' && field != 'tel2' && field != 'fax1' && field != 'fax2')return str;
      if (str == null || str == "") return "";
      if (Number.isInteger(str)) return str;
      str = str.replace(/ー|➖|−|-|―|‐|－|—|￣/gi,'-');
      return str.replace(/[Ａ-Ｚａ-ｚ０-９－]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    }
  }
});
