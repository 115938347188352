<template>
  <v-card class="mb-9">
    <v-card-title class="px-4 py-2 cyan darken-1">
      <h3 class="subtitle-1 font-weight-bold white--text">住所公開範囲</h3>
    </v-card-title>
    <v-simple-table class="transparent my-5">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <v-checkbox
                label="主たる事務所の住所公開範囲を市区町村までに留める"
                color="teal darken-1"
                v-model="fields.address_range"
                @change="changeOperation()"
              ></v-checkbox>
              <div v-if="errors && errors.address_range" class="text-danger">
                {{ errors.address_range[0] }}
              </div>
              <div class="grey--text text--darken-1 mt-3 text-caption">
                就業形態を「連絡先用途事務所」とする場合、主たる事務所の所在地の公表範囲を市区町村名までに留めることを申請できます。<br />申請する場合は選択してください。<br />
                事業用ではなく専ら本会からの連絡を受ける用途の事務所を自宅に設置した場合にのみ選択できます。<br />
                選択すると、主たる事務所の就業形態が自動的に「9.連絡先用途事務所」になります。
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeOperation() {
      if (this.fields.address_range == true) {
        for (const [key, val] of Object.entries(this.fields.office_entry_requests)) {
          if (val.office_state_cd == 1) {
            val.office_operation_cd = 12;
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr .bbnone {
  border-bottom: none !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
