import Vue from "vue";
import VueRouter from "vue-router";
import { checkAuth, checkAuthByRole } from "../mixins/Auth.js";
import Application from "../pages/Application";
import ApplicationComplete from "../pages/Application/Complete";
import Applications from "../pages/Applications";
import Change from "../pages/Change";
import ChangeComplete from "../pages/Change/Complete";
import ChangeDownload from "../pages/Change/Download";
import Online from "../pages/Change/Online";
import Corporate from "../pages/Corporate";
import CorporateDownload from "../pages/Corporate/Download";
import CorporateChange from "../pages/CorporateChange";
import CorporateChangeDownload from "../pages/CorporateChange/Download";
import Download from "../pages/Download";
import Email from "../pages/Email";
import Fee from "../pages/Fee";
import FeeHistory from "../pages/FeeHistory";
import FeeHistoryIssue from "../pages/FeeHistory/Issue";
import FAQ from "../pages/FAQ";
import Home from "../pages/Home.vue";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Password from "../pages/Password";
import PracticalTraining from "../pages/PracticalTraining";
import Top from "../pages/Top";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sso/:number/:proxy",
    component: Login,
    props: true
  },
  {
    path: "/login",
    component: Login,
    props: true
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "",
        name: "top",
        component: Top
      },
      {
        path: "/applications",
        name: "applications",
        component: Applications
      },
      {
        path: "/change",
        name: "change",
        component: Change
      },
      {
        path: "/change/download",
        name: "change_download",
        component: ChangeDownload
      },
      {
        path: "/change/online",
        name: "online",
        component: Online
      },
      {
        path: "/change/complete/:id",
        name: "change_complete",
        component: ChangeComplete
      },
      {
        path: "/email",
        name: "email",
        component: Email
      },
      {
        path: "/corporate",
        name: "corporate",
        component: Corporate
      },
      {
        path: "/corporate/download/:uuid",
        name: "corporate_download",
        component: CorporateDownload
      },
      {
        path: "/corporate_change",
        name: "corporate_change",
        component: CorporateChange
      },
      {
        path: "/corporate_change/download/:uuid",
        name: "corporate_change_download",
        component: CorporateChangeDownload
      },
      {
        path: "/download/",
        name: "download",
        component: Download
      },
      {
        path: "/fee",
        name: "fee",
        component: Fee
      },
      {
        path: "/fee_history",
        name: "fee_history",
        component: FeeHistory
      },
      {
        path: "/fee_history/issue",
        name: "fee_history_issue",
        component: FeeHistoryIssue
      },
      {
        path: "/faq",
        name: "FAQ",
        component: FAQ
      }
    ]
  },
  {
    name: "application",
    path: "/application",
    component: Application,
    meta: { title: '日本弁理士会 弁理士登録申請書作成' }
  },
  {
    name: "complete",
    path: "/application/complete/:uuid",
    component: ApplicationComplete,
    meta: { title: '日本弁理士会 弁理士登録申請書作成' }
  },
  {
    name: "practical_training",
    path: "/practical_training",
    component: PracticalTraining
  },
  {
    name: "NotFound",
    path: "*",
    component: NotFound
  },
  {
    name: "denied",
    path: "/denied",
    component: () => import("../pages/403")
  },
  {
    name: "Password",
    path: "/password",
    component: Password
  },

  {
    path: "/sample",
    name: "sample",
    component: Home,
    props: true,
    meta: {
      isPublic: true
    },
    children: [
      {
        name: "SampleAdd",
        path: "/samples/add",
        component: () => import("../pages/Sample/add.vue"),
        meta: {
          isPublic: true
        }
      },
      {
        name: "SamplEdit",
        path: "/samples/edit/:id",
        component: () => import("../pages/Sample/edit.vue"),
        meta: {
          isPublic: true
        }
      },
      {
        name: "SampleIndex",
        path: "/samples",
        component: () => import("../pages/Sample/list.vue"),
        meta: {
          isPublic: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  //isPublicがtrueの場合には必ずアクセス可能
  //各ルートに meta: { isPublic: true } を追加します。
  if (to.matched.some(page => page.meta.isPublic)) {
    next();
  } else {
    //async/awaitでそれぞれのログイン状況を取得する
    (async () => {
      let Auth = await checkAuth();
      //ログイン済
      if (Auth) {
        let is_next = true;
        //(種別)
        if (to.meta.role) {
          is_next = false;
          let AuthRole = await checkAuthByRole(to.meta.role);
          if (AuthRole) {
            is_next = true;
          }
        }

        //権限あり
        if (is_next) {
          next();
        } else {
          next({ path: "/denied" });
        }
      }
      //ログインしていない
      else {
        if (to.path.indexOf("/auth") === 0 || to.path.indexOf("/login") === 0) {
          next();
        } else {
          next({ path: "/login" });
        }
      }
    })();
  }
});

//タイトルタグの変更
const DEFAULT_TITLE = '日本弁理士会 各種申請';
router.afterEach((to, from) => {
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router;
