<template>
  <div>
    <transition>
      <v-card class="my-9">
        <v-card-title class="px-4 py-2 blue-grey darken-1">
          <h3 class="subtitle-1 font-weight-bold white--text">
            弁理士法人を構成する社員
          </h3>
        </v-card-title>

        <v-row justify="center">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="employees"
              hide-default-header
              hide-default-footer
              no-data-text="データがありません"
              disable-pagination
              class="mb-5"
            >
              <template v-slot:item="{ item, index }">
                <tr
                  v-if="item.delete_flag == false"
                  :class="{
                    active: changes.check[index],
                  }"
                >
                  <td width="10%">
                    <v-checkbox
                      v-model="changes.check[index]"
                      hide-details
                      class="mt-0 mb-1"
                      color="light-blue darken-4"
                    ></v-checkbox>
                  </td>
                  <td>
                    <div class="d-md-flex justify-start align-center">
                      <div>
                        <v-text-field
                          v-model="item.registration_number"
                          outlined
                          hide-details
                          color="light-blue darken-4"
                          class="mb-2 mb-md-0"
                          background-color="white"
                          dense
                          label="登録番号"
                          @change="
                            change_registration_number(
                              item.registration_number,
                              index
                            )
                          "
                          :disabled="!changes.check[index]"
                        ></v-text-field>
                      </div>
                      <div style="width: 30%;">
                        <div class="ma-2 my-md-0 ml-md-5">{{ item.name_kanji }}</div>
                        <div
                          v-if="
                            errors &&
                            errors['employee.' + index + '.registration_number']
                          "
                          class="text-danger"
                        >
                          {{
                            errors[
                              "employee." + index + ".registration_number"
                            ][0]
                          }}
                        </div>
                      </div>
                      <div>
                        <v-select
                          :items="employee_status"
                          item-text="label"
                          item-value="id"
                          outlined
                          hide-details
                          color="primary"
                          background-color="white"
                          label="雇用形態"
                          flat
                          dense
                          v-model="item.employment_status_cd"
                          :disabled="!changes.check[index]"
                        ></v-select>
                        <div
                          v-if="
                            errors &&
                            errors['employee.' + index + '.employment_status_cd']
                          "
                          class="text-danger"
                        >
                          {{
                            errors[
                              "employee." + index + ".employment_status_cd"
                            ][0]
                          }}
                        </div>
                      </div>
                      <v-btn small color="red darken-2" dark class="mt-2 mt-md-0 ml-md-9" @click="removeEmployee(index)">
                        削除
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr v-else class="delete_text">
                  <td></td>
                  <td>{{ item.registration_number }}</td>
                  <td>{{ item.name_kanji }}</td>
                  <td>{{ statusesFlat[item.employment_status_cd] }}</td>
                  <td><span>削除予定</span></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div class="text-center pb-4">
          <v-btn
            class="ma-2"
            dark
            depressed
            outlined
            color="blue-grey darken-2"
            @click="addEmployee"
          >
            <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>
            社員を追加する
          </v-btn>
        </div>
      </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusesFlat: {},
      person: [],
      employees: [],
      employeesLength: null,
      headers: [
        {
          text: "",
          sortable: false,
          width: "10%",
        },
        {
          text: "登録番号",
          sortable: false,
          width: "15%",
          value: "num",
        },
        {
          text: "氏名",
          sortable: false,
          width: "25%",
        },
        {
          text: "雇用形態",
          sortable: false,
          width: "20%",
        },
        {
          text: "",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => {},
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    changes: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    fields(val) {
      const employees = [];
      for (const [key, value] of Object.entries(val.employees)) {
        employees.push(value);
      }
      this.employees = employees;
      this.employeesLength = Object.keys(this.employees).length;
      val.employees = "";
      val.employees = this.employees;
    },
  },
  mounted() {
    (async () => {
      await this.$axios.get("/api/masters/employment_2").then((response) => {
        this.employee_status = response.data;
        this.employee_status.splice(2, 1); //remove 使用人
        for (const item of this.employee_status) {
          this.statusesFlat[item.id] = item.label;
        }
      });
    })();
  },
  methods: {
    removeEmployee(index) {
      if (confirm("削除してよろしいですか？")) {
        this.employees[index]["delete_flag"] = true;
        this.employees[index]["will_delete"] = 1;

        if (this.employees[index]["id"] == null) {
          this.employees.splice(index, 1);
        }
        const newEmployee = [];
        var i = 0;
        for (const value of this.employees) {
          this.$set(newEmployee, i, value);
          i++;
        }
        this.employees = newEmployee;
        this.fields.employees = "";
        this.fields.employees = this.employees;
      }
    },
    addEmployee() {
      const title = this.employees.length;
      this.$set(this.employees, title, {
        id: null,
        employee_id: null,
        registration_number: null,
        name_kanji: null,
        notification_number: null,
        employment_status_cd: null,
        will_delete: 0,
        charge_cd: null,
        delete_flag: false,
      });
      this.changes.check[title] = true;
      this.fields.employee = "";
      this.fields.employee = this.employees;
    },
    change_registration_number(number, index) {
      if (number != "") {
        this.$axios
          .get("/api/member_by_number/" + number)
          .then((response) => {
            if (response.data.exist == true && response.data.type == "person") {
              this.$set(
                this.employees[index],
                "name_kanji",
                response.data.name_kanji
              );
            } else {
              this.$set(this.employees[index], "name_kanji", "");
            }
          })
          .catch((error) => {
            this.$set(this.employees, false);
          });
      } else {
        this.$set(this.employees[index], "name_kanji", "");
      }
    },
  },
};
</script>

<style type="scss" scoped>
.delete_text {
  background-color: #f2f2f2 !important;
}
.active {
  background-color: #e8f5e9 !important;
}
.v-enter-active,
.v-leave-active,
.v-move {
  transition: opacity 0.3s;
}
.v-enter {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child td,
.theme--light.v-data-table .v-data-table__wrapper table tbody tr:last-child th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
<style type="scss">
.v-data-table__wrapper table thead tr th {
  background-color: #ffffff !important;
}
</style>
