<template>
    <v-app>
        <v-container fluid class="grey lighten-4 login">
            <v-overlay
                  :absolute="true" 
          >
          {{message}}
            <v-progress-circular indeterminate size="64" v-if=showCircular>
            </v-progress-circular>
          </v-overlay> 
        </v-container>
    </v-app>
</template>

<script>
export default {
      data() {
        return {
          message: 'ログイン中です',
          showCircular: true
        };
      },
      mounted() {
        this.$axios.get("/api/sanctum/csrf-cookie").then((response) => {
                this.$axios
                    .get(`/api/sso/${this.$route.params.number}/${this.$route.params.proxy}?expires=${this.$route.query.expires}&signature=${this.$route.query.signature}`)
                    .then((response) => {
                        if (response.data.success) {
                              this.$store.commit('auth/setAuthUser', {
                                    authuser : response.data.person,
                             });
                            //localStorage.setItem('token', response.data.token)
                            window.location.href = response.data.redirect_after_login;
                         }
                    })
                     .catch((error) => {
                      console.log(error);
                      this.message = 'ログインに失敗しました';
                      this.showCircular = false;
                    });
                    
            });
        },

    
};
</script>

<style scoped>
.title-bg {
  background-color: #19407f;
}
</style>

<style lang="scss">
.login_title {
  width: 100%;
}
.login {
  height: 100%;
  .row {
    height: 100%;
  }
}
</style>
