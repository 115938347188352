<template>
  <div>
    <v-app-bar clipped-left light app color="blue-grey lighten-5">
      <!-- PC -->
      <v-toolbar-title>
        <img src="../../assets/logo.svg" width="200" />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="mr-8 d-none d-md-flex">
        <v-icon class="mr-2"> mdi-account </v-icon>
        <div class="d-flex align-center" v-if="this.$store.state.auth.loggedout==false">
         {{authuser.name_kanji }}さま 
        </div>
      </v-toolbar-items>
      <v-btn
        to="/"
        tile
        depressed
        color="#393E46"
        class="mr-2 d-none d-md-flex"
        outlined
        small
        exact-path
        >メニューへ戻る </v-btn
      >
      <v-btn
        tile
        depressed
        color="#393E46"
        class="d-none d-md-flex"
        dark
        small
        @click.stop="logoutDlog"
        >ログアウト</v-btn
      >
      <!-- smartphone -->
      <v-app-bar-nav-icon
        @click.stop="drawer_smt = !drawer_smt"
        class="d-md-none"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer_smt"
      absolute
      temporary
      class="d-md-none"
    >
      <v-list nav dense>
        <v-list-item-group v-model="menu_smt" active-class="">
          <v-list-item class="mb-8" inactive>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">
              <span class="font-weight-bold mr-1">{{
                this.$store.state.auth.authuser.name_kanji
              }}</span
              >さま
            </v-list-item-title>
          </v-list-item>

          <v-list-item to="/" exact-path>
            <v-list-item-icon>
              <v-icon>mdi-keyboard-return</v-icon>
            </v-list-item-icon>
            <v-list-item-title>メニューへ戻る</v-list-item-title>
          </v-list-item>

          <v-list-item @click.stop="logoutDlog">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="dialog_logout" width="400">
      <v-card>
        <v-card-text class="py-5">
          ログアウトしてもよろしいですか？
        </v-card-text>

        <div class="text-center py-4">
          <v-btn color="blue darken-4" outlined class="mr-2" @click="logout">
            はい
          </v-btn>
          <v-btn color="red darken-3" dark @click="dialog_logout = false">
            いいえ
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog_logout: false,
      drawer_smt: false,
      menu_smt: null,
      authuser:{},
    };
  },
  mounted() {
      this.$axios
        .get("/api/login_user").then((response) => {
          this.authuser = response.data;
        })
        .catch((error) => {
            if (error.response.status == "404") {
                  this.$router.replace({
                      path: "/404",
                  });
              }
      });
  },
  methods: {
    logoutDlog() {
      this.dialog_logout = true;
    },
    logout: function () {
      this.dialog_logout = false;
      this.$store.commit("setOverlay", {
        overlay: true,
      });
      this.$axios.get("/api/sanctum/csrf-cookie").then((response) => {
        this.$axios
          .post("/api/logout")
          .then((response) => {
            if (response.data.success) {
              this.$store.commit("setOverlay", {
                overlay: false,
              });
              this.$store.commit("auth/resetAuthUser");
              localStorage.clear();
              window.location.href = response.data.url;
            }
          })
          .catch(function (error) {});
      });
    },
  },
};
</script>

<style>
.v-btn__content {
  line-height: 0 !important;
}
</style>
