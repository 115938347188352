<template>
  <v-app>
    <v-snackbar
      v-model="message.view"
      class="headerSnackbar"
      :color="message.color"
      :timeout="message.timeout"
      centered
      top
    >
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="message.view = false"
          id="closetab"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <!-- ログインの実装の後で開発します -->
    <v-dialog
      v-model="this.$store.state.auth.loggedout"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          ログイン状態ではありません
        </v-card-title>
        <v-card-text>下記のログインボタンを押して再度ログインしてください.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
              color="green darken-1"
              text
              target="_blank"
              rel="opener"
              :href="redirect_after_logout"
            >
              ログインする
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <Header />
    <v-main>
      <v-overlay :absolute="true" :value="this.$store.state.overlay">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Header from "./templates/Header";

export default {
  data() {
    return {
      drawer: false,
      redirect_after_logout:null,
    };
  },
  mounted(){
    this.$axios.get("/api/config/redirect_after_logout").then((response) => {
        this.redirect_after_logout = response.data;
      });
  },
  watch: {
    $route() {
      this.$store.commit("setOverlay", {
        overlay: false
      });
    }
  },
  components: { Header },

  computed: {
    message() {
      return this.$store.state.message;
    }
  }
};
</script>
