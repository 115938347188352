<template>
  <v-container grid-list-md>
    <h2 class="pt-4 pb-2 text-center">メールアドレス変更</h2>

    <v-row class="my-5" justify="center" align="center">
      <v-col lg="8">
        <v-card color="#f2fbff">
          <v-simple-table class="transparent my-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <th
                    width="130"
                    class="text-right px-3"
                    style="vertical-align: middle"
                  >
                    <span>Eメールアドレス1</span>
                  </th>
                  <td>
                    <div class="d-md-flex justify-start align-center">
                      <v-row>
                        <v-col sm="8" cols="12">
                          <v-text-field
                            v-model="fields.email1"
                            single-line
                            outlined
                            hide-details
                            dense
                            background-color="white"
                            color="cyan darken-2"
                          ></v-text-field>
                          <div
                            v-if="errors && errors.email1"
                            class="text-danger"
                          >
                            {{ errors.email1[0] }}
                          </div>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="fields.email_publication"
                            :items="publication"
                            item-text="label"
                            item-value="id"
                            outlined
                            hide-details
                            color="cyan darken-2"
                            background-color="white"
                            flat
                            dense
                          ></v-select>
                          <div
                            v-if="errors && errors.email_publication"
                            class="text-danger"
                          >
                            {{ errors.email_publication[0] }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-right px-3" style="vertical-align: middle">
                    <span>Eメールアドレス2</span>
                  </th>
                  <td>
                    <v-text-field
                      v-model="fields.email2"
                      single-line
                      outlined
                      hide-details
                      dense
                      class="my-2"
                      background-color="white"
                      color="cyan darken-2"
                    ></v-text-field>
                    <div v-if="errors && errors.email2" class="text-danger">
                      {{ errors.email2[0] }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-right px-3" style="vertical-align: middle">
                    <span>Eメールアドレス3</span>
                  </th>
                  <td>
                    <v-text-field
                      v-model="fields.email3"
                      single-line
                      outlined
                      hide-details
                      dense
                      class="my-2"
                      background-color="white"
                      color="cyan darken-2"
                    ></v-text-field>
                    <div v-if="errors && errors.email3" class="text-danger">
                      {{ errors.email3[0] }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div class="my-6 caption notice">
          <ul>
            <li>
              Eメールアドレス1には、原則として、日本弁理士会からの各種連絡及び情報について、平時・緊急時を問わず送信します。<br />
              会員名簿（電子フォーラム）への掲載可否を選択することができますので、名簿掲載の希望の可否を選択してください。
            </li>
            <li>
              Eメールアドレス2、3原則として、日本弁理士会からの緊急を要する連絡及び情報（災害時・防災関係等）のみ送信します。<br />
              会員名簿等には掲載されません。
            </li>
          </ul>
        </div>
        <div class="text-center py-3">
          <v-btn
            class="ma-2"
            dark
            x-large
            color="cyan darken-1 font-weight-bold"
            @click="submit"
            :loading=loading
          >
            変更する
          </v-btn>
        </div>
        <div class="text-center pb-5 caption font-weight-bold">
          メールアドレスの変更反映まで２-３日程度かかります
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      fields: {},
      errors: {},
      publication: [],
      loading:false,
    };
  },
  mounted() {
    this.fetch();
  },
  watch: {
    $route: function (to, from) {
      this.fetch();
    },
  },
  methods: {
    submit() {
      this.errors = {};
      this.loading = true;
      this.$axios
        .put(`/api/persons`, this.fields)
        .then((response) => {
          if (response.data.result == true) {
            this.loading = false;
            this.$store.commit("setMessege", {
              text: response.data.message,
              color: "success",
            });
            this.$router.push({ path: `/` });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.errors = error.response.data.errors || {};
            this.$store.commit("setMessege", {
              text: "入力内容にエラーがあります。内容を確認してください。",
              color: "warning",
            });
          }
        });
    },
    fetch() {
      this.$store.commit("setOverlay", {
        overlay: true,
      });
      (async () => {
        await this.$axios.get("/api/masters/publication").then((response) => {
          this.publication = response.data;
        });

        this.$axios
          .get(`/api/persons`)
          .then((response) => {
            this.fields = response.data;

            this.$store.commit("setOverlay", {
              overlay: false,
            });
          })
          .catch((error) => {
            if (error.response.status == "404") {
              this.$router.replace({
                path: "/404",
              });
            }
          });
      })();
    },
  },
};
</script>

<style type="scss">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 10px 16px;
}
</style>
<style type="scss" scoped>
div.notice ul li {
  list-style-type: none;
  padding-left: 1.3em;
  position: relative;
  margin-bottom: 10px;
}
div.notice ul li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}
</style>
